import { Box, CryptoIcon, Paper, Tooltip, Typography } from "@frontend/ui";
import { formatAmount } from "@frontend/ui/utils/formatters";
import { CustomIcon } from "@frontend/ui/custom-icon";
import { useClientConfig } from "src/pages/ccar-lending-page/clients-config";
import { RiskExplorerOverviewResults } from "../../../generated";

type Props = {
  data: RiskExplorerOverviewResults;
};

const getNumberDiff = (current: number, previous: number) => {
  const diff = current - previous;

  return diff < 0 ? formatAmount(diff, { notation: "standard" }) : `+${formatAmount(diff, { notation: "standard" })}`;
};

type CardProps = {
  title: string;
  subtitle: string;
  value: number;
  prevValue?: number;
  wallets: number;
  prevWallets?: number;
  stablecoin: {
    token?: string;
    amount: number;
    prevAmount?: number;
  };
  prevPotentialBadDebt?: number;
  totalPotentialBadDebt?: number;
};

const Card = ({
  title,
  subtitle,
  value,
  prevValue,
  wallets,
  prevWallets,
  stablecoin,
  prevPotentialBadDebt,
  totalPotentialBadDebt,
}: CardProps) => {
  const valueDiff = prevValue !== undefined && value - prevValue;
  const walletsDiff = prevWallets !== undefined && getNumberDiff(wallets, prevWallets);
  const badDebtDiff = prevPotentialBadDebt !== undefined && (totalPotentialBadDebt || 0) - (prevPotentialBadDebt || 0);
  const stablecoinDiff =
    stablecoin?.prevAmount !== undefined && (stablecoin?.amount || 0) - (stablecoin?.prevAmount || 0);
  return (
    <Paper
      variant="card"
      sx={{
        p: 2,
        display: "flex",
        gap: 2,
        flex: 1,
        flexDirection: { xs: "column", lg: "row" },
      }}
    >
      <Box flex="1">
        <Typography variant="h4" minHeight="3em" maxWidth={140}>
          {title}
        </Typography>
        <Typography variant="caption">{subtitle}</Typography>
      </Box>
      <Box bgcolor="background.default" flexBasis={1} />
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <Box display="flex" flexDirection="row" mb={2}>
          <CustomIcon icon="coins-stack-icon" sx={{ mr: 1, "& path": { fill: "#9B9DA1" } }} />
          <Typography variant="caption">Value</Typography>
        </Box>
        <Tooltip
          title={
            valueDiff
              ? formatAmount(valueDiff, {
                  currency: "USD",
                  notation: "standard",
                })
              : "No Change"
          }
          arrow
        >
          <Typography variant="h3" display="inline">
            {!valueDiff || valueDiff >= 0 ? "+" : ""}
            {formatAmount(valueDiff || 0, { currency: "USD", maximumFractionDigits: 1 })}
          </Typography>
        </Tooltip>
        <Box mt={1}>
          <Typography variant="caption" sx={{ ml: 1 }}>
            Total: {formatAmount(value, { currency: "USD", maximumFractionDigits: 2 })}
          </Typography>
        </Box>
      </Box>
      {stablecoin?.token && (
        <>
          <Box bgcolor="background.default" flexBasis={1} />
          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            <Box display="flex" flexDirection="row" mb={2} gap={1}>
              <CryptoIcon icon={stablecoin.token.toLowerCase()} sx={{ width: 24, height: 24 }} />
              <Typography variant="caption">{stablecoin.token}</Typography>
            </Box>
            <Tooltip title={formatAmount(stablecoinDiff || 0, { notation: "standard" })}>
              <Typography variant="h3" display="inline">
                {!stablecoinDiff || stablecoinDiff >= 0 ? "+" : ""}
                {formatAmount(stablecoinDiff || 0, { currency: "USD", maximumFractionDigits: 1 })}
              </Typography>
            </Tooltip>
            <Box mt={1}>
              <Typography variant="caption" sx={{ ml: 1 }}>
                Total: {formatAmount(stablecoin?.amount, { currency: "USD", maximumFractionDigits: 2 })}
              </Typography>
            </Box>
          </Box>
        </>
      )}
      <Box bgcolor="background.default" flexBasis={1} />
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Box display="flex" flexDirection="row" mb={2}>
            <CustomIcon icon="wallet-icon" sx={{ mr: 1, "& path": { fill: "#9B9DA1" } }} />
            <Typography variant="caption">Wallets</Typography>
          </Box>
          <Typography variant="h3">{walletsDiff || "No Change"}</Typography>
        </Box>
        <Box mt={1} display="flex">
          <Typography variant="caption" sx={{ ml: 1 }}>
            Total: {formatAmount(wallets, { notation: "compact" })}
          </Typography>
        </Box>
      </Box>
      {totalPotentialBadDebt !== undefined && (
        <>
          <Box bgcolor="background.default" flexBasis={1} />
          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            <Box display="flex" flexDirection="row" mb={2}>
              <CustomIcon icon="coin-insert" sx={{ mr: 1, "& path": { fill: "#9B9DA1" } }} />
              <Typography variant="caption">Bad Debt</Typography>
            </Box>
            <Tooltip title={formatAmount(badDebtDiff || 0, { currency: "USD", notation: "standard" })} arrow>
              <Typography variant="h3">
                {!badDebtDiff || badDebtDiff >= 0 ? "+" : ""}
                {formatAmount(badDebtDiff || 0, { currency: "USD", maximumFractionDigits: 1 })}
              </Typography>
            </Tooltip>
            <Box mt={1} display="flex">
              <Tooltip
                title="Current and simulated bad debt across all wallets with positions on the changed assets"
                arrow
              >
                <Typography variant="caption" sx={{ ml: 1 }}>
                  Total: {formatAmount(totalPotentialBadDebt, { currency: "USD", maximumFractionDigits: 2 })}
                </Typography>
              </Tooltip>
            </Box>
          </Box>
        </>
      )}
    </Paper>
  );
};

const RiskCards = ({ data }: Props) => {
  const { riskThreshold, eligibleThreshold } = useClientConfig();
  return (
    <Box display="flex" gap={3} flexDirection={{ xs: "column", lg: "row" }}>
      <Card
        title="Risk of Liquidations"
        subtitle={`Risk: health ${eligibleThreshold}-${riskThreshold}`}
        value={data.current.totalValueAtRiskUsd}
        prevValue={data.previous?.totalValueAtRiskUsd}
        wallets={data.current.numberOfWalletsAtRisk}
        prevWallets={data.previous?.numberOfWalletsAtRisk}
        stablecoin={{
          amount: data.current.totalNativeStablecoinRiskUsd,
        }}
      />
      <Card
        title="Eligible for Liquidations"
        subtitle={`Eligible: health < ${eligibleThreshold}`}
        value={data.current.totalValueAvailableForLiquidationUsd}
        prevValue={data.previous?.totalValueAvailableForLiquidationUsd}
        wallets={data.current.numberOfWalletsAvailableForLiquidation}
        prevPotentialBadDebt={data.previous?.totalPotentialBadDebtUsd}
        totalPotentialBadDebt={data.current.totalPotentialBadDebtUsd}
        prevWallets={data.previous?.numberOfWalletsAvailableForLiquidation}
        stablecoin={{
          amount: data.current.totalNativeStablecoinAvailableForLiquidationUsd,
          prevAmount: data.previous?.totalNativeStablecoinAvailableForLiquidationUsd,
        }}
      />
    </Box>
  );
};

export default RiskCards;
