import { EChartsOption } from "echarts";
import { formatAmount, formatCurrency, formatDeltaPercent } from "../../../utils/formatters";
import { echartsTooltipFormatter } from "../../tooltipFormatter";
import { PriceData } from "./types";

const formatLiquidationPriceData = (data: PriceData = []) =>
  data.map(({ label, currentPrice, liquidationUsdValueByPrice }) => ({
    id: label,
    title: label,
    data: liquidationUsdValueByPrice
      .map(({ price, liquidationAmount }) => {
        const x = price / currentPrice - 1;

        return {
          x,
          y: liquidationAmount,
        };
      })
      .sort((a, b) => (a.x > b.x ? 1 : -1))
      .map((d) => ({
        ...d,
        x: formatDeltaPercent(d.x),
      })),
  }));

export const getPriceChangeLiquidationOption = (data: PriceData): EChartsOption => {
  const formatted = formatLiquidationPriceData(data);
  return {
    tooltip: {
      trigger: "axis",
      name: "Value at risk of liquidation",
      formatter: echartsTooltipFormatter({
        headerFormatter: () => "Value at risk of liquidation",
        valueFormatter: (v) => formatCurrency(Number(v)),
        currency: "USD",
        showXAxisInline: true,
      }),
    },
    grid: {
      right: 20,
      bottom: 0,
      top: 50,
      left: 10,
    },
    legend: {
      data: formatted.map((datum) => datum.title),
    },
    toolbox: {
      feature: {
        saveAsImage: {
          show: false,
        },
      },
    },
    xAxis: {
      type: "category",
      boundaryGap: false,
      data: formatted.length ? formatted[0].data?.map(({ x }) => x) : [],
    },
    yAxis: {
      type: "value",
      axisLabel: {
        formatter: (v: number) => formatAmount(v, { currency: "USD", notation: "compact" }),
      },
    },
    series: formatted.map((datum) => ({
      name: datum.title,
      type: "line",
      symbol: "none",
      data: datum.data.map((d) => d.y),
    })),
  };
};
