import { Box } from "@frontend/ui";
import { useEffect, useState } from "react";
import { TimeSpan } from "src/pages/ccar-lending-page/generated";
import {
  LiquidationsDataCutHeader,
  LiquidationsEventsTable,
  WalletsEligibleForLiquidationTable,
} from "src/pages/ccar-lending-page/tabs/liquidations/components";
import LiquidationsStatsOverTime from "src/pages/ccar-lending-page/tabs/liquidations/components/liquidations-stats-over-time";
import {
  LiquidationsDataCutType,
  LiquidationsTimeSpans,
  deltaTimeByTimeSpan,
  deltaTimes,
} from "src/pages/ccar-lending-page/tabs/liquidations/types";
import { useLiquidationsData } from "src/pages/ccar-lending-page/tabs/liquidations/use-liquidations-data";
import MarketsProvider from "src/pages/ccar-lending-page/tabs/markets-new/context/markets-context";
import { useClientConfig } from "../../clients-config";
import MarketMetricOverTimeCharts, { MarketHistoryValue } from "./components/market-metric-charts";
import { useFeatureFlag } from "../../../../utils/feature-flags";

const LiquidationsInner = () => {
  const { liquidations: liquidationsConfig } = useClientConfig();
  const [dataCut, setDataCut] = useState<LiquidationsDataCutType>({
    daysAgo: deltaTimes[1],
    marketIds: null,
    assets: null,
  });
  const badDebtWalletsChartsEnabled = useFeatureFlag("bad-debt-wallets-charts");
  const [selectedTimeSpan, setSelectedTimeSpan] = useState<LiquidationsTimeSpans>(TimeSpan.Month);

  useEffect(() => {
    setDataCut((prevDataCut) => ({
      ...prevDataCut,
      daysAgo: deltaTimeByTimeSpan[selectedTimeSpan],
    }));
  }, [selectedTimeSpan]);

  const {
    data: { liquidationsOverview, liquidations, liquidationsEvents, isLoadingBadDebt, badDebtData },
  } = useLiquidationsData({
    ...dataCut,
    timeSpan: selectedTimeSpan,
  });

  const { badDebtUsdData, numberOfBadDebtWalletsData } = (badDebtData || []).reduce<{
    badDebtUsdData: MarketHistoryValue[];
    numberOfBadDebtWalletsData: MarketHistoryValue[];
  }>(
    (acc, d) => {
      const badDebtPoint: MarketHistoryValue = {
        marketId: d.marketId,
        timestamp: d.timestamp,
        value: d.totalBadDebtUsd,
        assetBreakDown: d.assetsBreakdown.reduce((obj, a) => ({ ...obj, [a.asset]: a.badDebtUsd }), {}),
      };

      const badDebtWalletsPoint: MarketHistoryValue = {
        marketId: d.marketId,
        timestamp: d.timestamp,
        value: d.totalNumberOfBadDebtWallets,
        assetBreakDown: d.assetsBreakdown.reduce((obj, a) => ({ ...obj, [a.asset]: a.numberOfBadDebtWallets }), {}),
      };

      return {
        badDebtUsdData: [...acc.badDebtUsdData, badDebtPoint],
        numberOfBadDebtWalletsData: [...acc.numberOfBadDebtWalletsData, badDebtWalletsPoint],
      };
    },
    {
      badDebtUsdData: [],
      numberOfBadDebtWalletsData: [],
    },
  );

  return (
    <Box height="100%" width="100%" display="flex" flexDirection="column" gap={3}>
      <LiquidationsDataCutHeader
        dataCut={dataCut}
        setDataCut={setDataCut}
        selectedTimeSpan={selectedTimeSpan}
        setSelectedTimeSpan={setSelectedTimeSpan}
      />
      <LiquidationsStatsOverTime dataCut={dataCut} overviewData={liquidationsOverview} liquidations={liquidations} />
      {badDebtWalletsChartsEnabled && (
        <>
          <MarketMetricOverTimeCharts
            dataCut={dataCut}
            isLoading={isLoadingBadDebt}
            metricName="Bad Debt"
            data={badDebtUsdData}
            currency="USD"
            timeSpan={selectedTimeSpan}
            marketChartProps={{
              description: (daysAgo: number) => `Total USD bad debt over the last ${daysAgo} days`,
            }}
            assetChartProps={{
              description: (daysAgo: number, market: string) =>
                `Total USD bad debt on the ${market} market over the last ${daysAgo} days`,
              tooltip:
                "For every wallet with bad debt, we calculate the amount of bad debt per asset based on its proportion of the total borrowed amount",
              emptyStateText: "This market currently has no bad debt",
            }}
          />

          <MarketMetricOverTimeCharts
            dataCut={dataCut}
            isLoading={isLoadingBadDebt}
            metricName="Bad Debt Wallets"
            data={numberOfBadDebtWalletsData}
            timeSpan={selectedTimeSpan}
            marketChartProps={{
              title: "Market-Based Trend Analysis of Wallets with Bad Debt",
              description: (daysAgo: number) => `The number of wallets with bad debt over the last ${daysAgo} days`,
            }}
            assetChartProps={{
              title: "Trend Analysis of Wallets with Bad Debt",
              tooltip:
                "For every wallet with bad debt, we count how many wallets have bad debt for a specific asset by checking if a wallet has borrowed that particular asset",
              description: (daysAgo: number, market: string) =>
                `The number of wallets with bad debt on the ${market} market over the last ${daysAgo} days`,
              emptyStateText: "This market currently has no bad debt",
            }}
          />
        </>
      )}
      {liquidationsConfig?.showLiquidationEvents && (
        <LiquidationsEventsTable
          liquidationsEvents={liquidationsEvents?.data}
          isLoading={liquidationsEvents?.isLoading || liquidationsEvents === undefined}
        />
      )}
      <WalletsEligibleForLiquidationTable />
    </Box>
  );
};

const Liquidations = () => (
  <MarketsProvider>
    <LiquidationsInner />
  </MarketsProvider>
);

export default Liquidations;
