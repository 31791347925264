import { RJSFSchema } from "@rjsf/utils";
import { AlertSeverity, AlertType } from "../../generated";
import { CcarClient } from "../alerts/utils";

export type AlertLevel = "GLOBAL" | "PROTOCOL" | "ALL";
export enum NotificationChannel {
  TELEGRAM = "TELEGRAM",
  SLACK = "SLACK",
  DISCORD = "DISCORD",
  JIRA = "JIRA",
  SNS = "SNS",
  WEBHOOKS = "WEBHOOKS",
}

export interface NotificationConfig {
  enabled: boolean;
  inputs?: any[];
}

export interface TriggerStepConfiguration {
  argumentsSchema: RJSFSchema;
  defaultArguments: any[];
}

export interface ClientConfig {
  enabled: boolean;
  hideFromUi?: boolean;
  overrideFetchInputs?: any[];
  overrideFilterInputs?: any[];
  notificationConfig?: Partial<Record<NotificationChannel, NotificationConfig>>;
}

export interface AlertSetting {
  alertId: string;
  name: string;
  description: string;
  enabled: boolean;
  alertType: AlertType;
  severity: AlertSeverity;
  level?: AlertLevel;
  frequencyInMinutes: number;
  keepAlertForInMinutes: number;
  isActionable: boolean;
  hideFromUi?: boolean;
  clientsConfig: Partial<Record<CcarClient, ClientConfig>>;
  evaluateStep: TriggerStepConfiguration;
  filterStep?: TriggerStepConfiguration;
  globalNotificationConfig: Partial<Record<NotificationChannel, NotificationConfig>>;
}

export function convertAlertLevelToDisplayName(level?: AlertLevel): string {
  switch (level) {
    case "GLOBAL":
      return "Global alert";
    default:
      return "Protocol alert";
  }
}
