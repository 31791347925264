import { Box, CustomReactSelect, Grid, TextField, Typography } from "@frontend/ui";
import { Controller, useFormContext } from "react-hook-form";
import { Stack, Switch } from "@mui/material";
import { alertTypeLabels } from "../../../../../utils/consts";
import { AlertSettingsForm } from "./types";

interface GeneralInformationSectionProps {
  submitting: boolean;
}

export const GeneralInformationSection = ({ submitting }: GeneralInformationSectionProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<AlertSettingsForm>();

  return (
    <Grid container spacing={2}>
      <Grid item lg={3} md={4} xs={12}>
        <Controller
          name="alertId"
          control={control}
          disabled
          render={({ field }) => <TextField fullWidth label="Alert ID" {...field} />}
        />
      </Grid>
      <Grid item lg={3} md={4} xs={12}>
        <Controller
          name="name"
          control={control}
          rules={{
            required: "Please enter a unique name for the webhook",
            maxLength: {
              value: 100,
              message: "Name must be less than 100 characters",
            },
            minLength: {
              value: 2,
              message: "Name must be at least 2 characters",
            },
          }}
          disabled={submitting}
          render={({ field }) => (
            <TextField fullWidth label="Name" error={!!errors.name} helperText={errors.name?.message} {...field} />
          )}
        />
      </Grid>
      <Grid item lg={3} md={4} xs={12}>
        <Controller
          name="level"
          control={control}
          disabled={submitting}
          render={({ field }) => (
            <CustomReactSelect
              controlShouldRenderValue
              label="Level"
              placeholder="Select level"
              options={[
                {
                  label: "Global alert",
                  value: "GLOBAL",
                },
                {
                  label: "Protocol alert",
                  value: "PROTOCOL",
                },
              ]}
              value={field.value}
              onChange={field.onChange}
              disabled={field.disabled}
            />
          )}
        />
      </Grid>
      <Grid item lg={3} md={4} xs={12}>
        <Controller
          name="severity"
          control={control}
          disabled={submitting}
          render={({ field }) => (
            <CustomReactSelect
              controlShouldRenderValue
              label="Severity"
              placeholder="Select sevirity"
              options={[
                {
                  label: "High",
                  value: "HIGH",
                },
                {
                  label: "Info",
                  value: "INFO",
                },
              ]}
              value={field.value}
              onChange={field.onChange}
              disabled={field.disabled}
            />
          )}
        />
      </Grid>
      <Grid item lg={3} md={4} xs={12}>
        <Controller
          name="alertType"
          control={control}
          disabled={submitting}
          render={({ field }) => (
            <CustomReactSelect
              controlShouldRenderValue
              label="Alert Type"
              placeholder="Select type"
              options={Object.entries(alertTypeLabels).map(([value, label]) => ({
                value,
                label,
              }))}
              value={field.value}
              onChange={field.onChange}
              disabled={field.disabled}
            />
          )}
        />
      </Grid>
      <Grid item lg={3} md={4} xs={12}>
        <Stack spacing={2}>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            sx={{
              pt: {
                lg: 4,
                md: 4,
              },
            }}
          >
            <Controller
              name="isActionable"
              control={control}
              disabled={submitting}
              render={({ field }) => <Switch {...field} checked={field.value} onClick={(e) => e.stopPropagation()} />}
            />
            <Typography variant="body2" color="almostWhite.main">
              Mark this alert as actionable
            </Typography>
          </Box>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="description"
          control={control}
          rules={{
            maxLength: {
              value: 500,
              message: "Name must be less than 500 characters",
            },
          }}
          disabled={submitting}
          render={({ field }) => (
            <TextField
              fullWidth
              label="Description"
              multiline
              rows={4}
              error={!!errors.description}
              helperText={errors.description?.message}
              {...field}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};
