import { FC, useState } from "react";
import { Link as RouterLink, useNavigate, useNavigationType } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import { BoxProps, SxProps, useMediaQuery } from "@mui/material";
import { IconButton } from "../icon-button";
import { CopyButton } from "../copy-button";
import { CustomIcon } from "../custom-icon";
import { Alert } from "../alert";
import { Box } from "../box";
import { Breadcrumbs } from "../breadcrumbs";
import { Button } from "../button";
import { Link } from "../link";
import { Typography } from "../typography";
import { Theme } from "../theme";
import { ChaosMenu, MenuItemType } from "../chaos-menu";
import { CryptoIcon } from "../crypto-icon";
import { Identicon } from "../identicon";

export type Breadcrumb = { title: string | React.ReactNode; href: string; icon?: string };

export type PageHeaderProps = {
  pageTitle: string | React.ReactNode;
  pageSubtitle?: string | React.ReactNode;
  breadcrumbTitle?: string | React.ReactNode;
  breadCrumbTitleIcon?: string;
  breadcrumbTitleLink?: string;
  extraData?: {
    text: string;
    allowCopy?: boolean;
    link?: { text: string; href: string; isExternal?: boolean };
  };
  breadcrumbsLinks?: Breadcrumb[];
  icon?: string | React.ReactNode;
  buttons?: React.ComponentProps<typeof Button>[];
  menuItems?: MenuItemType[];
  containerStyle?: SxProps<Theme>;
  suffixComponent?: React.ReactNode;
  backLink?: string;
  showBackButton?: boolean;
  identicon?: string;
  underlyingText?: React.ReactNode;
  badge?: { text: string; color?: string; bgcolor?: string };
  titleAlignment?: BoxProps["alignItems"];
};

export const PageHeader: FC<PageHeaderProps> = ({
  pageTitle,
  pageSubtitle,
  breadcrumbTitle,
  breadCrumbTitleIcon,
  breadcrumbTitleLink,
  breadcrumbsLinks,
  extraData,
  icon,
  buttons,
  menuItems,
  containerStyle,
  suffixComponent,
  backLink,
  showBackButton = true,
  identicon,
  underlyingText,
  badge,
  titleAlignment,
}) => {
  const type = useNavigationType();
  const navigate = useNavigate();
  const [snackbarText, setSnackbarText] = useState("");
  const isSmallWidth = useMediaQuery<Theme>((theme) => theme.breakpoints.down("md"));

  return (
    <Box marginY={5.5} width="100%" sx={containerStyle}>
      {!!breadcrumbsLinks?.length && (
        <Breadcrumbs aria-label="breadcrumb" separator={<CustomIcon icon="breadcrumbs-arrow" />} sx={{ mb: 2 }}>
          {breadcrumbsLinks.map(({ title, href, icon: breadCrumbIcon }) => (
            <Box display="flex" alignItems="center" key={breadCrumbIcon || title?.toString()}>
              {breadCrumbIcon && (
                <CryptoIcon
                  icon={breadCrumbIcon.toLowerCase()}
                  sx={{
                    px: 1,
                    img: { width: "24px", height: "24px" },
                  }}
                />
              )}
              <Link component={RouterLink} key={title?.toString()} to={href} color="almostWhite.main" underline="hover">
                {title}
              </Link>
            </Box>
          ))}
          <Box display="flex" alignItems="center" flexDirection="row">
            {breadCrumbTitleIcon && (
              <CryptoIcon icon={breadCrumbTitleIcon.toLowerCase()} sx={{ mx: 1 }} size="x-small" />
            )}
            {breadcrumbTitleLink ? (
              <Link
                key={breadcrumbTitleLink}
                component={RouterLink}
                to={breadcrumbTitleLink}
                color="almostWhite.main"
                underline="hover"
              >
                {breadcrumbTitle || pageTitle}
              </Link>
            ) : (
              <Typography color="white.main">{breadcrumbTitle || pageTitle}</Typography>
            )}
          </Box>
        </Breadcrumbs>
      )}
      <Box display="flex" justifyContent="space-between">
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }} flex={1}>
          <Box display="flex" alignItems="center">
            <Box display="flex" alignItems="center" flexGrow={1} flexWrap="wrap" gap={2}>
              {showBackButton && (
                <IconButton
                  size="small"
                  onClick={() => {
                    if (backLink) {
                      navigate(backLink);
                      return;
                    }

                    if (breadcrumbsLinks && type === "POP") {
                      navigate(breadcrumbsLinks[breadcrumbsLinks.length - 1].href);
                    } else {
                      navigate(-1);
                    }
                  }}
                >
                  <CustomIcon icon="arrow-left" />
                </IconButton>
              )}
              {icon && (
                <Box overflow="hidden" display="flex" alignItems="center">
                  {typeof icon === "string" ? <CryptoIcon icon={icon} /> : <Box mr={2}>{icon}</Box>}
                </Box>
              )}
              {typeof pageTitle === "string" ? (
                <Box
                  display="flex"
                  flexWrap="wrap"
                  alignItems={titleAlignment ?? (typeof pageSubtitle === "object" ? "center" : "baseline")}
                  gap={1}
                >
                  <Box display="flex" flexDirection="column">
                    <Box display="flex" alignItems="center">
                      {identicon && (
                        <Box mr={2}>
                          <Identicon seed={identicon} diameter={24} />
                        </Box>
                      )}
                      <Typography variant="h3" component="h1" data-testid="page-title">
                        {pageTitle}
                      </Typography>
                    </Box>
                    {underlyingText}
                  </Box>
                  {badge && (
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      borderRadius={1}
                      px={1}
                      ml={2}
                      color={badge.color}
                      bgcolor={badge.bgcolor || "#0366C180"}
                    >
                      {badge.text}
                    </Box>
                  )}
                  {typeof pageSubtitle === "string" ? (
                    <Typography variant="caption">{pageSubtitle}</Typography>
                  ) : (
                    pageSubtitle
                  )}
                </Box>
              ) : (
                <Box flex={1}>{pageTitle}</Box>
              )}
              {extraData && (
                <Box display="flex" alignSelf="end" alignItems="center" sx={{ svg: { fontSize: "12px" } }} gap={0.5}>
                  <Typography variant="caption">{extraData.text}</Typography>
                  {extraData.link && (
                    <Link
                      href={extraData.link.href}
                      target={extraData.link.isExternal ? "_blank" : undefined}
                      color="inherit"
                      variant="caption"
                      underline="hover"
                    >
                      <Typography className="gradient-link" variant="caption">
                        {extraData.link.text}
                      </Typography>
                    </Link>
                  )}
                  {extraData.allowCopy && (
                    <CopyButton text={extraData.text || extraData.link?.text || ""} onCopy={setSnackbarText} />
                  )}
                </Box>
              )}
              <Box ml="auto" display="flex" gap={1}>
                {buttons?.map((btnProps, i) => <Button key={i} color="primary" {...btnProps} />)}
              </Box>
              {menuItems && (
                <ChaosMenu items={menuItems}>
                  <IconButton sx={{ ml: 2 }} size="medium">
                    <CustomIcon icon="more" />
                  </IconButton>
                </ChaosMenu>
              )}
            </Box>
          </Box>
        </Box>
        {suffixComponent && !isSmallWidth && <Box ml="auto">{suffixComponent}</Box>}
      </Box>
      {suffixComponent && isSmallWidth && <Box mt={[2, 3]}>{suffixComponent}</Box>}
      <Snackbar open={!!snackbarText} autoHideDuration={2000} onClose={() => setSnackbarText("")}>
        <Alert onClose={() => setSnackbarText("")} severity="info">
          {snackbarText}
        </Alert>
      </Snackbar>
    </Box>
  );
};
