import { Box, Link, Typography } from "@frontend/ui";
import { IconButton } from "@frontend/ui/icon-button";
import { CustomIcon } from "@frontend/ui/custom-icon";
import ChaosLabsLogo from "src/assets/img/chaoslabs.svg";

export const PublicFooter = (): JSX.Element => (
  <Box
    paddingTop={{ xs: 5, md: 10 }}
    paddingBottom={{ xs: 5, md: 10 }}
    px={{
      xs: 2,
      sm: 5,
      md: 10,
      lg: 15,
    }}
    bgcolor="#0C0D10"
    width="100%"
    display="flex"
    flexDirection="column"
    gap={{ xs: 6, md: 15 }}
  >
    <Box display="flex" gap={{ xs: 4, md: 10 }} flexWrap="wrap">
      <Link target="_blank" href="https://chaoslabs.xyz/about" style={{ textDecoration: "none", color: "white" }}>
        <Typography fontSize="16px" fontWeight="600" whiteSpace="nowrap" align="center">
          Company
        </Typography>
      </Link>
      <Link target="_blank" href="https://chaoslabs.xyz/blog" style={{ textDecoration: "none", color: "white" }}>
        <Typography fontSize="16px" fontWeight="600" whiteSpace="nowrap" align="center">
          Blog
        </Typography>
      </Link>
      <Box display="flex" gap={1.5}>
        <Link target="_blank" href="https://chaoslabs.xyz/careers" style={{ textDecoration: "none", color: "white" }}>
          <Typography fontSize="16px" fontWeight="600" whiteSpace="nowrap" align="center">
            Careers
          </Typography>
        </Link>
        <Box paddingX={2} borderRadius={2} bgcolor="#30D4C1" color="black.main">
          We're Hiring!
        </Box>
      </Box>
      <Box>
        <Link target="_blank" href="/privacy" style={{ textDecoration: "none", color: "white" }}>
          <Typography fontSize="16px" fontWeight="600" whiteSpace="nowrap" align="center">
            Privacy
          </Typography>
        </Link>
      </Box>
      <Box>
        <Link target="_blank" href="/terms" style={{ textDecoration: "none", color: "white" }}>
          <Typography fontSize="16px" fontWeight="600" whiteSpace="nowrap" align="center">
            Terms of Service
          </Typography>
        </Link>
      </Box>
    </Box>
    <Box display="flex" justifyContent="space-between" flexWrap="wrap-reverse" gap={2} alignItems="center">
      <Box>
        <Link target="_blank" href="https://chaoslabs.xyz/">
          <img src={ChaosLabsLogo} width="226px" height="40px" alt="ChaosLabsLogo" />
        </Link>
      </Box>
      <Typography variant="caption">
        For informational purposes only. This is not professional, financial, or legal advice.
      </Typography>
      <Box display="flex" alignItems="center" gap={3.5}>
        <Typography fontSize="16px" fontWeight="600" whiteSpace="nowrap" align="center">
          FOLLOW US
        </Typography>
        <IconButton size="small" sx={{ p: "0 !important" }} target="_blank" href="https://twitter.com/chaos_labs">
          <CustomIcon icon="outline-twitter" />
        </IconButton>
        <IconButton
          size="small"
          sx={{ p: "0 !important" }}
          target="_blank"
          href="https://linkedin.com/company/chaos-labs-xyz"
        >
          <CustomIcon icon="linkedin" />
        </IconButton>
        <IconButton size="small" sx={{ p: "0 !important" }} target="_blank" href="mailto:info@chaoslabs.xyz">
          <CustomIcon icon="outline-mail" />
        </IconButton>
      </Box>
    </Box>
  </Box>
);
