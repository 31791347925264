import { Paper, Typography } from "@frontend/ui";
import { PieChart } from "@frontend/ui/charts";
import { ShimmerLoader } from "@frontend/ui/shimmer-loader";
import { JlpPoolData } from "../../../generated";

type Props = {
  data?: JlpPoolData;
  isLoading: boolean;
};

const PoolAssets = ({ data, isLoading }: Props) => {
  const chartData = data?.liquidityAllocations.map((allocation) => ({
    x: allocation.tokenSymbol,
    y: allocation.sizeUsd,
  }));

  return (
    <Paper variant="card" sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Typography variant="h3">Pool Assets Distribution</Typography>
      <Paper variant="widget">
        {isLoading ? (
          <ShimmerLoader sx={{ height: 240 }} />
        ) : (
          <PieChart data={chartData} format="currency" size={240} />
        )}
      </Paper>
    </Paper>
  );
};

export default PoolAssets;
