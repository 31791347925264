import { createContext, FC, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { MultiOptionType } from "@frontend/ui";
import axios from "axios";
import { AlertLevel, AlertSetting } from "./utils";
import { CcarClient } from "../alerts/utils";
import { dataFetchersURL } from "../../../../utils/data-fetchers-url";

interface AlertSettingsTableData {
  alertSettings: AlertSetting[];
  loading: boolean;
  onUpdate: (alertId: string, updatedAlert: Partial<Omit<AlertSetting, "alertId">>) => Promise<AlertSetting | null>;
  onSearch: (searchText: string) => void;
  onAlertLevelFilterChange: (level: AlertLevel) => void;
  onProtocolsFilterChange: (protocols: MultiOptionType[]) => void;
  selectedAlertLevel: AlertLevel;
  selectedProtocols: MultiOptionType[];
  resetFilters: () => void;
}

const AlertsSettingsContext = createContext<AlertSettingsTableData>({
  alertSettings: [],
  loading: false,
  onUpdate: async () => null,
  onSearch: () => {},
  onAlertLevelFilterChange: () => {},
  onProtocolsFilterChange: () => {},
  selectedAlertLevel: "ALL",
  selectedProtocols: [],
  resetFilters: () => {},
});

const useAlertSettingsFetcher = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<AlertSetting[]>([]);

  const getAlertsSetting = async () => {
    setLoading(true);

    try {
      const res = await axios.get<AlertSetting[]>(`${dataFetchersURL()}data/alerts-setting`, {
        withCredentials: true,
      });
      setData(res.data);
    } catch (e) {
      setData([]);
    }

    setLoading(false);
  };

  useEffect(() => {
    void getAlertsSetting();
  }, []);

  const updateAlertSettings = (alertId: string, updatedAlert: AlertSetting) => {
    data[data.findIndex((alert) => alert.alertId === alertId)] = updatedAlert;
  };

  return {
    loading,
    data,
    updateAlertSettings,
  };
};

export const useAlertSettings = () => useContext<AlertSettingsTableData>(AlertsSettingsContext);

interface AlertSettingsProviderProps {
  children: ReactNode;
}

export const AlertSettingsProvider: FC<AlertSettingsProviderProps> = ({ children }) => {
  const [alertLevelFilter, setAlertLevelFilter] = useState<AlertLevel>("ALL");
  const [protocolsFilter, setProtocolsFilter] = useState<MultiOptionType[]>([]);
  const [search, setSearch] = useState("");

  const { loading, data, updateAlertSettings } = useAlertSettingsFetcher();

  const filteredData = useMemo(
    () =>
      data?.filter(
        (alertSetting) =>
          (alertSetting.level === alertLevelFilter || alertLevelFilter === "ALL") &&
          (protocolsFilter.length === 0 ||
            protocolsFilter.some((p) => alertSetting.clientsConfig[p.value as CcarClient]?.enabled) ||
            (alertSetting.level === "GLOBAL" && alertLevelFilter === "GLOBAL")) &&
          (!search || alertSetting.name.toLowerCase().includes(search)),
      ),
    [data, alertLevelFilter, protocolsFilter, search],
  );

  const resetFilters = useCallback(() => {
    setAlertLevelFilter("ALL");
    setProtocolsFilter([]);
    setSearch("");
  }, []);

  const value = useMemo(
    () => ({
      alertSettings: filteredData,
      loading,
      onUpdate: async (
        alertId: string,
        updatedAlert: Partial<Omit<AlertSetting, "alertId">>,
      ): Promise<AlertSetting | null> => {
        try {
          const res = await axios.put<AlertSetting>(
            `${dataFetchersURL()}data/alerts-setting/${alertId}`,
            updatedAlert,
            {
              withCredentials: true,
            },
          );
          if (res.status === 200) {
            updateAlertSettings(alertId, res.data);
            return res.data;
          }
          return null;
        } catch (e) {
          return null;
        }
      },
      onSearch: (searchText: string) => {
        setSearch(searchText?.toLowerCase() ?? "");
      },
      onAlertLevelFilterChange: (level: AlertLevel) => {
        setAlertLevelFilter(level);
      },
      onProtocolsFilterChange: (protocols: MultiOptionType[]) => {
        setProtocolsFilter(protocols);
      },
      selectedAlertLevel: alertLevelFilter,
      selectedProtocols: protocolsFilter,
      resetFilters,
    }),
    [alertLevelFilter, filteredData, loading, protocolsFilter, updateAlertSettings, resetFilters],
  );

  return <AlertsSettingsContext.Provider value={value}>{children}</AlertsSettingsContext.Provider>;
};
