import SchemaForm from "@rjsf/mui";
import { RJSFSchema } from "@rjsf/utils";
import validator from "@rjsf/validator-ajv8";
import { Box } from "@frontend/ui";

export const JsonSchemaInput = <T = any>({
  value,
  onChange,
  schema,
  title,
  disabled,
}: {
  value: T[];
  onChange: (value: T[]) => void;
  schema: RJSFSchema | null;
  title?: string;
  disabled?: boolean;
}) => {
  if (!schema) {
    return null;
  }

  if (!schema.properties) {
    return null;
  }

  const properties = Object.keys(schema.properties);

  const defaultValues = properties.reduce(
    (acc, property, index) => ({
      ...acc,
      [property]: value?.[index],
    }),
    {},
  );

  return (
    <SchemaForm
      tagName={Box}
      schema={{
        title,
        ...schema,
      }}
      uiSchema={{
        "ui:submitButtonOptions": {
          norender: true,
        },
      }}
      showErrorList={false}
      validator={validator}
      liveValidate
      liveOmit
      omitExtraData
      disabled={disabled}
      formData={defaultValues}
      onChange={({ formData }) => {
        onChange(Object.values(formData as Record<string, T>));
      }}
    />
  );
};
