import { Box, ECharts, Paper, Typography } from "@frontend/ui";
import { ShimmerLoader } from "@frontend/ui/shimmer-loader";
import { palette } from "@frontend/ui/theme/palette";
import { formatAmount, formatAxis } from "@frontend/ui/utils/formatters";
import { WalletLiquidationRange } from "src/pages/ccar-lending-page/generated";

type Props = {
  range?: WalletLiquidationRange;
  loading?: boolean;
};

export const LiquidationRange = ({ range, loading }: Props) => (
  <Paper variant="widget" sx={{ height: 368 }}>
    <Box height="100%" px={1} pb={2}>
      <Typography variant="h4">Liquidation Range</Typography>
      {loading ? (
        <ShimmerLoader />
      ) : (
        <ECharts
          option={{
            animation: false,
            tooltip: {
              trigger: "item",
              displayMode: "single",
              // eslint-disable-next-line
              formatter: (params: any) => params.data.name,
            },
            grid: {
              top: 60,
              left: 10,
              right: 20,
            },
            yAxis: {
              show: false,
            },
            xAxis: {
              type: "value",
              min: 0,
              max: range?.maxPrice,
              axisLabel: {
                formatter: (value: number) => formatAxis(value, 2, "USD"),
                fontSize: 12,
              },
              axisLine: {
                show: true,
                lineStyle: {
                  type: "dashed",
                },
              },
              splitLine: {
                show: false,
              },
            },
            series: [
              {
                type: "bar",
                markArea: {
                  itemStyle: {
                    color: palette.almostWhite.opacity20,
                    borderColor: palette.blue.main,
                    borderWidth: 2,
                  },
                  label: {
                    show: false,
                  },
                  data: [
                    [
                      {
                        name: `Liquidation Range\n${formatAmount(range?.upperBandPrice || 0, {
                          currency: "USD",
                        })} - ${formatAmount(range?.lowerBandPrice || 0, { currency: "USD" })}`,
                        xAxis: range?.lowerBandPrice,
                        yAxis: 0,
                      },
                      {
                        xAxis: range?.upperBandPrice,
                        yAxis: 1,
                      },
                    ],
                  ],
                },
                markLine: {
                  itemStyle: {
                    color: palette.red.main,
                  },
                  lineStyle: {
                    type: "solid",
                    width: 2,
                  },
                  symbol: [],
                  label: {
                    color: palette.almostWhite.main,
                    fontFamily: "Archivo",
                    fontSize: 12,
                    backgroundColor: palette.almostWhite.opacity20,
                    padding: 8,
                    borderRadius: 4,
                  },
                  data: [
                    [
                      {
                        name: `Oracle Price\n${formatAmount(range?.oraclePrice || 0, { currency: "USD" })}`,
                        xAxis: range?.oraclePrice,
                        yAxis: 0,
                      },
                      {
                        xAxis: range?.oraclePrice,
                        yAxis: 1,
                      },
                    ],
                  ],
                },
              },
            ],
          }}
        />
      )}
    </Box>
  </Paper>
);
