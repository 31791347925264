import { Paper, CompositionOverTimeChart } from "@frontend/ui";
import { NumberChartSeries } from "@frontend/ui/echarts/types";
import { palette } from "@frontend/ui/theme/palette";
import { HistoryDataFragment } from "src/pages/ccar-perpetuals-page/generated";

type Props = {
  loading: boolean;
  longLeverage?: HistoryDataFragment[];
  shortLeverage?: HistoryDataFragment[];
};

const getChartProps = (
  longLeverage: HistoryDataFragment[],
  shortLeverage: HistoryDataFragment[],
): { series: NumberChartSeries[] } => ({
  series: [
    {
      label: "Short",
      color: palette.red.main,
      type: "bar",
      data: shortLeverage.map((s) => [s.time, s.value]),
    },
    {
      label: "Long",
      color: palette.green.main,
      type: "bar",
      data: longLeverage.map((s) => [s.time, s.value]),
    },
  ],
});

export const AverageLeverageChart = ({ loading, longLeverage = [], shortLeverage = [] }: Props) => {
  const { series } = getChartProps(longLeverage, shortLeverage);

  return (
    <Paper variant="widget">
      <CompositionOverTimeChart
        title="Average Leverage"
        isLoading={loading}
        hideTotal
        series={series}
        showLegend={false}
        currency=""
      />
    </Paper>
  );
};
