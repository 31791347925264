import { Header, RenderData, TokenHolder } from "@frontend/types";
import { Paper, ECharts, Box, Typography, ChaosTable } from "@frontend/ui";
import { palette } from "@frontend/ui/theme/palette";
import { formatAddressCompact, formatAmount, formatCurrency, formatPercent } from "@frontend/ui/utils/formatters";
import { EChartsOption } from "echarts";
import { useClientConfig } from "src/pages/ccar-lending-page/clients-config";
import { useTopHoldersQuery } from "src/pages/ccar-lending-page/generated";

const getTop10HoldersChartOptions = (holders: TokenHolder[]): EChartsOption => {
  const top10Holders = holders.sort((holderA, holderB) => (holderB.balance > holderA.balance ? 1 : -1)).slice(0, 10);

  return {
    tooltip: {
      trigger: "item",
    },
    yAxis: {
      type: "value",
      axisLabel: {
        formatter: (value: number) => formatAmount(value),
      },
    },
    xAxis: {
      type: "category",
      axisLabel: {
        formatter: (value: string) => (value.startsWith("0x") ? formatAddressCompact(value, 4) : value),
      },
    },
    series: [
      {
        data: top10Holders.map(({ addressLabel, address, balance }) => {
          if (addressLabel && addressLabel !== null) {
            return [String(addressLabel), balance];
          }
          return [address, balance];
        }),
        type: "bar",
        color: palette.turqouise.main,
        barWidth: 40,
        barGap: 65,
      },
    ],
  };
};

const tableHeaders = (asset?: string) => {
  const headers: Header[] = [
    {
      renderType: "TEXT",
      text: "Address",
    },
    {
      renderType: "TEXT",
      text: `Balance ${asset ? `(${asset})` : ""}`,
    },
    {
      renderType: "TEXT",
      text: "Balance (USD)",
    },
    {
      renderType: "TEXT",
      text: " % Token Supply",
    },
  ];
  return headers;
};

export const TopTokenHolders = () => {
  const { stableCoin } = useClientConfig();
  const { data, loading } = useTopHoldersQuery();
  const topHolders = data?.topHolders.items || [];
  const top10Holders = topHolders.slice(0, 10);
  const tableData: RenderData[][] = topHolders.map(
    (holder) =>
      [
        {
          renderType: "TEXT",
          text: holder.addressLabel ? holder.addressLabel : formatAddressCompact(holder.address),
          value: holder.address,
          tooltipText: holder.address,
          copyText: holder.address,
        },
        {
          renderType: "TEXT",
          text: formatAmount(holder.balance),
          value: holder.balance,
        },
        {
          renderType: "TEXT",
          text: formatCurrency(holder.value),
          value: holder.value,
        },
        {
          renderType: "TEXT",
          text: formatPercent(holder.share / 100),
          value: holder.share,
        },
      ] as RenderData[],
  );

  return (
    <Paper variant="card" sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
      <Box>
        <Typography variant="h3">Top Token Holders</Typography>
        {stableCoin?.asset && <Typography>Wallets with largest balances of {stableCoin.asset}. </Typography>}
      </Box>
      <Paper variant="widget" sx={{ display: "flex", flexDirection: "column", gap: 3 }} data-testid="top-holders-chart">
        <Typography variant="h4">Top 10 Token Holders</Typography>
        <Box height={300}>
          <ECharts option={getTop10HoldersChartOptions(top10Holders)} />
        </Box>
      </Paper>
      <ChaosTable
        headers={tableHeaders(stableCoin?.asset)}
        data={tableData}
        pageSize={7}
        isWrapped={false}
        isFullHeight
        isFilterHidden
        initialSortBy={3}
        isInitialSortDesc
        isLoading={loading}
      />
    </Paper>
  );
};
