import { Grid } from "@frontend/ui";
import { ValueCard, ValueCardProps } from "@frontend/ui/value-card";
import { GmPoolFragment } from "src/pages/ccar-perpetuals-page/generated";

type Props = {
  gmPool?: GmPoolFragment;
};

const Cards = ({ gmPool }: Props) => {
  const cardsProps: ValueCardProps[] = [
    {
      title: "Pool Value",
      value: gmPool?.poolValue,
      icon: "coins-stack-icon",
      currency: "USD",
    },
    {
      title: "Funding Rate Per 1h",
      value: gmPool
        ? [
            {
              title: "Short",
              value: gmPool.fundingRateShort / 100,
              isPercent: true,
              showSign: true,
              valueMaximumFractionDigits: 4,
            },
            {
              title: "Long",
              value: gmPool.fundingRateLong / 100,
              isPercent: true,
              showSign: true,
              valueMaximumFractionDigits: 4,
            },
          ]
        : undefined,
    },
    {
      title: "Liquidity Long",
      value: gmPool?.longInterestAmountUsd,
      icon: "line-up-smooth",
      currency: "USD",
      progress: gmPool
        ? {
            maxValue: gmPool?.maxReservedLongUsd,
            displayMaxValue: true,
          }
        : undefined,
    },
    {
      title: "Liquidity Short",
      value: gmPool?.shortInterestAmountUsd,
      icon: "line-down-smooth",
      currency: "USD",
      progress: gmPool
        ? {
            maxValue: gmPool?.maxReservedShortUsd,
            displayMaxValue: true,
          }
        : undefined,
    },
  ];

  return (
    <Grid container spacing={{ xs: 1, md: 3 }}>
      {cardsProps.map((cardProps, i) => (
        <Grid
          key={`${cardProps.title}${i.toString()}`}
          item
          zeroMinWidth
          xs={12}
          md={6}
          lg={3}
          data-testid="asset-stats"
        >
          <ValueCard {...cardProps} />
        </Grid>
      ))}
    </Grid>
  );
};

export default Cards;
