import { CompositionOverTimeChart, Paper } from "@frontend/ui";
import { useStablecoinDataContext } from "../../provider";

export const CollateralRatioChart = () => {
  const { isLoading, history, tokenSymbol } = useStablecoinDataContext();

  const distribution = Object.values(history ?? {});
  return (
    <Paper variant="widget" data-testid="collateral-ratio-chart">
      <CompositionOverTimeChart
        isLoading={isLoading}
        title="Collateral Ratio Over Time"
        description={`The weighted average of the collateral to borrow ratio across all
      positions, weighted by the amount of ${tokenSymbol} borrowed.`}
        isPercent
        series={[
          {
            label: "Collateral Ratio",
            type: "line",
            data: distribution.map((d) => [d.date * 1000, d.averageCollateralRatio]),
          },
        ]}
      />
    </Paper>
  );
};
