import { CompositionOverTimeChart, Paper, Typography } from "@frontend/ui";
import { NumberChartSeries } from "@frontend/ui/echarts/types";
import { TimeSpan } from "src/pages/ccar-lending-page/generated";
import { LiquidationsHistogramDataType } from "../types";

type Props = {
  data: LiquidationsHistogramDataType;
  isLoading?: boolean;
  description?: string;
  timeSpan?: TimeSpan;
};

const getChartProps = (liquidationsDaily: LiquidationsHistogramDataType): { series: NumberChartSeries[] } => {
  const dates = Array.from(
    new Set(Object.values(liquidationsDaily).flatMap((v) => v.flatMap((d) => new Date(d.x).getTime()))),
  ).sort();
  return {
    series: Object.entries(liquidationsDaily).map(([asset, d]) => ({
      name: asset,
      label: asset,
      type: "bar",
      stack: "Asset",
      barWidth: 8,
      barGap: 1,
      data: dates.map((date) => {
        const datum = d.find((v) => new Date(v.x).getTime() === date);
        return [date, Number(datum?.y) || 0];
      }),
      emphasis: {
        focus: "series",
      },
    })),
  };
};

const LiquidationsHistogram = ({ data, description, timeSpan, isLoading }: Props) => {
  const { series } = getChartProps(data);
  return (
    <Paper variant="card">
      <Typography variant="h4" component="h2">
        Liquidations Over Time
      </Typography>
      {description && <Typography>{description}</Typography>}
      <Paper variant="widget" sx={{ mt: 2 }}>
        <CompositionOverTimeChart
          title="Liquidations Over Time"
          description={description}
          yAxisLabel="Liquidated Amount (USD)"
          isLoading={isLoading}
          chartHeight={380}
          series={series}
          emptyState={!series.length && !isLoading}
          timeSpan={timeSpan}
        />
      </Paper>
    </Paper>
  );
};

export default LiquidationsHistogram;
