import { Box, CompositionOverTimeChart, CustomReactSelect, Grid, Paper } from "@frontend/ui";
import { useClientConfig } from "src/pages/ccar-lending-page/clients-config";
import { NumberChartSeries, TimeSpan } from "@frontend/ui/echarts/types";
import { useEffect, useMemo, useState } from "react";
import { getIconSrc } from "@frontend/ui/crypto-icon/get-icon-src";
import { LiquidationsDataCutType } from "../types";
import { useMarketsContext } from "../../markets-new/context/markets-context";

export type MarketHistoryValue = {
  marketId: string;
  timestamp: number;
  value: number;
  assetBreakDown: Record<string, number>;
};

type ChartProps = {
  title?: string;
  tooltip?: string;
  emptyStateText?: string;
  description?: (daysAgo: number, market: string) => string;
};

type Props = {
  metricName: string;
  dataCut: LiquidationsDataCutType;
  data?: MarketHistoryValue[];
  isLoading: boolean;
  currency?: string;
  marketChartProps?: ChartProps;
  assetChartProps?: ChartProps;
  timeSpan?: TimeSpan;
};

const MarketMetricOverTimeCharts = ({
  metricName,
  dataCut,
  data,
  isLoading,
  currency,
  marketChartProps,
  assetChartProps,
  timeSpan,
}: Props) => {
  const { marketType, isMultiMarket, isMultichain } = useClientConfig();
  const { isLoading: isLoadingMarkets, markets } = useMarketsContext();
  const [selectedMarket, setSelectedMarket] = useState<string>();
  const sortedMarkets = [...markets].sort((m1, m2) => m2.totalBorrowUsd - m1.totalBorrowUsd);

  useEffect(() => {
    if (sortedMarkets?.length && !selectedMarket) {
      setSelectedMarket(sortedMarkets[0].id);
    }
  }, [sortedMarkets, selectedMarket]);

  const marketsOptions = (sortedMarkets || []).map(({ id, name, chain }) => {
    const showChain = isMultichain && marketType !== "ChainMarket";

    return {
      value: id,
      label: showChain ? `${chain} - ${name}` : name,
      cryptoIcon: marketType !== "GeneralMarket" && getIconSrc(name) ? name.toLowerCase() : undefined,
    };
  });

  const selectedOption = marketsOptions.find((m) => m.value === selectedMarket);

  const seriesByMarket = useMemo(
    () =>
      (data || []).reduce(
        (acc, d: MarketHistoryValue) => {
          const market = sortedMarkets.find((m) => m.id === d.marketId);

          if (!market) return acc;

          const showChain = isMultichain && marketType !== "ChainMarket";

          const { timestamp, value } = d;
          const prevData = acc[market.id]?.data || [];

          const series: NumberChartSeries = {
            label: showChain ? `${market.chain} - ${market.name}` : market.name,
            type: "bar",
            data: [...prevData, [timestamp, value]],
          };
          return { ...acc, [market.id]: series };
        },
        {} as Record<string, NumberChartSeries>,
      ),
    [data, sortedMarkets, isMultichain, marketType],
  );

  const seriesByAsset = useMemo(
    () =>
      (data || []).reduce(
        (acc, d: MarketHistoryValue) => {
          if (d.marketId !== selectedOption?.value) return acc;

          const { timestamp, assetBreakDown } = d;

          const newAssetsSeries = Object.entries(assetBreakDown).reduce((assetsSeries, [asset, value]) => {
            const prevData = acc[asset]?.data || [];
            const newEntry = [timestamp, value] as [number, number];
            const series: NumberChartSeries = {
              label: asset,
              type: "bar",
              data: [...prevData, newEntry],
            };
            return { ...assetsSeries, [asset]: series };
          }, {});

          return { ...acc, ...newAssetsSeries };
        },
        {} as Record<string, NumberChartSeries>,
      ),
    [data, selectedOption?.value],
  );

  const selectedMarketName = selectedOption?.label || "Market";

  return (
    <Paper variant="card">
      <Grid container spacing={2}>
        {isMultiMarket && (
          <Grid item xs={12} lg={6}>
            <Paper variant="widget" sx={{ height: "100%" }}>
              <CompositionOverTimeChart
                emptyState={!Object.values(seriesByMarket).length}
                emptyStateText={marketChartProps?.emptyStateText}
                tooltip={marketChartProps?.tooltip}
                key={`${metricName}-chart-per-market-${dataCut.daysAgo}`}
                title={marketChartProps?.title || `${metricName} By Market Over Time`}
                series={Object.values(seriesByMarket)}
                isLoading={isLoading || isLoadingMarkets}
                description={
                  marketChartProps?.description?.(dataCut.daysAgo, selectedMarketName) ||
                  `Value of ${metricName} in USD over the last ${dataCut.daysAgo} days`
                }
                currency={currency ?? ""}
                timeSpan={timeSpan}
              />
            </Paper>
          </Grid>
        )}

        <Grid item xs={12} lg={isMultiMarket ? 6 : 12}>
          <Paper variant="widget" sx={{ height: "100%" }}>
            <CompositionOverTimeChart
              tooltip={assetChartProps?.tooltip}
              emptyStateText={assetChartProps?.emptyStateText}
              emptyState={!Object.values(seriesByAsset).length}
              key={`${metricName}-${selectedOption?.value || ""}-chart-per-asset-${dataCut.daysAgo}`}
              title={assetChartProps?.title || `${metricName} Over Time`}
              series={Object.values(seriesByAsset)}
              isLoading={isLoading || isLoadingMarkets}
              description={
                assetChartProps?.description?.(dataCut.daysAgo, selectedMarketName) ||
                `Value of ${metricName} in USD on ${selectedOption?.label || "Market"} market over the last ${
                  dataCut.daysAgo
                } days`
              }
              currency={currency ?? ""}
              timeSpan={timeSpan}
              headerSuffix={
                isMultiMarket ? (
                  <Box ml={2}>
                    <CustomReactSelect
                      options={marketsOptions}
                      value={selectedOption}
                      isSearchable={false}
                      isLoading={isLoadingMarkets}
                      onChange={(opt) => {
                        setSelectedMarket(opt?.value);
                      }}
                    />
                  </Box>
                ) : undefined
              }
            />
          </Paper>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default MarketMetricOverTimeCharts;
