import { Box, Typography } from "@frontend/ui";
import { PieChart } from "@frontend/ui/charts";
import { ShimmerLoader } from "@frontend/ui/shimmer-loader";
import { useCollateralBreakdownQuery } from "../../../../generated";

const CollateralBreakdownPieChart = () => {
  const { loading, data } = useCollateralBreakdownQuery();
  const chartData = (data?.collateralBreakdown?.collateralBreakdown || []).map((c) => ({
    x: c.symbol,
    y: c.amountEth,
  }));

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Typography variant="h4">Collateral Breakdown</Typography>
      {loading ? (
        <ShimmerLoader sx={{ height: 288 }} />
      ) : (
        <PieChart data={chartData} format="number" labelSuffix="ETH" size={288} />
      )}
    </Box>
  );
};

export default CollateralBreakdownPieChart;
