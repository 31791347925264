import { Box, CustomIcon, IconButton, Paper, Tooltip, Typography } from "@frontend/ui";
import { DataItem, PieChart } from "@frontend/ui/charts";
import { downloadCsv } from "@frontend/ui/utils/csv";
import { ShimmerLoader } from "@frontend/ui/shimmer-loader";
import { LiquidationsDataCutType } from "../types";
import { LiquidationsDailyPerAsset } from "../../../generated";

type Props = {
  dataCut: LiquidationsDataCutType;
  liquidations: {
    isLoading: boolean;
    data?: LiquidationsDailyPerAsset[];
  };
};

export const LiquidationsPie = ({ dataCut, liquidations }: Props) => {
  const aggregated: DataItem[] = [];
  (liquidations.data || []).forEach((liq) => {
    const assetKey = liq.asset;

    if (aggregated.find((d) => d.x === assetKey)) {
      const index = aggregated.findIndex((d) => d.x === assetKey);
      aggregated[index] = {
        x: assetKey,
        y: (aggregated[index].y as number) + Number(liq.liquidationAmount),
      };
    } else {
      aggregated.push({
        x: assetKey,
        y: Number(liq.liquidationAmount),
      });
    }
  });

  const exportToCsv = () => {
    downloadCsv(
      ["Asset", "Liquidated (USD)"],
      aggregated.map((item) => [item.x?.toString() ?? "", item.y?.toString() ?? ""]),
      "Collateral Distribution",
    );
  };

  return (
    <Paper variant="card">
      <Box display="flex" alignItems="flex-start" justifyContent="space-between" mb={2}>
        <Box>
          <Typography variant="h4" component="h2">
            Collateral Distribution
          </Typography>
          <Typography>
            {`Amount of collateral liquidated ${
              dataCut.daysAgo ? `in the last ${dataCut.daysAgo} days` : ""
            }, by asset`}
          </Typography>
        </Box>
        <IconButton size="small" onClick={exportToCsv} sx={{ ml: 2 }}>
          <Tooltip title="Export to CSV">
            <CustomIcon icon="export" sx={{ svg: { width: 24, height: 24 } }} />
          </Tooltip>
        </IconButton>
      </Box>
      <Paper variant="widget" sx={{ mt: 2 }}>
        {!liquidations || liquidations.isLoading ? (
          <ShimmerLoader sx={{ height: 240 }} />
        ) : (
          <PieChart
            format="currency"
            showLegendIcon
            legendPrefix="Liquidated"
            size={240}
            data={aggregated.sort((a, b) => ((a.y ?? 0) > (b.y ?? 0) ? -1 : 1))}
            emptyState={!aggregated.length}
            pieLabelFormatNotation="compact"
          />
        )}
      </Paper>
    </Paper>
  );
};
