import { Grid } from "@frontend/ui";
import { ValueCard, ValueCardProps } from "@frontend/ui/value-card";
import { ChainPoolsData } from "../../../generated";

type Props = {
  data?: ChainPoolsData;
};

export const PoolsCards = ({ data }: Props) => {
  const cards: ValueCardProps[] = [
    {
      title: "Total Value Locked",
      value: data?.tvl,
      icon: "lock-icon",
      currency: "USD",
    },
    {
      title: "Total Borrowed",
      value: data?.totalBorrowed,
      currency: "USD",
      icon: "borrow-icon",
    },
    {
      title: "Total Supply",
      value: data?.totalSupply,
      icon: "deposit-icon",
      currency: "USD",
    },
    {
      title: "Total Value on Credit Accounts",
      value: data?.creditAccountsTotalValue,
      currency: "USD",
      icon: "coins-stack-icon",
    },
  ];

  return (
    <Grid container spacing={2}>
      {cards.map((card, i) => (
        <Grid key={`${card.title}${i.toString()}`} item xs={6} md={4} lg={3}>
          <ValueCard {...card} />
        </Grid>
      ))}
    </Grid>
  );
};

export default PoolsCards;
