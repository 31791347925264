import { Box, CustomIcon, ECharts, Paper, Tooltip, Typography } from "@frontend/ui";
import { ShimmerLoader } from "@frontend/ui/shimmer-loader";
import { usePriceChangeLiquidationOption } from "./use-price-change-liquidation-chart-options";
import { useStablecoinDataContext } from "../../provider";

export const AssetPriceLiquidationChart = () => {
  const option = usePriceChangeLiquidationOption();
  const { tokenSymbol } = useStablecoinDataContext();

  return (
    <Paper variant="card">
      <Paper variant="widget">
        <Typography variant="h2" sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          {tokenSymbol} at Risk{" "}
          <Tooltip
            title={
              <>
                Excludes stablecoins pegged
                <br />
                to the US Dollar (eg: USDT)
              </>
            }
          >
            <CustomIcon icon="info" sx={{ width: 20, height: 20 }} />
          </Tooltip>
        </Typography>
        <Typography variant="body1" mb={2}>
          {tokenSymbol} to be eligible for liquidation (in USD) in correlation with asset price changes, assuming no
          additional collateral is supplied by borrowers.
        </Typography>

        <Box height={350}>{option ? <ECharts option={option} /> : <ShimmerLoader />}</Box>
      </Paper>
    </Paper>
  );
};
