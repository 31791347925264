import { Box, CustomSwitch, Paper, Typography } from "@frontend/ui";
import { PieChart } from "@frontend/ui/charts";
import { ShimmerLoader } from "@frontend/ui/shimmer-loader";
import { useState } from "react";
import { TraderStats } from "src/pages/ccar-perpetuals-page/generated";

type Props = {
  traderStats?: TraderStats[];
  isLoading: boolean;
};
const DistributionCharts = ({ traderStats, isLoading }: Props) => {
  const [showMaxExposure, setShowMaxExposure] = useState(false);

  const getChartData = (key: keyof TraderStats) =>
    (traderStats || []).reduce((acc: { x: string; y: number }[], marketStats) => {
      const asset = marketStats.asset.toUpperCase();
      if (asset === "PORTFOLIO") return acc;
      const newPoint = {
        x: marketStats.asset.toUpperCase(),
        y: Number(marketStats[key]),
      };
      return [...acc, newPoint];
    }, []);

  return (
    <Paper variant="card" sx={{ display: "flex", gap: 2 }}>
      <Paper variant="widget" sx={{ display: "flex", flexDirection: "column", gap: 2, width: 0, flex: 1 }}>
        <Typography variant="h5">Volume Distribution</Typography>
        {isLoading ? (
          <ShimmerLoader sx={{ height: 240 }} />
        ) : (
          <PieChart format="currency" showLegendIcon size={240} data={getChartData("volumeUsd")} />
        )}
      </Paper>
      <Paper variant="widget" sx={{ display: "flex", flexDirection: "column", gap: 2, width: 0, flex: 1 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h5">Exposure Distribution</Typography>
          <CustomSwitch
            onChange={() => setShowMaxExposure((prev) => !prev)}
            checked={showMaxExposure}
            uncheckedLabel="Avg"
            checkedLabel="Max"
          />
        </Box>
        {isLoading ? (
          <ShimmerLoader sx={{ height: 240 }} />
        ) : (
          <PieChart
            format="number"
            showLegendIcon
            size={240}
            data={getChartData(showMaxExposure ? "maxGrossExposure" : "avgGrossExposure")}
          />
        )}
      </Paper>
    </Paper>
  );
};

export default DistributionCharts;
