import { PieChart } from "@frontend/ui/charts";
import { wrappedSymbolToIconSymbol } from "@frontend/ui/utils/icon-helper";
import { useParams } from "react-router-dom";
import { Box, Grid, Paper, Typography, ValueOverTimeChart } from "@frontend/ui";
import { Loader } from "@frontend/ui/loader";
import { useClientConfig } from "../../clients-config";
import { useReservesData } from "./use-reserves-data";
import { Client } from "../../types";

const ReservesInner = () => {
  const { clientName } = useParams<{ clientName: Client }>();
  const { reserves } = useClientConfig();
  const { isLoading, chartsStats } = useReservesData(clientName!);
  return (
    <Box height="100%">
      {isLoading ? (
        <Box height="100%" display="flex" alignItems="center" justifyContent="center">
          <Loader />
        </Box>
      ) : (
        <Paper
          variant="card"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 3,
          }}
        >
          {reserves?.charts.includes("reserveDistribution") && chartsStats?.reserveDistribution && (
            <Box bgcolor="background.default" p={3} pb={3} borderRadius={2}>
              <Typography variant="h4" mb={3}>
                Reserve Distribution by Asset (USD)
              </Typography>
              <PieChart
                size={360}
                showLegendIcon
                legendPrefix="Reserves"
                format="currency"
                data={chartsStats?.reserveDistribution.sort((a, b) => {
                  const tokenA = wrappedSymbolToIconSymbol(a.x);
                  const tokenB = wrappedSymbolToIconSymbol(b.x);
                  return tokenA > tokenB ? 1 : -1;
                })}
              />
            </Box>
          )}
          {reserves?.charts.includes("reserveHistory") && chartsStats?.reserveHistory && (
            <Box bgcolor="background.default" p={3} pb={3} borderRadius={2}>
              <Typography variant="h4" mb={3}>
                Reserve Assets Over Time
              </Typography>
              <Grid container spacing={2}>
                {Object.entries(chartsStats?.reserveHistory).map(([assetSymbol, seriesData]) => (
                  <Grid key={assetSymbol} item xs={12} md={6}>
                    <Paper variant="widget">
                      <ValueOverTimeChart
                        title={assetSymbol}
                        series={[
                          {
                            label: assetSymbol,
                            data: seriesData,
                            type: "line",
                          },
                        ]}
                        currency=""
                        yAxisMax
                        yAxisMin
                        showLegend={false}
                      />
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            </Box>
          )}
        </Paper>
      )}
    </Box>
  );
};

const ReservesTab = () => <ReservesInner />;

export default ReservesTab;
