import { FC } from "react";
import { Box } from "../box";
import { Typography } from "../typography";
import { IconButton } from "../icon-button";
import { CustomIcon } from "../custom-icon";
import { NumberInput } from "../number-input";
import { InputLabel } from "../input-label";
import { Button } from "../button";
import { CryptoIcon } from "../crypto-icon";

export type AdvancedSettingsChain = { icon: string; name: string; isSelected: boolean };

export type AdvancedSettingsAsset = {
  icon: string;
  name: string;
  chains: AdvancedSettingsChain[];
  liquidationThreshold?: number;
};

type Props = {
  onClose: () => void;
  onChange: (assets: AdvancedSettingsAsset[]) => void;
  assets: AdvancedSettingsAsset[];
  liquidationThresholdLabel?: string;
};

export const AdvancedSettingsPopover: FC<Props> = ({
  onClose,
  onChange,
  assets,
  liquidationThresholdLabel = "Liquidation Threshold",
}) => (
  <Box bgcolor="grey.main" width={560}>
    <Box display="flex" p={2} pb={0}>
      <Typography variant="h4">Advanced Settings</Typography>
      <IconButton size="small" sx={{ ml: "auto" }} onClick={onClose}>
        <CustomIcon icon="close" size="small" sx={{ width: 12, height: 12 }} />
      </IconButton>
    </Box>
    {assets.map((asset) => (
      <Box display="flex" key={asset.name} mt={3}>
        <Box display="flex" flexDirection="column" width="100%">
          <Box display="flex" px={2} pb={1}>
            <CryptoIcon icon={asset.icon} />
            <Typography ml={1.5}>{asset.name}</Typography>
          </Box>
          <Box bgcolor="darkGrey.main" p={2}>
            <Box display="flex" flexDirection="row" flexWrap="wrap" gap={1} mb={3}>
              {asset.chains.map((chain) => (
                <Box display="flex" key={chain.name}>
                  <Button
                    sx={{ display: "flex", padding: 1, borderRadius: 1 }}
                    color={chain.isSelected ? "primary" : "secondary"}
                    onClick={() =>
                      onChange(
                        assets.map((a) =>
                          a.icon === asset.icon
                            ? {
                                ...a,
                                chains: a.chains.map((c) =>
                                  c.icon === chain.icon
                                    ? {
                                        ...c,
                                        isSelected: !c.isSelected,
                                      }
                                    : c,
                                ),
                              }
                            : a,
                        ),
                      )
                    }
                  >
                    <CryptoIcon icon={chain.icon} />
                    <Typography ml={0.5}>{chain.name}</Typography>
                  </Button>
                </Box>
              ))}
            </Box>
            <InputLabel>{liquidationThresholdLabel}</InputLabel>
            <NumberInput
              isPercent
              value={asset.liquidationThreshold}
              onChange={async (v) =>
                onChange(assets.map((a) => (a.icon === asset.icon ? { ...a, liquidationThreshold: v } : a)))
              }
            />
          </Box>
        </Box>
      </Box>
    ))}
  </Box>
);
