import { useRef, useState } from "react";
import { CustomIcon } from "../../custom-icon";
import { Button } from "../../button";
import { Filter, TableFilter } from "../table-filter";

export type ControlledFilterProps = {
  filters: Filter[];
  onChange: (updatedFilters: Filter[]) => void;
};

export const ControlledFilters = ({ filters, onChange }: ControlledFilterProps) => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const filterButtonRef = useRef<null | HTMLButtonElement>(null);

  return (
    <div>
      <Button
        color="secondary"
        disabled={!filters.length}
        ref={filterButtonRef}
        onClick={() => setIsFilterOpen(!isFilterOpen)}
        aria-label="Add Filter"
        data-testid="toggle-filter"
        endIcon={<CustomIcon icon="filter" />}
      >
        Filter
      </Button>
      <TableFilter
        isOpen={isFilterOpen}
        anchorEl={filterButtonRef.current}
        filters={filters}
        onChange={(updatedFilters) => {
          onChange(
            Object.values({
              ...updatedFilters,
            }),
          );
        }}
        close={() => setIsFilterOpen(false)}
      />
    </div>
  );
};
