import AlertsTable from "../../../../components/alerts-table/alerts";

export const AlertsTab = () => (
  <AlertsTable
    notificationsChannels={[
      {
        channel: "telegram",
        link: "https://t.me/+W2uSlczgHYxmOGFh",
      },
    ]}
  />
);
