import { Box } from "@frontend/ui";
import { OverviewCards, OverviewCharts } from "./components";

const Overview = () => (
  <Box display="flex" flexDirection="column" gap={3}>
    <OverviewCards />
    <OverviewCharts />
  </Box>
);

export default Overview;
