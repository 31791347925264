import { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TagChip } from "@frontend/ui/tag-chip";
import { Box, Typography, Tooltip, CryptoIcon } from "@frontend/ui";
import { wrappedSymbolToIconSymbol } from "@frontend/ui/utils/icon-helper";
import { formatAmount } from "@frontend/ui/utils/formatters";
import { Client } from "src/pages/ccar-lending-page/types";
import { Market, MarketData, MarketLiquidationBand } from "src/pages/ccar-lending-page/generated";
import { useMarketSymbols } from "src/pages/ccar-lending-page/tabs/markets";
import { RouteParams, RoutePath } from "src/config/routes";
import { useIsInUnifiedProduct } from "src/hooks/use-is-in-unified-product";
import { getHealthText, getHealthTooltip } from "src/pages/ccar-lending-page/utils/health-utils";
import { useClientConfig } from "src/pages/ccar-lending-page/clients-config";

interface MarketHealthScoreProps {
  market: Market;
  healthFactor: number;
  totalCollateral?: number;
  totalSupply?: number;
  totalBorrow?: number;
  eModeCategory?: { id: string; label: string };
  marketData?: MarketData;
  liquidationBands?: MarketLiquidationBand;
}

type MarketFieldProps = {
  title: string;
  value: number | string;
  currency?: string;
};

const MarketField = ({ title, value, currency }: MarketFieldProps) => {
  const displayValue = typeof value === "string" ? value : formatAmount(value, { currency, notation: "compact" });
  const tooltipValue = typeof value === "string" ? "" : formatAmount(value, { currency, notation: "standard" });

  return (
    <Box display="flex">
      <Typography variant="caption">{title}: &nbsp;</Typography>
      <Tooltip title={tooltipValue} placement="right-start">
        <Typography>{displayValue}</Typography>
      </Tooltip>
    </Box>
  );
};

export const MarketHealthScore: FC<MarketHealthScoreProps> = ({
  market,
  healthFactor,
  totalSupply,
  totalCollateral,
  totalBorrow,
  eModeCategory,
  marketData,
  liquidationBands,
}) => {
  const { clientName } = useParams<{ clientName: Client }>();
  const navigate = useNavigate();
  const { isHealthPercent, showChainMarketsBreakdown } = useClientConfig();
  const { marketSymbol1, marketSymbol2 } = useMarketSymbols(market);
  const { isInUnifiedProduct } = useIsInUnifiedProduct();

  const mainSymbol = showChainMarketsBreakdown ? marketData?.chain : marketSymbol1;

  return (
    <Box display="flex" flexDirection="column" alignItems="flex-start" width="100%" gap={[2, 3]}>
      <Box display="flex" width="100%" alignItems="center" gap={[2, 3]}>
        {mainSymbol && (
          <Box
            sx={{ transform: { xs: "scale(0.8)", md: "none" } }}
            display="flex"
            alignSelf="flex-start"
            minHeight={48}
          >
            {marketSymbol2 && (
              <CryptoIcon
                icon={wrappedSymbolToIconSymbol(marketSymbol2.toLowerCase())}
                sx={{
                  marginTop: { xs: -1.5, md: -3 },
                  img: { width: "48px", height: "48px" },
                }}
              />
            )}
            <CryptoIcon
              icon={wrappedSymbolToIconSymbol(mainSymbol.toLowerCase())}
              sx={{
                marginLeft: { xs: marketSymbol2 ? -1.5 : 0, md: marketSymbol2 ? -2 : 0 },
                marginBottom: { xs: marketSymbol2 ? -1.5 : 0, md: marketSymbol2 ? -3 : 0 },
                img: { width: "48px", height: "48px" },
              }}
            />
          </Box>
        )}
        <Box
          display="flex"
          flexGrow={1}
          alignItems="flex-start"
          sx={{ flexDirection: { xs: "column", md: "row" } }}
          gap={[0, 3]}
        >
          <Box>
            {marketData && <MarketField title="Market" value={marketData.name} />}
            <Box display="flex">
              <Typography variant="caption">Health Score: &nbsp;</Typography>
              <Tooltip title={getHealthTooltip(healthFactor || 0, isHealthPercent)}>
                <Typography
                  fontFamily="Archivo"
                  fontStyle="normal"
                  fontWeight={400}
                  fontSize={healthFactor === Number.MAX_VALUE ? 24 : 14}
                  lineHeight={healthFactor === Number.MAX_VALUE ? 0.9 : undefined}
                >
                  {getHealthText(healthFactor || 0, isHealthPercent)}
                </Typography>
              </Tooltip>
            </Box>
            {eModeCategory && (
              <Box display="flex">
                <Typography variant="caption" mr={1}>
                  E-Mode:
                </Typography>
                <TagChip
                  label={eModeCategory.label}
                  sx={{ bgcolor: "recommended.opacity50", "&:hover": { bgcolor: "recommended" } }}
                  onClick={
                    isInUnifiedProduct
                      ? () => {
                          navigate(
                            RoutePath.Risk.MarketEmodeDetails.replace(RouteParams.ClientName, clientName!)
                              .replace(RouteParams.MarketId, market.id)
                              .replace(RouteParams.EModeId, eModeCategory.id),
                          );
                        }
                      : undefined
                  }
                />
              </Box>
            )}
            {liquidationBands?.isInSoftLiquidation && (
              <TagChip label="Soft Liquidation" sx={{ bgcolor: "orange.opacity50" }} />
            )}
          </Box>
          <Box>
            {!totalCollateral && !totalBorrow && totalSupply !== undefined ? (
              <MarketField title="Supply" value={totalSupply} currency="USD" />
            ) : (
              <>
                {totalCollateral !== undefined && (
                  <MarketField title="Collateral" value={totalCollateral} currency="USD" />
                )}
                {totalBorrow !== undefined && <MarketField title="Borrow" value={totalBorrow} currency="USD" />}
              </>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
