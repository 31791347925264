import { AssetPriceLiquidationChart, Paper } from "@frontend/ui";
import {
  AggregateAssetStatsFragment,
  useAssetTotalValueAtRiskByChainQuery,
} from "src/pages/ccar-lending-page/generated";

type Props = {
  asset?: AggregateAssetStatsFragment;
};

export const ValueAtRisk = ({ asset }: Props) => {
  const tokenSymbol = asset?.symbol || "";
  const { data, loading } = useAssetTotalValueAtRiskByChainQuery({
    variables: {
      tokenSymbol,
    },
    skip: !asset,
  });

  return (
    <Paper variant="widget">
      <AssetPriceLiquidationChart
        title="Value at Risk of Liquidation"
        isLoading={loading}
        data={
          data?.assetTotalValueAtRiskByChain
            .map((d) => ({
              label: d.chain,
              currentPrice: d.currentAssetPrice,
              liquidationUsdValueByPrice: d.liquidationUsdValueByPrice,
            }))
            .sort((a, b) => (a.label > b.label ? -1 : 1)) || []
        }
      />
    </Paper>
  );
};
