import { CompositionOverTimeChart, Paper } from "@frontend/ui";
import {
  AggregateAssetStatsFragment,
  useAssetExposureDistributionOverTimeQuery,
} from "src/pages/ccar-lending-page/generated";

type Props = {
  asset?: AggregateAssetStatsFragment;
};

export const CrossChainExposure = ({ asset }: Props) => {
  const tokenSymbol = asset?.symbol || "";
  const { data, loading } = useAssetExposureDistributionOverTimeQuery({
    variables: {
      tokenSymbol,
    },
    skip: !asset,
  });
  const isCollateralizable = asset?.isCollateralizable;
  const openInterest =
    data?.assetExposureDistributionOverTime
      ?.map((item) => ({
        chain: item.chain,
        data: isCollateralizable ? item.openInterest.supply : item.openInterest.borrow,
      }))
      .sort((a, b) => (a.chain > b.chain ? -1 : 1)) || [];

  return (
    <Paper variant="widget">
      <CompositionOverTimeChart
        isLoading={!asset || loading}
        percentToggle
        title="Cross-Chain Exposure"
        description={asset ? `Exposure to ${asset.symbol || ""} across all markets` : " "}
        series={openInterest.map((distribution) => ({
          label: distribution.chain,
          data: distribution.data.map((b) => [b.time * 1000, b.value]),
        }))}
      />
    </Paper>
  );
};
