import { Box, Paper } from "@frontend/ui";
import TvlChart from "../../components/tvl-chart";
import TopAccountsChart from "../../components/top-accounts-chart";
import { OverviewCards } from "./cards";

const Overview = () => (
  <Box display="flex" flexDirection="column" gap={3}>
    <OverviewCards />
    <Paper variant="card" sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
      <TvlChart />
      <TopAccountsChart />
    </Paper>
  </Box>
);

export default Overview;
