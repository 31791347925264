import { Box, CompositionOverTimeChart, CustomReactSelect, Paper, Typography } from "@frontend/ui";
import { NumberChartSeries, TimeSpan } from "@frontend/ui/echarts/types";
import { capitalizeFirstLetter } from "@frontend/ui/utils/formatters";
import { useEffect, useState } from "react";
import { ProtocolPoolsHistoryFragment } from "src/pages/ccar-lending-page/generated";
import TvlBalanceToggle from "./tvl-balance-toggle";

type Props = {
  asset: string;
  poolsHistoryByProtocol?: ProtocolPoolsHistoryFragment[];
  timeSpan: TimeSpan;
  isLoading: boolean;
};
const ProtocolPoolsAmountsChart = ({ asset, poolsHistoryByProtocol, timeSpan, isLoading }: Props) => {
  const [showTvl, setShowTvl] = useState(false);
  const [selectedProtocol, setSelectedProtocol] = useState<string>();

  useEffect(() => {
    setSelectedProtocol((prev) => (!prev ? poolsHistoryByProtocol?.[0]?.protocol : prev));
  }, [poolsHistoryByProtocol]);

  const dropdownOptions = (poolsHistoryByProtocol || []).map(({ protocol }) => ({
    value: protocol,
    label: capitalizeFirstLetter(protocol),
    cryptoIcon: protocol,
  }));

  const selectedProtocolData = poolsHistoryByProtocol?.find(({ protocol }) => protocol === selectedProtocol);

  const poolsSeries = (selectedProtocolData?.data || []).reduce(
    (acc, protocolPools) => {
      const { timestamp, pools } = protocolPools;

      pools.forEach((pool) => {
        const { tvlUSD, tokens, name } = pool;
        const token = tokens.find((t) => t.symbol === asset);
        const amount = showTvl ? tvlUSD : Number(token?.balance);

        const dataEntry: [number, number] = [timestamp, amount || 0];

        const currentAssetSeries = acc[name];

        if (currentAssetSeries) {
          const { data: seriesData } = currentAssetSeries;
          const newData = [...seriesData, dataEntry];
          currentAssetSeries.data = newData;
        } else {
          const newSeries: NumberChartSeries = {
            label: name,
            type: "bar",
            data: [dataEntry],
          };
          acc[name] = newSeries;
        }
      });

      return acc;
    },
    {} as Record<string, NumberChartSeries>,
  );

  return (
    <Paper variant="card" sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h3">{asset} in Pools</Typography>
        <CustomReactSelect
          options={dropdownOptions}
          value={dropdownOptions.find((opt) => opt.value === selectedProtocol)}
          isSearchable={false}
          placeholder="Protocol"
          onChange={(opt) => setSelectedProtocol(opt?.value)}
        />
      </Box>
      <Paper variant="widget">
        <CompositionOverTimeChart
          key={`${selectedProtocol || ""}-${timeSpan}`}
          isLoading={isLoading}
          title=" "
          series={Object.values(poolsSeries)}
          currency={showTvl ? "USD" : ""}
          timeSpan={timeSpan}
          headerSuffix={<TvlBalanceToggle asset={asset} showTvl={showTvl} setShowTvl={setShowTvl} />}
        />
      </Paper>
    </Paper>
  );
};

export default ProtocolPoolsAmountsChart;
