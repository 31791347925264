import { Paper } from "@frontend/ui";
import { ShimmerLoader } from "@frontend/ui/shimmer-loader";
import { WalletHealthHistogramItem } from "../../../generated";
import MarketWalletsHealth from "./market-wallets-health";

type Props = {
  walletsHealth?: WalletHealthHistogramItem[];
  tokenSymbol: string;
  marketName?: string;
};

export const BorrowHealthHistogram = ({ walletsHealth, tokenSymbol, marketName }: Props) => (
  <Paper variant="widget" sx={{ height: { md: 416 } }}>
    {!walletsHealth && <ShimmerLoader sx={{ width: "100%", height: "100%", borderRadius: 2 }} />}
    {walletsHealth && (
      <MarketWalletsHealth
        title="Borrowers Market Wallets Health"
        description={`Count of wallets that have open borrow positions for ${tokenSymbol ?? "this asset"}
        ${marketName ? ` on the ${marketName} market` : ""}
        , bucketed by their respective health scores.`}
        walletsHealth={walletsHealth}
      />
    )}
  </Paper>
);

export const SupplyHealthHistogram = ({ walletsHealth, tokenSymbol, marketName }: Props) => (
  <Paper variant="widget" sx={{ height: { md: 416 } }}>
    {!walletsHealth && <ShimmerLoader sx={{ width: "100%", height: "100%", borderRadius: 2 }} />}
    {walletsHealth && (
      <MarketWalletsHealth
        title="Suppliers Market Wallets Health"
        description={`Count of wallets that have open supply positions for ${tokenSymbol ?? "this asset"}
      ${marketName ? ` on the ${marketName} market` : ""}
      , bucketed by their respective health scores.`}
        walletsHealth={walletsHealth}
      />
    )}
  </Paper>
);
