import { Paper, Typography, Box, CustomIcon, Tooltip, CompositionOverTimeChart } from "@frontend/ui";
import { palette } from "@frontend/ui/theme/palette";
import { DistributionItem } from "src/pages/ccar-lending-page/generated";
import { useClientConfig } from "src/pages/ccar-lending-page/clients-config";
import { useStablecoinDataContext } from "../../provider";

type GetSeriesData = (predicate: (c: DistributionItem) => boolean) => [number, number][];

export const AssetClassBreakdownChart = () => {
  const { stableCoin } = useClientConfig();
  const { isLoading, history = [], marketAssets = {}, tokenSymbol } = useStablecoinDataContext();

  const useCorrelatedAssets = (correlatedTo: string) =>
    Object.values(marketAssets)
      .filter((ma) => !stableCoin?.marketType || ma.marketType === stableCoin?.marketType)
      .filter((a) => a.correlatedSymbol === correlatedTo)
      .map((a) => a.asset);

  const ethCorrelatedAssets = useCorrelatedAssets("ETH");
  const btcCorrelatedAssets = useCorrelatedAssets("BTC");
  const stableAssets = Object.values(marketAssets)
    .filter((ma) => !stableCoin?.marketType || ma.marketType === stableCoin?.marketType)
    .filter((ma) => !btcCorrelatedAssets.includes(ma.asset))
    .filter((ma) => !ethCorrelatedAssets.includes(ma.asset))
    .filter((ma) => ma?.isStable)
    .map((a) => a.asset);
  const otherAssets = Object.values(marketAssets)
    .filter((ma) => !stableCoin?.marketType || ma.marketType === stableCoin?.marketType)
    .filter((ma) => !btcCorrelatedAssets.includes(ma.asset))
    .filter((ma) => !ethCorrelatedAssets.includes(ma.asset))
    .filter((ma) => !ma?.isStable === true)
    .map((a) => a.asset);

  const getSeriesData: GetSeriesData = (predicate) =>
    history.map((h) => [
      h.date * 1000,
      h.collateralDistribution.filter(predicate).reduce((curr, next) => curr + next.value, 0),
    ]);

  const ethCorrelatedSeries = getSeriesData((c) => ethCorrelatedAssets.includes(c.asset));
  const btcCorrelatedSeries = getSeriesData((c) => btcCorrelatedAssets.includes(c.asset));
  const stableAssetsSeries = getSeriesData((c) => stableAssets.includes(c.asset));
  const otherAssetsSeries = getSeriesData((c) => otherAssets.includes(c.asset));

  const series = [
    {
      label: "Stablecoins",
      color: palette.green.main,
      data: stableAssetsSeries,
    },
    ...(ethCorrelatedAssets && [
      {
        label: "ETH Correlated",
        color: palette.blue.main,
        data: ethCorrelatedSeries,
      },
    ]),
    ...(btcCorrelatedAssets && [
      {
        label: "BTC Correlated",
        color: palette.orange.main,
        data: btcCorrelatedSeries,
      },
    ]),
    {
      label: "Other",
      color: palette.red.main,
      data: otherAssetsSeries,
    },
  ];

  return (
    <Paper variant="widget" data-testid="collateral-by-category-chart">
      <Box mb={2}>
        <Box display="flex" gap={1}>
          <Typography variant="h4">Collateral Breakdown By Category</Typography>

          <Tooltip
            placement="top"
            title="ETH and BTC correlated are assets closely priced against these assts.
            Stables include assets pegged or softly-pegged to a fiat currency (e.g. USD)"
          >
            <CustomIcon icon="info" size="small" />
          </Tooltip>
        </Box>
        <Typography>
          Per asset breakdown of collateral asset class across all {tokenSymbol} borrow positions.
        </Typography>
      </Box>
      <Box height={285}>
        <CompositionOverTimeChart isLoading={isLoading} series={series} />
      </Box>
    </Paper>
  );
};
