import { PageHeader } from "@frontend/ui/page-header";
import { Box } from "@frontend/ui";
import { useParams } from "react-router-dom";
import { OrdersTable, PositionCards } from "./components";
import { RouteParams } from "../../../../../../config/route-params";
import { RoutePath } from "../../../../../../config/routes";
import { useClientConfig } from "../../../../clients-config";
import { usePositionOrdersQuery } from "../../../../generated";

const PositionDetails = () => {
  const { positions: positionsConfig } = useClientConfig();
  const { clientName, positionId } = useParams();
  const { data, loading } = usePositionOrdersQuery({
    variables: {
      positionId: positionId!,
    },
  });

  const breadcrumbsLinks = [
    {
      title: "Positions",
      href: RoutePath.Risk.Positions.replace(RouteParams.ClientName, clientName!),
    },
  ];

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      <PageHeader
        pageTitle="Position"
        breadcrumbTitle={positionId}
        extraData={{
          text: "",
          link: positionsConfig?.accountDetailsUrl
            ? {
                text: positionId!,
                href: `${positionsConfig?.accountDetailsUrl}${positionId!}`,
                isExternal: true,
              }
            : undefined,
          allowCopy: true,
        }}
        containerStyle={{ mt: 0, mb: 2 }}
        breadcrumbsLinks={breadcrumbsLinks}
      />
      <PositionCards position={data?.position} />
      <OrdersTable orders={data?.positionOrders} isLoading={loading} />
    </Box>
  );
};

export default PositionDetails;
