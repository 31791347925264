import { useParams } from "react-router-dom";
import { Box, Page404 } from "@frontend/ui";
import { PageHeader } from "@frontend/ui/page-header";
import { Loader } from "@frontend/ui/loader";
import { useMemo } from "react";
import { WalletPoolsTable, WalletPortfolioBreakdownChart, WalletStatsCards } from "./components";
import { useWalletDetailsQuery } from "../../generated";

export const WalletDetailsPage = () => {
  const { address } = useParams<{ address: string }>();
  const input = useMemo(() => ({ address: address! }), [address]);
  const { data, loading } = useWalletDetailsQuery({ variables: input });

  return (
    <Box
      sx={{
        display: "flex",
        gap: "24px",
        flexDirection: "column",
      }}
    >
      <Box display="flex" flexDirection="row">
        <PageHeader
          pageTitle="Wallet"
          containerStyle={{ mt: 0, mb: 4 }}
          extraData={{
            text: "",
            link: {
              text: address!,
              href: `https://www.mintscan.io/osmosis/account/${address!}`,
              isExternal: true,
            },
            allowCopy: true,
          }}
        />
      </Box>
      {loading ? (
        <Loader />
      ) : (
        <Box>
          {data?.walletDetails ? (
            <Box display="flex" flexDirection="column" gap={3}>
              <WalletStatsCards data={data.walletDetails} />
              <WalletPoolsTable data={data.walletDetails.pools} />
              <WalletPortfolioBreakdownChart data={data.walletDetails.pools} />
            </Box>
          ) : (
            <Page404 />
          )}
        </Box>
      )}
    </Box>
  );
};
