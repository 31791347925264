import { WithUnifiedProductRedirect } from "src/utils/feature-flags";
import { WalletsTable } from "../markets/components";

const WalletsTab = () => (
  <WithUnifiedProductRedirect>
    <WalletsTable />
  </WithUnifiedProductRedirect>
);

export default WalletsTab;
