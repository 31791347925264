import { Box, Paper, Typography } from "@frontend/ui";
import { AssetClassBreakdownChart } from "./components/asset-class-breakdown-chart";
import { CollateralCompositionChart } from "./components/collateral-composition-chart";
import { CollateralRatioChart } from "./components/collateral-ratio-chart";
import { useStablecoinDataContext } from "./provider";
import { MarketBreakdownChart } from "./components/market-breakdown-chart";
import { useClientConfig } from "../../clients-config";
import { StableCoinCollateralChart } from "../../types";

type Props = {
  hasPaper?: boolean;
};

const getChartNode = (chart: StableCoinCollateralChart) => {
  switch (chart) {
    case "assetBreakdown":
      return <CollateralCompositionChart key={chart} />;
    case "assetClassBreakdown":
      return <AssetClassBreakdownChart key={chart} />;
    case "collateralRatio":
      return <CollateralRatioChart key={chart} />;
    case "marketBreakdown":
      return <MarketBreakdownChart key={chart} />;
    default:
      return null;
  }
};

export const CollateralComposition = ({ hasPaper = true }: Props) => {
  const { tokenSymbol } = useStablecoinDataContext();
  const { stableCoin } = useClientConfig();
  const {
    title = `${tokenSymbol} Collateral Composition`,
    subtitle = `Analysis of collateral used to mint ${stableCoin?.asset || ""}.`,
    charts = [],
  } = { ...stableCoin?.collateral };

  if (!charts.length) {
    return null;
  }

  const content = (
    <>
      <Box>
        <Typography variant="h2">{title}</Typography>
        <Typography>{subtitle}</Typography>
      </Box>
      <Box
        sx={{
          display: "grid",
          gap: 3,
          gridTemplateColumns: {
            sx: "1fr",
            md: "1fr 1fr",
          },
          "& >.MuiPaper-root:first-child": {
            gridColumn: charts.length % 2 === 1 ? "1 / 3" : 1,
          },
        }}
      >
        {charts.map(getChartNode)}
      </Box>
    </>
  );

  if (hasPaper) {
    return (
      <Paper variant="card" sx={{ display: "grid", gap: 3 }}>
        {content}
      </Paper>
    );
  }

  return content;
};
