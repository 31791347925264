import { CompositionOverTimeChart, Paper } from "@frontend/ui";
import { NumberChartSeries } from "@frontend/ui/echarts/types";
import { useStablecoinDataContext } from "../../provider";
import { LendingEventType, useAggEventsQuery } from "../../../../generated";
import { useClientConfig } from "../../../../clients-config";

export const StablecoinMintBurnChart = () => {
  const { stableCoin } = useClientConfig();
  const { tokenSymbol, timeSpan } = useStablecoinDataContext();
  const { loading, data } = useAggEventsQuery({
    variables: {
      query: {
        timeSpan,
        assetSymbol: stableCoin!.asset,
        marketId: stableCoin!.marketId || null,
        eventsTypes: [LendingEventType.Borrow, LendingEventType.Repay],
      },
    },
    skip: !stableCoin,
  });

  const series = (data?.aggEvents ?? []).reduce((acc: Record<string, NumberChartSeries>, aggEvent) => {
    const { data: assetsData, timestamp, type } = aggEvent;
    const totalAmount = assetsData.reduce((total, a) => a.amount + total, 0);

    const currentSeries = acc[type];

    const multiplier = type === LendingEventType.Borrow ? 1 : -1;
    const amount = totalAmount * multiplier;

    const dataEntry: [number, number] = [timestamp, amount];

    if (currentSeries) {
      const { data: seriesData } = currentSeries;
      const lastEntry = seriesData[seriesData.length - 1];
      const isLastEntrySameTime = lastEntry[0] === timestamp;
      const newEntry: [number, number] = isLastEntrySameTime ? [timestamp, amount + lastEntry[1]] : dataEntry;
      const newData = [...seriesData, newEntry];
      currentSeries.data = newData;
      return acc;
    }

    const newSeries: NumberChartSeries = {
      label: type === LendingEventType.Borrow ? "Mint" : "Redeem",
      type: "bar",
      data: [dataEntry],
      stack: "bar",
    };
    acc[type] = newSeries;

    return acc;
  }, {});

  return (
    <Paper variant="widget" data-testid="change-per-day-chart">
      <CompositionOverTimeChart
        title={`${tokenSymbol} Change Per Day`}
        description="Showing the total amount of mint and redeem events each day. In the case of minting and redeeming a position within the same day, both are counted."
        isLoading={loading}
        series={Object.values(series)}
        hideTotal
        currency=""
        chartHeight={325}
        timeSpan={timeSpan}
      />
    </Paper>
  );
};
