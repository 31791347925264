import { ChaosTable } from "@frontend/ui";
import { useParams } from "react-router-dom";
import { RouteParams, RoutePath } from "src/config/routes";
import { useClientConfig } from "src/pages/ccar-lending-page/clients-config";
import { useMarketsContext } from "../../context/markets-context";
import { useTableConfig } from "./config";

const MarketsTable = () => {
  const { clientName } = useParams<{ clientName: string }>();
  const { stableCoin } = useClientConfig();
  const { isLoading, markets } = useMarketsContext();

  const config = useTableConfig(stableCoin?.showInOverview ? "stablecoin" : "lending", markets);
  const headers = config.getHeaders();
  const tableData = markets.map(config.getRowData);

  const handleOnClickRow = (rowIdx: number) => {
    const market = markets[rowIdx];

    return RoutePath.Risk.MarketDetails.replace(RouteParams.ClientName, clientName!).replace(
      RouteParams.MarketId,
      market.id,
    );
  };

  return (
    <ChaosTable
      title="Markets"
      headers={headers}
      data={tableData}
      isLoading={isLoading}
      pageSize={10}
      rowHref={handleOnClickRow}
      initialSortBy={config.initialSortBy}
      isInitialSortDesc
      showRowChevron
      isFullHeight
    />
  );
};

export default MarketsTable;
