import { Grid } from "@frontend/ui";
import { ValueCard, ValueCardProps } from "@frontend/ui/value-card";
import { PoolData } from "../../../generated";

type Props = {
  data?: PoolData;
};

const PoolDetailsCards = ({ data }: Props) => {
  const cards: ValueCardProps[] = [
    {
      title: "Total Supply",
      value: data?.totalSupplyUsd,
      icon: "deposit-icon",
      currency: "USD",
    },
    {
      title: "Total Borrowed",
      value: data?.totalBorrowUsd,
      currency: "USD",
      icon: "borrow-icon",
    },
    {
      title: "Deposit APY",
      value: data?.depositApy,
      icon: "circular",
      isPercent: true,
    },
    {
      title: "Utilization",
      value: data?.poolUtilization,
      icon: "pie-slice",
      isPercent: true,
    },
    {
      title: "Utilization APY",
      value: data?.utilizationApy,
      icon: "bubble-race",
      isPercent: true,
    },
  ];

  return (
    <Grid container spacing={2}>
      {cards.map((card, i) => (
        <Grid key={`${card.title}${i.toString()}`} item xs={6} md={4} lg={3}>
          <ValueCard {...card} />
        </Grid>
      ))}
    </Grid>
  );
};

export default PoolDetailsCards;
