import { useMemo, useState } from "react";
import { Box, CustomSwitch, Grid } from "@frontend/ui";
import { ValueCard, ValueCardProps } from "@frontend/ui/value-card";
import { CreditManagerFragment, PoolData } from "../../../generated";

type Props = {
  data?: CreditManagerFragment | null;
  poolData?: PoolData;
};

const CreditManagerCards = ({ data, poolData }: Props) => {
  const [showValuesInToken, setShowValuesInToken] = useState(true);

  const cards: ValueCardProps[] = useMemo(
    () => [
      {
        title: "Borrow APY",
        value: data?.borrowApy,
        icon: "circular",
        isPercent: true,
      },
      {
        title: "Total Borrowed",
        value: showValuesInToken ? data?.totalBorrowed : data?.totalBorrowedUsd,
        icon: "borrow-icon",
        cryptoCurrency: showValuesInToken ? poolData?.assetSymbol : undefined,
        currency: showValuesInToken ? undefined : "USD",
      },
      {
        title: "Debt Limit",
        value: showValuesInToken ? data?.totalDebtLimit : data?.totalDebtLimitUsd,
        icon: "coin-insert",
        cryptoCurrency: showValuesInToken ? poolData?.assetSymbol : undefined,
        currency: showValuesInToken ? undefined : "USD",
      },
      {
        title: "Utilization",
        value: data ? data.totalBorrowed / data.totalDebtLimit || 0 : undefined,
        icon: "pie-slice",
        isPercent: true,
      },
      {
        title: "Total Repaid",
        value: showValuesInToken ? data?.totalRepaid : data?.totalRepaidUsd,
        icon: "coin-dollar",
        cryptoCurrency: showValuesInToken ? poolData?.assetSymbol : undefined,
        currency: showValuesInToken ? undefined : "USD",
      },
      {
        title: "Total Profit",
        value: showValuesInToken ? data?.totalProfit : data?.totalProfitUsd,
        icon: "coins-stack-icon",
        cryptoCurrency: showValuesInToken ? poolData?.assetSymbol : undefined,
        currency: showValuesInToken ? undefined : "USD",
      },
      {
        title: "Total Losses",
        value: showValuesInToken ? data?.totalLosses : data?.totalLossesUsd,
        icon: "coins-stack-icon",
        cryptoCurrency: showValuesInToken ? poolData?.assetSymbol : undefined,
        currency: showValuesInToken ? undefined : "USD",
      },
      {
        title: "Open Accounts",
        value: data?.openedAccountsCount,
        icon: "users",
      },
      {
        title: "Closed Accounts",
        value: data?.totalClosedAccounts,
        icon: "user_close",
      },
      {
        title: "Total Liquidated Accounts",
        value: data?.totalLiquidatedAccounts,
        icon: "user_close",
      },
    ],
    [data, poolData?.assetSymbol, showValuesInToken],
  );

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Box marginLeft="auto">
        <CustomSwitch
          checked={showValuesInToken}
          onChange={() => setShowValuesInToken((prev) => !prev)}
          checkedLabel={poolData?.assetSymbol || "Token"}
          uncheckedLabel="USD"
        />
      </Box>
      <Grid container spacing={2}>
        {cards.map((card, i) => (
          <Grid key={`${card.title}${i.toString()}`} item xs={6} md={4} lg={3}>
            <ValueCard {...card} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default CreditManagerCards;
