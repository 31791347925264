import { Box, CompositionOverTimeChart, CryptoIcon, Grid, Paper, Typography } from "@frontend/ui";
import { ShimmerLoader } from "@frontend/ui/shimmer-loader";
import { palette } from "@frontend/ui/theme/palette";
import { ReactNode, useMemo } from "react";
import { useClientConfig } from "src/pages/ccar-lending-page/clients-config";
import { PoolSortBy, SortOrder, usePoolsHistoryQuery, usePoolsQuery } from "src/pages/ccar-lending-page/generated";

export const poolsCompositionChartColors = [palette.blue.main, palette.aqua.main];

export const StablecoinPoolsComposition = () => {
  const config = useClientConfig();
  const asset = config.stableCoin?.asset || "";
  const { data: poolsData } = usePoolsQuery({
    variables: {
      asset,
      input: {
        sort: PoolSortBy.TotalLiquidity,
        order: SortOrder.Descending,
        limit: 4,
        skip: 0,
      },
    },
    skip: !asset.length,
  });
  const pools = useMemo(() => poolsData?.pools.items || [], [poolsData]);
  const { data: poolsHistory } = usePoolsHistoryQuery({
    variables: {
      addresses: pools.map((p) => p.address),
    },
    skip: !pools.length,
  });

  return (
    <Paper variant="card">
      <Typography variant="h2">Pools Compositions Over Time</Typography>
      <Typography mb={[2, 3]}>Showing the 4 largest pools in terms of total liquidity (TVL).</Typography>
      <Grid container spacing={[2, 3]}>
        {pools.map((pool) => {
          const poolHistory = poolsHistory?.poolsHistory.find((h) => h.address === pool.address);

          let content: ReactNode;
          if (pool && poolHistory) {
            content = (
              <Box data-testid="pool-liquidity-chart">
                <Box mb={[1, 2]} display="flex" alignItems="center" gap={[1, 2]}>
                  <Box sx={{ position: "relative" }}>
                    <CryptoIcon icon={pool.source} />
                  </Box>
                  <Typography variant="h4">{pool.tokens.map((t) => t.symbol).join(" / ")}</Typography>
                </Box>
                <CompositionOverTimeChart
                  series={pool.tokens.map((t, i) => ({
                    label: t.symbol,
                    data: poolHistory.history.map((h) => {
                      const tokenData = h.pool.tokens.find((s) => s.address === t.address);
                      const { balance, usdPrice } = tokenData || {};
                      const tokenBalanceUsd = balance ? Number(usdPrice) * Number(balance) : 0;
                      return [h.timestamp, tokenBalanceUsd] as [number, number];
                    }),
                    color: poolsCompositionChartColors[i],
                  }))}
                />
              </Box>
            );
          } else {
            content = <ShimmerLoader sx={{ minHeight: 380 }} />;
          }

          return (
            <Grid xs={12} md={6} item key={pool.address}>
              <Paper variant="widget">{content}</Paper>
            </Grid>
          );
        })}
      </Grid>
    </Paper>
  );
};
