import { Paper, CompositionOverTimeChart } from "@frontend/ui";
import { FeesDistributionHistoryFragment } from "src/pages/ccar-perpetuals-page/generated";

type FeesDistributionChartProps = {
  loading: boolean;
  data?: FeesDistributionHistoryFragment;
};

export const FeesDistributionChart = ({ loading, data }: FeesDistributionChartProps) => (
  <Paper variant="widget">
    <CompositionOverTimeChart
      isLoading={loading}
      title="Fees Distribution Over Time"
      hideTotal
      series={[
        {
          label: "Borrow Fees",
          type: "bar",
          data: data?.borrowFee.map((s) => [s.time, s.value]) || [],
        },
        {
          label: "Position Fees",
          type: "bar",
          data: data?.positionFee.map((s) => [s.time, s.value]) || [],
        },
        {
          label: "Price Impact",
          type: "bar",
          data: data?.priceImpact.map((s) => [s.time, s.value]) || [],
        },
      ]}
    />
  </Paper>
);
