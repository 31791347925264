import { FC } from "react";
import { useLocation, useParams } from "react-router-dom";
import { PageTracker } from "src/components/page-tracker";
import { Box } from "@frontend/ui";
import { CustomReactSelect, OptionType } from "@frontend/ui/custom-select";
import { Typography } from "@frontend/ui/typography";
import { PublicFooter } from "src/components/public-footer";
import { clientViewProps } from "src/pages/community-analytics-page/client-view-props";
import { SearchBar, SearchBarProps } from "@frontend/ui/search-bar";
import { CommunityDashboardHeader } from "./community-dashboard-header";
import TabsNavigation, { NavigationTabProps } from "./tabs-navigation";

type Props = {
  tabs?: NavigationTabProps[];
  searchBarProps?: SearchBarProps;
  showClientToolsDropdown?: boolean;
  homePath?: string;
  shareText?: string;
  shareHashtags?: string[];
  headerButtonsPrefix?: React.ReactElement;
  hideLoginButton?: boolean;
};

export const CommunityDashboardLayout: FC<Props> = PageTracker(
  ({
    tabs,
    searchBarProps,
    children,
    showClientToolsDropdown = true,
    homePath: homePathParam,
    shareText,
    shareHashtags,
    headerButtonsPrefix,
    hideLoginButton,
  }) => {
    const { pathname } = useLocation();
    const { clientName } = useParams<{ clientName: string }>();
    const clientsProps = clientViewProps();
    const currentTool = clientsProps?.tools?.find((tool) => pathname.includes(tool.link));
    const clientHomePath = (clientsProps?.homePath || clientName) ?? "";

    const homePath =
      homePathParam || `https://chaoslabs.xyz/customers/${clientHomePath}`;

    const clientToolsOptions: OptionType[] = (clientsProps?.tools || []).reduce<OptionType[]>((acc, tool) => {
      if (!tool.externalLink) {
        acc.push({
          label: tool.title,
          value: tool.link,
          link: tool.link,
        });
      }

      return acc;
    }, []);

    return (
      <Box minHeight="100vh" display="flex" flexDirection="column" alignItems="center">
        <Box minHeight="100vh" display="flex" flexDirection="column" alignItems="center" width="100%">
          <CommunityDashboardHeader
            homePath={homePath}
            shareText={shareText}
            shareHashtags={shareHashtags}
            buttonsPrefix={headerButtonsPrefix}
            hideLoginButton={hideLoginButton}
          />
          <Box
            px={{
              xs: 2,
              sm: 5,
              md: 10,
              lg: 15,
            }}
            bgcolor="#0C0D10"
            position="sticky"
            top={0}
            zIndex={10}
            width="100%"
          >
            <Box maxWidth={1320} margin="auto">
              {showClientToolsDropdown && (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  gap={1}
                  mt={{
                    xs: 1,
                    md: 4,
                  }}
                  mb={{
                    xs: 0,
                    md: 2,
                  }}
                >
                  {clientToolsOptions.length === 1 ? (
                    <Box>
                      <Typography variant="h1">{currentTool?.title}</Typography>
                    </Box>
                  ) : (
                    <CustomReactSelect
                      options={clientToolsOptions}
                      value={clientToolsOptions.find((opt) => opt.label === currentTool?.title)}
                      isSearchable={false}
                      variant="title"
                    />
                  )}
                  {searchBarProps && <SearchBar {...searchBarProps} />}
                </Box>
              )}
              {tabs && <TabsNavigation tabs={tabs} />}
            </Box>
          </Box>
          <Box
            flex={1}
            py={{
              xs: 3,
              md: 5,
            }}
            px={{
              xs: 2,
              sm: 5,
              md: 10,
              lg: 15,
            }}
            maxWidth={1560}
            margin="auto"
            width="100%"
          >
            {children}
          </Box>
        </Box>
        <PublicFooter />
      </Box>
    );
  },
);
