import { Box, Paper, CompositionOverTimeChart } from "@frontend/ui";
import { palette } from "@frontend/ui/theme/palette";
import { useAggOrdersData } from "./use-agg-order-historic-data";

type Props = {
  daysAgo: number;
};

const AggOrdersCharts = ({ daysAgo }: Props) => {
  const { isLoading, cumulatedVolume, cumulatedFees, totalVolume, makerFees, takerFees } = useAggOrdersData(daysAgo);

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      <Paper variant="widget">
        <CompositionOverTimeChart
          isLoading={isLoading}
          title="Trading Volume Over Time"
          description="Showing the trading volume in USD, per day and aggregated over time"
          hideTotal
          series={[
            {
              type: "line",
              label: "Cumulated",
              showArea: true,
              data: cumulatedVolume,
              z: 0,
            },
            {
              type: "bar",
              data: totalVolume,
              label: "Daily",
              stack: "a",
            },
          ]}
        />
      </Paper>
      <Paper variant="widget">
        <CompositionOverTimeChart
          isLoading={isLoading}
          title="Trading Fees Over Time"
          description="Showing the trading fees, in USD, per day and aggregated over time"
          hideTotal
          series={[
            {
              type: "line",
              label: "Cumulated",
              showArea: true,
              data: cumulatedFees,
              stack: "cumulated",
              z: 0,
              color: palette.green.main,
            },
            {
              type: "bar",
              data: makerFees,
              label: "Maker",
              stack: "fees",
            },
            {
              type: "bar",
              data: takerFees,
              label: "Taker",
              stack: "fees",
            },
          ]}
        />
      </Paper>
    </Box>
  );
};
export default AggOrdersCharts;
