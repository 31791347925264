import { FC } from "react";
import { Modal } from "../modal";
import { Paper } from "../paper";
import { IconButton } from "../icon-button";
import { CustomIcon } from "../custom-icon";
import { Typography } from "../typography";
import { Box } from "../box";

interface ModalWrapperProps {
  open: boolean;
  onClose?: () => void;
  maxWidth?: number;
  title?: string;
}

export const ModalWrapper: FC<ModalWrapperProps> = (props) => {
  const { children, open, onClose, maxWidth, title } = props;
  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        p: 5,
        display: "flex",
        "& > .MuiBox-root": {
          maxWidth,
        },
        ".modal": {
          display: "flex",
          alignItems: "center",
          width: "auto",
          margin: "auto",
        },
        ".modal-header, .modal-footer": {
          display: "flex",
          alignItems: "center",
          px: { xs: 2, md: 5 },
        },
        ".modal-body": {
          display: "flex",
          flexDirection: "column",
          rowGap: "40px",
          overflow: "auto",
          px: { xs: 2, md: 5 },
        },
        ".modal-footer": {
          justifyContent: "flex-end",
        },
      }}
    >
      <Paper
        variant="card"
        sx={{
          margin: "auto",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          maxHeight: "100%",
          flex: 1,
          maxWidth,
          width: "100%",
          overflow: "hidden",
          pt: 0,
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center" py={3}>
          <Typography variant="h2">{title}</Typography>
          <Box height={32} display="flex" alignItems={title ? "center" : "flex-start"}>
            <IconButton onClick={onClose} size="large">
              <CustomIcon icon="close" />
            </IconButton>
          </Box>
        </Box>
        {children}
      </Paper>
    </Modal>
  );
};
