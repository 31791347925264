import dayjs, { Dayjs } from "dayjs";
import { Box, Grid, NotificationBanner } from "@frontend/ui";
import { FC, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { RouteParams, RoutePath } from "src/config/routes";
import { PageHeader } from "@frontend/ui/page-header";
import { useDataFetchersQuery } from "src/hooks/useDataFetchersQuery";
import { Loader } from "@frontend/ui/loader";
import { formatAddressCompact, formatDate } from "@frontend/ui/utils/formatters";
import AssetsHealthWidget, {
  MarketHealthWithTotalSupplyAndBorrow,
} from "src/components/assets-health-widget/assets-health-widget";
import MultiValueCard from "src/components/multi-values-card/multi-values-card";
import { ValueCardProps } from "@frontend/ui/value-card";
import { palette } from "@frontend/ui/theme/palette";
import { WithUnifiedProductRedirect } from "src/utils/feature-flags";
import { Client } from "../../types";
import { useClientConfig } from "../../clients-config";
import { TimeMachineInput } from "./components/time-machine";
import { useWalletSupplyAndBorrow } from "./use-wallet-supply-borrow";
import { WalletEvent, normalizeWalletEvents } from "./wallet-events";
import EventsTable from "../../components/events-table/events-table";
import WalletPositionsTables from "./components/wallet-positions/wallet-positions-tables";
import { WalletHistory } from "./components/wallet-history";
import { useAllMarketsQuery, useWalletLabelQuery } from "../../generated";

type CryptoWalletInput = {
  walletAddress: string;
};

export type PositionDataType = {
  tokenSymbol: string;
  tokenName: string;
  amount: number;
};

type SupplyAndBorrowType = { totalSupply: number; totalBorrow: number };

const isToday = (date: Dayjs) => dayjs().startOf("day").isSame(date.startOf("day"));

const WalletDetailsInner: FC = () => {
  const { clientName, address } = useParams<{ clientName: Client; address: string }>();
  const { endpointsPrefix, walletDetails, chains, showChainMarketsBreakdown } = useClientConfig();
  const [timeMachineDate, setTimeMachineDate] = useState<Dayjs>(dayjs());
  const isTimeMachineEnabled = !isToday(timeMachineDate);
  const { isLoading: isLoadingSupplyAndBorrow, supplyAndBorrow } = useWalletSupplyAndBorrow(address!, timeMachineDate);
  const { data: walletLabel } = useWalletLabelQuery({ variables: { address: address! } });
  const { loading: isLoadingAllMarkets, data: allMarkets } = useAllMarketsQuery();
  const showWalletHistory = walletDetails?.charts?.includes("wallet-history") && !isTimeMachineEnabled;

  const input = useMemo(
    () => ({
      walletAddress: address!,
      endDate: isTimeMachineEnabled ? timeMachineDate.toDate().getTime() : undefined,
    }),
    [address, isTimeMachineEnabled, timeMachineDate],
  );

  const breadcrumbsLinks = [
    {
      title: "Wallets",
      href: RoutePath.Risk.Wallets.replace(RouteParams.ClientName, clientName!),
    },
  ];

  const tokenData = useMemo(() => supplyAndBorrow?.tokenData || [], [supplyAndBorrow]);

  const marketHealth = useMemo(() => supplyAndBorrow?.marketHealth || [], [supplyAndBorrow]);

  const walletTotalSupplyAndBorrowSummary = tokenData?.reduce<SupplyAndBorrowType>(
    (result, val) => {
      const totalSupply: number = result?.totalSupply ? result.totalSupply : 0;
      const totalBorrow: number = result?.totalBorrow ? result.totalBorrow : 0;

      const updatedResult = {
        totalSupply: totalSupply + val.supplyAmountInUsd + val.collateralAmountInUsd,
        totalBorrow: totalBorrow + val.borrowAmountInUsd,
      };

      return updatedResult;
    },
    { totalSupply: 0, totalBorrow: 0 },
  );

  const marketHealthWithTotalSupplyAndBorrow = marketHealth?.reduce<MarketHealthWithTotalSupplyAndBorrow[]>(
    (result, val) => {
      const { market } = val;
      const marketTokenData = tokenData.filter((t) => market.id === t.market.id);
      result.push({
        market: val.market,
        healthFactor: val.healthFactor || 0,
        totalCollateral: marketTokenData.length
          ? marketTokenData.reduce((acc, t) => acc + t.collateralAmountInUsd, 0)
          : undefined,
        totalSupply: marketTokenData.length
          ? marketTokenData.reduce((acc, t) => acc + t.supplyAmountInUsd, 0)
          : undefined,
        totalBorrow: marketTokenData.length
          ? marketTokenData.reduce((acc, t) => acc + t.borrowAmountInUsd, 0)
          : undefined,
      });

      return result;
    },
    [],
  );

  const { response: walletEvents, isLoading: isLoadingWalletEvents } = useDataFetchersQuery<
    { data: Array<WalletEvent> },
    CryptoWalletInput
  >(
    `${endpointsPrefix}.wallet_events`,
    "offchain",
    isTimeMachineEnabled && walletDetails?.tables.includes("recentEvents") ? input : undefined,
    true,
  );

  const multiCardValues: ValueCardProps[] = [
    {
      title: "Total Supply",
      value:
        walletTotalSupplyAndBorrowSummary?.totalSupply !== undefined
          ? walletTotalSupplyAndBorrowSummary?.totalSupply
          : "N/A",
      currency: "USD",
    },
    {
      title: "Total Borrow",
      value:
        walletTotalSupplyAndBorrowSummary?.totalBorrow !== undefined
          ? walletTotalSupplyAndBorrowSummary?.totalBorrow
          : "N/A",
      currency: "USD",
    },
  ];

  const isLoading = isLoadingWalletEvents || isLoadingSupplyAndBorrow || isLoadingAllMarkets;

  const walletMarkets = allMarkets?.allMarkets.filter((m) => tokenData.find((t) => t.market.id === m.id));

  return (
    <Box
      sx={{
        display: "flex",
        gap: "24px",
        flexDirection: "column",
      }}
    >
      <Box display="flex" flexDirection="row">
        <PageHeader
          breadcrumbTitle={formatAddressCompact(address!)}
          pageTitle={walletLabel?.walletLabel || "Wallet"}
          breadcrumbsLinks={breadcrumbsLinks}
          containerStyle={{ mt: 0, mb: 0 }}
          extraData={{
            text: "",
            link: {
              text: `${address!}`,
              href: `https://debank.com/profile/${address!}`,
              isExternal: true,
            },
            allowCopy: true,
          }}
          suffixComponent={
            walletDetails?.hideTimeMachine ? undefined : (
              <TimeMachineInput
                value={timeMachineDate}
                onChange={async (date: Dayjs) => setTimeMachineDate(date)}
                disabled={isLoading}
              />
            )
          }
        />
      </Box>
      {isLoading ? (
        <Loader />
      ) : (
        <Box display="flex" flexDirection="column" gap={3}>
          {isTimeMachineEnabled && (
            <NotificationBanner
              message={`Time Machine mode, viewing account status as of ${formatDate(timeMachineDate.toDate())}`}
              icon="clock"
              bgcolor={palette.blue.opacity50}
            />
          )}
          {marketHealthWithTotalSupplyAndBorrow && !!marketHealthWithTotalSupplyAndBorrow.length && (
            <Grid
              container
              sx={{ flexDirection: { xs: "column-reverse", md: "row" } }}
              justifyContent="space-between"
              spacing={3}
            >
              <Grid item xs={12} md={8}>
                <AssetsHealthWidget
                  markets={allMarkets?.allMarkets}
                  marketHealth={marketHealthWithTotalSupplyAndBorrow}
                  eModeCategories={supplyAndBorrow?.eModeCategories ? supplyAndBorrow?.eModeCategories : undefined}
                  marketLiquidationBands={supplyAndBorrow?.marketLiquidationBands ?? undefined}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <MultiValueCard values={multiCardValues} title="Balances" sx={{ height: "100%" }} />
              </Grid>
            </Grid>
          )}
          {showWalletHistory && !!walletMarkets?.length && <WalletHistory markets={walletMarkets} />}
          {supplyAndBorrow && allMarkets && (
            <WalletPositionsTables data={tokenData} metadata={address} markets={allMarkets.allMarkets} />
          )}
          {walletDetails?.tables.includes("recentEvents") && (
            <Box>
              <EventsTable
                events={isTimeMachineEnabled ? normalizeWalletEvents(walletEvents?.data || [], address!) : undefined}
                eventsQuery={isTimeMachineEnabled ? undefined : { walletAddress: address }}
                metadata={address}
              />
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

const WalletDetails = () => (
  <WithUnifiedProductRedirect>
    <WalletDetailsInner />
  </WithUnifiedProductRedirect>
);

export default WalletDetails;
