import { echartsTooltipFormatter } from "@frontend/ui";
import { formatAmount, formatCurrency } from "@frontend/ui/utils/formatters";
import { EChartsOption } from "echarts";
import { useStablecoinDataContext } from "../../provider";
import { formatPriceChangeLiquidationResponse } from "../../utils/format-price-change-liquidation-response";

export const usePriceChangeLiquidationOption = (): EChartsOption | null => {
  const { priceChangeLiquidation, tokenSymbol, isLoading } = useStablecoinDataContext();

  if (isLoading || !priceChangeLiquidation) {
    return null;
  }

  const formatted = formatPriceChangeLiquidationResponse(priceChangeLiquidation);

  return {
    tooltip: {
      trigger: "axis",
      name: `${tokenSymbol} at risk of liquidation`,
      formatter: echartsTooltipFormatter({
        headerFormatter: () => `${tokenSymbol} at risk of liquidation`,
        valueFormatter: (v) => formatCurrency(Number(v)),
        currency: "USD",
        showXAxisInline: true,
      }),
    },
    grid: {
      right: 20,
      bottom: 35,
      top: 50,
      left: 10,
    },
    legend: {
      data: formatted.map((datum) => `${datum.title} Price`),
    },
    toolbox: {
      feature: {
        saveAsImage: {
          show: false,
        },
      },
    },
    xAxis: {
      name: "Collateral Asset Price Change",
      nameLocation: "middle",
      nameGap: 55,
      boundaryGap: false,
      type: "category",
      data: formatted[0]?.data.map(({ x }) => x),
    },
    yAxis: {
      type: "value",
      axisLabel: {
        formatter: (v: number) => formatAmount(v, { currency: "USD", notation: "compact" }),
      },
    },
    series: formatted.map((datum) => ({
      name: `${datum.title} Price`,
      type: "line",
      symbol: "none",
      data: datum.data.map((d) => d.y),
    })),
  };
};
