import { FC, useCallback, useEffect, useRef, useState } from "react";
import { Box } from "../box";
import { IconButton } from "../icon-button";
import { InputLabel } from "../input-label";
import { NumberInput } from "../number-input";
import { CustomReactSelect } from "../custom-select";
import { Button } from "../button";
import { Popover } from "../popover";
import { Typography } from "../typography";
import { CustomIcon } from "../custom-icon";
import { CryptoIcon } from "../crypto-icon";
import { formatAmount } from "../utils/formatters";
import { AdvancedSettingsAsset, AdvancedSettingsChain, AdvancedSettingsPopover } from "./advanced-settings-popover";

export type Asset = { name: string; symbol: string; mappedSymbol: string; price: number };

type Props = {
  assets: Record<string, Asset[]>;
  onApply: (
    query: {
      chains: string[];
      tokenSymbol: string;
      priceChangePercent: number;
      liquidationThreshold?: number;
    }[],
  ) => void;
  isMultichain?: boolean;
  stableCoinSymbol?: string;
  subtitle?: string;
  showAdvancedSettings?: boolean;
  liquidationThresholdLabel?: string;
};

const directionsOptions = [
  { label: "Up", value: "Up" },
  { label: "Down", value: "Down" },
];

export const AssetsPriceChange: FC<Props> = ({
  assets,
  onApply,
  isMultichain,
  stableCoinSymbol,
  subtitle,
  showAdvancedSettings,
  liquidationThresholdLabel,
}) => {
  const chains = Object.keys(assets);
  const getAssetOptions = (chain: string) =>
    (showAdvancedSettings
      ? Object.values(assets)
          .flat()
          .reduce<Asset[]>(
            (acc, asset) => (acc.find((a) => a.mappedSymbol === asset.mappedSymbol) ? acc : [...acc, asset]),
            [],
          )
      : assets[chain]
    )
      .filter((a) => a?.symbol.toLowerCase() !== stableCoinSymbol?.toLowerCase())
      .map((a) => ({
        value: a.mappedSymbol,
        label: a.name,
        cryptoIcon: a.mappedSymbol,
      }));
  const getAdvancedSettingsAsset = useCallback(
    (asset: Asset): AdvancedSettingsAsset => ({
      name: asset.mappedSymbol,
      icon: asset.mappedSymbol.toLowerCase(),
      chains: Object.entries(assets).reduce<AdvancedSettingsChain[]>(
        (acc, [chain, chainAssets]) =>
          !acc.find((c) => c.name === chain) && chainAssets.find((a) => a.mappedSymbol === asset.mappedSymbol)
            ? [...acc, { name: chain, icon: chain.toLowerCase(), isSelected: true }]
            : acc,
        [],
      ),
    }),
    [assets],
  );
  const [selectedChains, setSelectedChains] = useState<string[]>([chains[0]]);
  const [selectedAssets, setSelectedAssets] = useState([getAssetOptions(chains[0])[0]]);
  const [isPricesUp, setIsPricesUp] = useState([false]);
  const [priceChanges, setPriceChanges] = useState([0]);
  const [isAdvancedSettingsOpen, setIsAdvancedSettingsOpen] = useState(false);
  const [advancedSettingsAssets, setAdvancedSettingsAssets] = useState<AdvancedSettingsAsset[]>(
    selectedAssets.map((sa) => {
      const asset = Object.values(assets)
        .flat()
        .find((a) => a.mappedSymbol === sa.value)!;

      return getAdvancedSettingsAsset(asset);
    }),
  );
  const settingsButtonRef = useRef<null | HTMLButtonElement>(null);

  useEffect(
    () =>
      setAdvancedSettingsAssets(
        selectedAssets.map((sa) => {
          const asset = Object.values(assets)
            .flat()
            .find((a) => a.mappedSymbol === sa.value)!;

          return getAdvancedSettingsAsset(asset);
        }),
      ),
    [selectedAssets, selectedChains, assets, getAdvancedSettingsAsset],
  );

  const getPrice = (i: number) =>
    Object.values(assets)
      .flat()
      .find((a) => a.mappedSymbol === selectedAssets[i].value)?.price;
  const getNewPrice = (i: number) => {
    const price = getPrice(i);

    if (!price) {
      return undefined;
    }

    return isPricesUp[i] ? price + price * priceChanges[i] * 0.01 : price * Math.max(1 - priceChanges[i] * 0.01, 0);
  };
  const isMissingPriceForSelected = selectedAssets.some((_, i) => getNewPrice(i) === undefined);

  return (
    <>
      <Box display="flex" gap={2} paddingBottom={3}>
        <Box flexGrow={1}>
          <Typography variant="h2">Risk Explorer</Typography>
          {subtitle && <Typography variant="h6">{subtitle}</Typography>}
        </Box>
        {showAdvancedSettings && (
          <>
            <Button
              ref={settingsButtonRef}
              color="secondary"
              onClick={() => setIsAdvancedSettingsOpen(!isAdvancedSettingsOpen)}
              sx={{ display: "flex", gap: 2, flexShrink: 0 }}
            >
              Advanced
              <CustomIcon icon="settings-adjust" />
            </Button>
            <Popover
              open={isAdvancedSettingsOpen}
              onClose={() => setIsAdvancedSettingsOpen(false)}
              anchorEl={settingsButtonRef.current}
            >
              <AdvancedSettingsPopover
                onClose={() => setIsAdvancedSettingsOpen(false)}
                assets={advancedSettingsAssets}
                onChange={(v) => setAdvancedSettingsAssets(v)}
                liquidationThresholdLabel={liquidationThresholdLabel}
              />
            </Popover>
          </>
        )}
        <Button
          color="primary"
          disabled={isMissingPriceForSelected}
          onClick={() =>
            onApply(
              advancedSettingsAssets.map((a, j) => ({
                chains: a.chains.filter((c) => c.isSelected).map((c) => c.name),
                liquidationThreshold: a.liquidationThreshold,
                tokenSymbol: a.name,
                priceChangePercent: isPricesUp[j] ? priceChanges[j] : -priceChanges[j],
              })),
            )
          }
        >
          Apply
        </Button>
      </Box>
      <Box display="flex" gap={2} flexDirection="column">
        {selectedChains.map((_, i) => {
          const isFirstRow = i === 0;
          const canAddMore = selectedChains.length <= 3;
          return (
            <Box key={i} gap={3} flexGrow="1" display="flex" flexWrap="wrap" alignItems="flex-end">
              {isMultichain && !showAdvancedSettings && (
                <Box flex="1">
                  {isFirstRow && <InputLabel>Chain</InputLabel>}
                  <CustomReactSelect
                    options={chains.map((c) => ({
                      value: c,
                      label: c,
                      cryptoIcon: c.toLowerCase(),
                    }))}
                    value={{
                      value: selectedChains[i],
                      label: selectedChains[i],
                      cryptoIcon: selectedChains[i].toLowerCase(),
                    }}
                    onChange={(selected) => {
                      if (selected) {
                        setSelectedChains((sc) => sc.map((c, index) => (index === i ? selected.value : c)));
                        setSelectedAssets((sa) =>
                          sa.map((c, index) => (index === i ? getAssetOptions(selected.value)[0] : c)),
                        );
                      }
                    }}
                  />
                </Box>
              )}
              <Box key={i} flex="1" data-testid="asset-select">
                {isFirstRow && <InputLabel>Asset</InputLabel>}
                <CustomReactSelect
                  options={getAssetOptions(selectedChains[i])}
                  value={selectedAssets[i]}
                  onChange={(selected) => {
                    if (selected) {
                      setSelectedAssets((sa) =>
                        sa.map((a, index) =>
                          index === i
                            ? (selected as {
                                value: string;
                                label: string;
                                cryptoIcon: string;
                              })
                            : a,
                        ),
                      );
                    }
                  }}
                />
              </Box>
              <Box flex="1">
                {isFirstRow && <InputLabel>Price Change Direction</InputLabel>}
                <CustomReactSelect
                  options={directionsOptions}
                  value={{
                    value: isPricesUp[i] ? "Up" : "Down",
                    label: isPricesUp[i] ? "Up" : "Down",
                  }}
                  onChange={(selected) => {
                    if (selected) {
                      setIsPricesUp((ipus) => ipus.map((ipu, index) => (index === i ? selected.value === "Up" : ipu)));
                    }
                  }}
                  isSearchable={false}
                />
              </Box>
              <Box flex="1">
                {isFirstRow && <InputLabel>Price Change</InputLabel>}
                <NumberInput
                  value={priceChanges[i]}
                  isPercent
                  onChange={async (v) => {
                    setPriceChanges((pcs) => pcs.map((pc, index) => (index === i ? v : pc)));
                  }}
                />
              </Box>
              <Box flex="1">
                {isFirstRow && <InputLabel>New Price</InputLabel>}
                <Box borderRadius={2} bgcolor="inputs.main" py={1.5} px={2} flexGrow={1} display="flex" gap={1}>
                  <CryptoIcon
                    icon={
                      getAssetOptions(selectedChains[i]).find((a) => a.value === selectedAssets[i].value)?.cryptoIcon ||
                      ""
                    }
                    sx={{ img: { width: 20, height: 20 } }}
                  />
                  <Typography variant="caption">=</Typography>
                  {formatAmount(getNewPrice(i)!, { notation: "standard", currency: "USD" })}
                </Box>
              </Box>
              {i === 0 ? (
                <IconButton
                  color="primary"
                  disabled={!canAddMore}
                  onClick={() => {
                    setSelectedChains((sc) => [...sc, chains[0]]);
                    setSelectedAssets((sa) => [...sa, getAssetOptions(chains[0])[0]]);
                    setIsPricesUp((ipus) => [...ipus, false]);
                    setPriceChanges((pcs) => [...pcs, 0]);
                  }}
                >
                  <CustomIcon icon="plus" />
                </IconButton>
              ) : (
                <IconButton
                  color="secondary"
                  type="button"
                  onClick={() => {
                    setSelectedChains((sc) => sc.filter((__, index) => index !== i));
                    setSelectedAssets((sa) => sa.filter((__, index) => index !== i));
                    setIsPricesUp((ipu) => ipu.filter((__, index) => index !== i));
                    setPriceChanges((pc) => pc.filter((__, index) => index !== i));
                  }}
                >
                  <CustomIcon icon="trash-can" />
                </IconButton>
              )}
            </Box>
          );
        })}
      </Box>
    </>
  );
};
