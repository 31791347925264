import { Box, CustomSwitch, Paper, StackedBarChart, Typography } from "@frontend/ui";
import { StringChartSeries } from "@frontend/ui/echarts/types";
import { formatAddressCompact, formatAmount, formatStringCompact } from "@frontend/ui/utils/formatters";
import { ReactNode, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { RouteParams } from "src/config/route-params";
import { RoutePath } from "src/config/routes";
import {
  WalletWithTokensFragment,
  useTopWalletsQuery,
  Chain,
  TopWalletsSortBy,
  Token,
} from "src/pages/ccar-lending-page/generated";
import { Client } from "src/pages/ccar-lending-page/types";
import { useClientConfig } from "../../clients-config";
import { getHealthText } from "../../utils/health-utils";

type Props = {
  chain?: Chain;
  tokenSymbol?: string;
  eModeCategoryId?: number;
  marketId?: string;
  marketName?: string;
  hideTopBorrowers?: boolean;
  hideTopSuppliers?: boolean;
};

type SideAmountKey = keyof Pick<Token, "borrowAmountInUsd" | "supplyAmountInUsd" | "collateralAmountInUsd">;

type Side = {
  amountKey: SideAmountKey;
  multiplier: 1 | -1;
  walletTokenDatapoints: [string, number][];
};

interface HasBorrowProps {
  wallets: WalletWithTokensFragment[];
  tokenSymbol: string;
}

const getHasBorrowForToken = ({ wallets, tokenSymbol }: HasBorrowProps) =>
  wallets.some((w) => w.tokens.find((t) => t.assetSymbol === tokenSymbol && t.borrowAmountInUsd > 0));

const getHasSupplyForToken = ({ wallets, tokenSymbol }: HasBorrowProps) =>
  wallets.some((w) =>
    w.tokens.find((t) => t.assetSymbol === tokenSymbol && (t.supplyAmountInUsd > 0 || t.collateralAmountInUsd > 0)),
  );

export const TopWallets = ({
  chain,
  tokenSymbol = "",
  eModeCategoryId,
  marketId,
  marketName,
  hideTopBorrowers = false,
  hideTopSuppliers = false,
}: Props) => {
  const { clientName, asset = tokenSymbol } = useParams<{ clientName: Client; asset: string }>();
  const { chains, isHealthPercent } = useClientConfig();
  const [sort, setSort] = useState(hideTopBorrowers ? TopWalletsSortBy.Supply : TopWalletsSortBy.Borrow);

  const formattedWalletHealth = (wallet: WalletWithTokensFragment) => {
    const singleMarket = marketId || chain || (chains.length === 1 && chains[0]);
    const singleMarketHealthFactor = singleMarket
      ? wallet.marketHealth?.find((mh) => mh.market.id === singleMarket)?.healthFactor
      : undefined;
    // eslint-disable-next-line no-nested-ternary
    const health = singleMarket
      ? singleMarketHealthFactor !== undefined
        ? singleMarketHealthFactor
        : wallet.health
      : undefined;
    if (health !== undefined && health !== null) {
      return getHealthText(health, isHealthPercent);
    }
    return null;
  };

  const formatLabel = (wallet: WalletWithTokensFragment) => {
    const health = formattedWalletHealth(wallet);
    const walletName = wallet.addressLabel
      ? formatStringCompact(wallet.addressLabel, 20)
      : formatAddressCompact(wallet.address);
    return `${walletName}${health ? `\n(${health})` : ""}`;
  };

  const marketTitle: string | ReactNode = marketName ? <> on the {marketName} market</> : "";

  const { loading: loadingSuppliers, data: suppliersData } = useTopWalletsQuery({
    variables: {
      shouldFetchAssetBalance: false,
      input: {
        chain: chain || null,
        limit: 10,
        sort: TopWalletsSortBy.Supply,
        tokenSymbol: tokenSymbol || null,
        eModeCategoryId: eModeCategoryId || null,
        marketId: marketId || null,
      },
    },
  });

  const { loading: loadingBorrowers, data: borrowersData } = useTopWalletsQuery({
    variables: {
      shouldFetchAssetBalance: false,
      input: {
        chain: chain || null,
        limit: 10,
        sort: TopWalletsSortBy.Borrow,
        tokenSymbol: tokenSymbol || null,
        eModeCategoryId: eModeCategoryId || null,
        marketId: marketId || null,
      },
    },
  });

  const loading = loadingSuppliers && loadingBorrowers;

  let showToggle = !loading && !hideTopBorrowers && !hideTopSuppliers;
  let hasBorrowForToken = false;
  let hasSupplyForToken = false;
  if (tokenSymbol && borrowersData && suppliersData) {
    hasBorrowForToken = getHasBorrowForToken({ wallets: borrowersData.topWallets, tokenSymbol });
    hasSupplyForToken = getHasSupplyForToken({ wallets: suppliersData.topWallets, tokenSymbol });
    showToggle = hasBorrowForToken && hasSupplyForToken;
  }

  useEffect(() => {
    if (tokenSymbol) {
      setSort(hasBorrowForToken && !hideTopBorrowers ? TopWalletsSortBy.Borrow : TopWalletsSortBy.Supply);
    }
  }, [
    showToggle,
    hasBorrowForToken,
    hasSupplyForToken,
    chain,
    tokenSymbol,
    marketId,
    hideTopBorrowers,
    hideTopSuppliers,
  ]);
  const data = sort === TopWalletsSortBy.Borrow ? borrowersData : suppliersData;

  const stackedBarChartData: Record<string, StringChartSeries> = data
    ? data.topWallets.reduce((map: Record<string, StringChartSeries>, obj: WalletWithTokensFragment) => {
        obj.tokens.forEach((token) => {
          if (marketId && token.marketId !== marketId) {
            return;
          }

          const chartSides: Side[] = [
            {
              amountKey: "borrowAmountInUsd",
              multiplier: -1,
              walletTokenDatapoints: [],
            },
            {
              amountKey: "supplyAmountInUsd",
              multiplier: 1,
              walletTokenDatapoints: [],
            },
            {
              amountKey: "collateralAmountInUsd",
              multiplier: 1,
              walletTokenDatapoints: [],
            },
          ];

          chartSides.forEach(({ amountKey, walletTokenDatapoints, multiplier }) => {
            const groupKey = `${token.assetSymbol}-${amountKey}`;
            if (token[amountKey] > 1) {
              const fromMap = map[groupKey];
              const tokenAmountInWallet = fromMap?.data?.find((d) => d[0] === obj.address);
              const amount = token[amountKey] * multiplier;
              if (tokenAmountInWallet) {
                tokenAmountInWallet[1] += amount;
              } else {
                walletTokenDatapoints.push([obj.address, amount]);

                if (Object.keys(map).includes(groupKey)) {
                  map[groupKey].data.push(...walletTokenDatapoints);
                } else if (walletTokenDatapoints.length > 0) {
                  const newTokenSerie: StringChartSeries = {
                    id: groupKey,
                    label: token.assetSymbol,
                    data: walletTokenDatapoints,
                  };
                  // eslint-disable-next-line no-param-reassign
                  map[groupKey] = newTokenSerie;
                }
              }
            }
          });
        });

        return map;
      }, {})
    : {};
  const series = Object.values(stackedBarChartData);
  const sortedStacks = data?.topWallets.map((w) => w.address) || [];

  return (
    <Paper
      variant="widget"
      sx={{
        display: "flex",
        flexDirection: "column",
        columnSpan: 2,
        gap: 3,
      }}
    >
      <StackedBarChart
        key={`${chain || ""}-${marketId || ""}-${tokenSymbol}-${eModeCategoryId || ""}-top-wallets-chart`}
        isLoading={loading}
        title={`Top ${sort === TopWalletsSortBy.Borrow ? "Borrowers" : "Suppliers"}`}
        headerSuffix={
          showToggle ? (
            <CustomSwitch
              onChange={() =>
                setSort(sort === TopWalletsSortBy.Supply ? TopWalletsSortBy.Borrow : TopWalletsSortBy.Supply)
              }
              checked={sort === TopWalletsSortBy.Supply}
              checkedLabel="Top Suppliers"
              uncheckedLabel="Top Borrowers"
            />
          ) : undefined
        }
        description={
          <span style={{ display: "block" }}>
            Showing supply and borrow asset breakdown of accounts with largest
            {` ${asset} `}
            {sort === TopWalletsSortBy.Borrow ? "borrow" : "supply"} balance
            {marketTitle}.
          </span>
        }
        emptyState={!loading && data?.topWallets.length === 0}
        series={series}
        onClick={(address) => {
          if (address) {
            window.open(
              RoutePath.Risk.WalletDetails.replace(RouteParams.ClientName, clientName!).replace(
                RouteParams.Address,
                address.toString(),
              ),
              "_blank",
            );
          }
        }}
        tooltipHeaderFormatter={(v) => {
          const wallet = data?.topWallets.find((w) => w.address === v);
          const walletHealth = wallet ? formattedWalletHealth(wallet) : null;
          const total = sort === TopWalletsSortBy.Borrow ? wallet?.totalBorrowsUsd : wallet?.totalLiquidityUsd;
          if (wallet) {
            return (
              <Box mb={0.5}>
                <Typography fontSize={12}>
                  {wallet.addressLabel
                    ? formatStringCompact(wallet.addressLabel, 20)
                    : formatAddressCompact(wallet.address)}
                </Typography>
                {total && (
                  <Typography fontSize={12}>
                    <Box component="span" color="#9B9DA1">
                      {sort === TopWalletsSortBy.Borrow ? "Total Borrow" : "Total Supply"}
                    </Box>
                    : {formatAmount(total, { currency: "USD", notation: "compact" })}
                  </Typography>
                )}
                {walletHealth && (
                  <Typography fontSize={12} mb={0.5}>
                    <Box component="span" color="#9B9DA1">
                      Wallet Health:
                    </Box>{" "}
                    {walletHealth}
                  </Typography>
                )}
              </Box>
            );
          }
          return String(v);
        }}
        option={{
          xAxis: {
            data: sortedStacks,
            axisLabel: {
              fontSize: 10,
              formatter: (v: string | number) => {
                const wallet = data?.topWallets.find((w) => w.address === v);
                if (wallet) {
                  return formatLabel(wallet);
                }
                return String(v);
              },
            },
          },
        }}
      />
    </Paper>
  );
};
