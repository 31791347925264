import { ValueCard } from "@frontend/ui";

type Props = {
  totalDebtUsd: number;
  debtCeilingUsd: number;
};

const IsolationModeBar = ({ totalDebtUsd, debtCeilingUsd }: Props) => (
  <ValueCard
    {...{
      title: "Isolated Debt Ceiling",
      tooltip:
        "Debt ceiling limits the amount possible to borrow against this asset by protocol users. Debt ceiling is specific to assets in isolation mode and is denoted in USD.",
      value: totalDebtUsd,
      currency: "USD",
      icon: "",
      progress: {
        maxValue: debtCeilingUsd,
        displayMaxValue: true,
      },
    }}
  />
);

export default IsolationModeBar;
