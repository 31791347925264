import { CompositionOverTimeChart, Paper } from "@frontend/ui";
import { TimeSpan } from "@frontend/ui/echarts/types";
import { palette } from "@frontend/ui/theme/palette";
import { OverviewChartsStats } from "../types";

type Props = {
  data?: OverviewChartsStats["historyStatsTvl"];
  isLoading?: boolean;
  timeSpan?: TimeSpan;
};

const TvlOverTimeChart = ({ data, isLoading, timeSpan }: Props) => (
  <Paper variant="widget">
    <CompositionOverTimeChart
      isLoading={isLoading}
      series={[
        {
          label: "TVL",
          data: (data || []).map((c) => [c.x, c.y]),
          color: palette.aqua.main,
        },
      ]}
      title="Total Value Locked"
      percentToggle
      showLegend={false}
      timeSpan={timeSpan}
    />
  </Paper>
);

export default TvlOverTimeChart;
