import { Box, Paper } from "@frontend/ui";
import { useParams } from "react-router-dom";
import { Loader } from "@frontend/ui/loader";
import { LiquidationBands } from "src/pages/ccar-lending-page/components/liquidation-bands";
import { ExchangeEventsTable, InfoCards } from "./components";
import { useMarketsContext } from "../../context/markets-context";

const MarketAmm = () => {
  const { marketId } = useParams();
  const { markets } = useMarketsContext();

  const market = markets.find((m) => m.id === marketId);

  if (!market) return <Loader />;

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      {market.amm && <InfoCards marketAmm={market.amm} />}
      <Paper variant="card">
        <LiquidationBands
          marketId={marketId!}
          borrowAsset={market.borrowableAssets[0]}
          collateralAsset={market.collateralableAssets.find((asset) => !market.borrowableAssets.includes(asset))}
        />
      </Paper>
      <ExchangeEventsTable marketId={marketId} market={market?.name} />
    </Box>
  );
};

export default MarketAmm;
