import { Box } from "../../../box";
import ECharts from "../../echarts";
import { Typography } from "../../../typography";
import { ShimmerLoader } from "../../../shimmer-loader";
import { getPriceChangeLiquidationOption } from "./get-asset-price-liquidation-chart-option";
import { PriceData } from "./types";

type Props = {
  title: string;
  description?: string;
  chartHeight?: number;
  emptyState?: boolean;
  isLoading?: boolean;
  data: PriceData;
};

export const AssetPriceLiquidationChart = ({
  title,
  description,
  chartHeight = 280,
  emptyState,
  isLoading,
  data = [],
}: Props) => {
  const option = getPriceChangeLiquidationOption(data);

  return (
    <Box>
      <Box display="flex" alignItems="flex-start" justifyContent="space-between" mb={2}>
        <Box>
          <Typography variant="h4">{title}</Typography>
          {description && <Typography>{description}</Typography>}
        </Box>
      </Box>
      <Box height={chartHeight}>
        {isLoading ? <ShimmerLoader sx={{ borderRadius: 1 }} /> : <ECharts emptyState={emptyState} option={option} />}
      </Box>
    </Box>
  );
};
