import { ColorPartial } from "@mui/material/styles/createPalette";

// Extending ColorPartial to avoid naming conflicts with `grey` color
export interface ChaosPaletteColor extends ColorPartial {
  main: string;
  hover: string;
  active: string;
  opacity80?: string;
  opacity50?: string;
  opacity20?: string;
  opacity15?: string;
  opacity10?: string;
}

export type ChaosPalette = {
  blue: ChaosPaletteColor;
  white: ChaosPaletteColor;
  almostWhite: ChaosPaletteColor;
  lightGrey: ChaosPaletteColor;
  grey: ChaosPaletteColor;
  darkGrey: ChaosPaletteColor;
  black: ChaosPaletteColor;
  button: ChaosPaletteColor;
  inputs: ChaosPaletteColor;
  red: ChaosPaletteColor;
  green: ChaosPaletteColor;
  turqouise: ChaosPaletteColor;
  aqua: ChaosPaletteColor;
  orange: ChaosPaletteColor;
  pink: ChaosPaletteColor;
  violette: ChaosPaletteColor;
  paleTurquoise: ChaosPaletteColor;
};

// From Design System https://www.figma.com/file/iid8AJByT3DwRlR1nfNEIU/New-Design-System?node-id=11115%3A43102&t=cnnBdOOdjD3espPy-4s
export const palette: ChaosPalette = {
  blue: {
    main: "#0366C1",
    hover: "#0758A0",
    active: "#0C4880",
    opacity80: "#0366C1CC",
    opacity50: "#0366C180",
    opacity20: "#0366C133",
    opacity15: "#0366C126",
    opacity10: "#0366C11A",
  },
  white: {
    main: "#E5E9EB",
    hover: "#BCC0C2",
    active: "#939799",
    opacity80: "#E5E9EBCC",
    opacity50: "rgba(229, 233, 235, 0.5)",
  },
  almostWhite: {
    main: "#8694A1",
    hover: "#727B80",
    active: "#5C6368",
    opacity20: "#8694A133",
    opacity50: "#8694A180",
    opacity10: "#8694A11A",
  },
  lightGrey: {
    main: "#454F58",
    hover: "#3C454C",
    active: "#333A41",
  },
  grey: {
    main: "#32393F",
    hover: "#2D3438",
    active: "#282D32",
  },
  darkGrey: {
    main: "#252A2F",
    hover: "#23282C",
    active: "#202428",
  },
  black: {
    main: "#181C1F",
    hover: "#222729",
    active: "#2D3033",
  },
  button: {
    main: "rgba(134, 148, 161, 0.2)",
    hover: "rgba(134, 148, 161, 0.3)",
    active: "rgba(134, 148, 161, 0.5)",
  },
  inputs: {
    main: "rgba(134, 148, 161, 0.1)",
    hover: "rgba(134, 148, 161, 0.2)",
    active: "rgba(134, 148, 161, 0.3)",
  },
  red: {
    main: "#DE4469",
    hover: "#B73C5A",
    active: "#8F344B",
    opacity80: "#DE4469CC",
    opacity50: "#DE446980",
    opacity20: "#DE446933",
    opacity10: "#DE44691A",
  },
  green: {
    main: "#2DB574",
    hover: "#299763",
    active: "#257852",
    opacity80: "#2DB574CC",
    opacity50: "#2DB57480",
    opacity20: "#2DB57433",
  },
  turqouise: {
    main: "#30D4C1",
    hover: "#2BB0A0",
    active: "#278A80",
    opacity80: "#30D4C1CC",
    opacity50: "#30D4C180",
    opacity20: "#30D4C133",
  },
  aqua: {
    main: "#24B3D0",
    hover: "#24B3D0",
    active: "#24B3D0",
    opacity50: "#24B3D050",
  },
  orange: {
    main: "#FFA318",
    hover: "#D18819",
    active: "#A36D1A",
    opacity50: "#FFA31880",
    opacity20: "#FFA31833",
  },
  pink: {
    main: "#E85DFF",
    hover: "#BF50D2",
    active: "#9543A5",
  },
  violette: {
    main: "#B85DFF",
    hover: "#9850D2",
    active: "#7843A5",
    opacity50: "#B85DFF80",
    opacity20: "#B85DFF33",
  },
  paleTurquoise: {
    main: "#6D9AB4",
    hover: "#5C8196",
    active: "#4B6778",
  },
};
