import { useState, ReactElement } from "react";
import { Box } from "../../../box";
import ECharts from "../../echarts";
import { Typography } from "../../../typography";
import { CustomSwitch } from "../../../custom-switch";
import { ShimmerLoader } from "../../../shimmer-loader";
import { NumberChartProps, NumberChartSeries } from "../../types";
import { getCompositionChartOption } from "./get-composition-chart-option";
import { IconButton } from "../../../icon-button";
import { Tooltip } from "../../../tooltip";
import { CustomIcon } from "../../../custom-icon";
import { transformToCSV } from "../export-utils";
import { downloadCsv } from "../../../utils/csv";

type Props = {
  title?: string;
  description?: string;
  percentToggle?: boolean;
  chartHeight?: number | string;
  emptyState?: boolean;
  emptyStateText?: string;
  headerSuffix?: ReactElement;
  hideTotal?: boolean;
  showAggregateOther?: boolean;
  cryptoCurrency?: string;
  onClick?: (x: number, y: number) => void;
} & NumberChartProps;

const MAX_SERIES_DATA = 5;

const getAggregatedOtherSeries = (series: NumberChartSeries[]) => {
  const isSeriesMoreThanMax = series.length > MAX_SERIES_DATA + 1;

  const othersSeriesData = series
    .slice(MAX_SERIES_DATA + 1)
    .map((s) => s.data)
    .flat();

  const sumByTimestamp = othersSeriesData.reduce(
    (acc: Record<number, number>, [timestamp, value]: [number, number]) => ({
      ...acc,
      [timestamp]: (acc[timestamp] || 0) + value,
    }),
    {},
  );

  const othersSeries: NumberChartSeries = {
    ...series[MAX_SERIES_DATA],
    data: Object.entries(sumByTimestamp).map(
      ([timestamp, value]) => [Number(timestamp), Number(value)] as [number, number],
    ),
    label: "Other",
  };

  return isSeriesMoreThanMax ? othersSeries : undefined;
};

export const CompositionOverTimeChart = ({
  title,
  description,
  percentToggle,
  chartHeight = 280,
  emptyState,
  emptyStateText,
  isLoading,
  series,
  headerSuffix,
  hideTotal,
  showAggregateOther,
  cryptoCurrency,
  tooltip,
  onClick,
  ...restProps
}: Props) => {
  const [isPercent, setIsPercent] = useState(false);
  const hasDataForPercentToggle = series.length > 1;
  const exportToCsv = () => {
    downloadCsv(
      ["Timestamp", ...series.map((serie) => serie.label || "")],
      transformToCSV(series),
      title || "chart_data",
    );
  };
  const header = (
    <Box display="flex" alignItems="flex-start" justifyContent="space-between" mb={2}>
      <Box>
        {title && (
          <Box display="flex" alignItems="stretch" gap={1}>
            <Typography variant="h4">{title}</Typography>
            {tooltip && (
              <Tooltip title={tooltip} placement="right-end">
                <CustomIcon icon="info" sx={{ svg: { width: 20, height: 20 } }} />
              </Tooltip>
            )}
          </Box>
        )}
        {description && (
          <Typography sx={{ "&:after": { content: '" "', whiteSpace: "pre" } }}>{description}</Typography>
        )}
      </Box>
      <Box display="flex" alignItems="center" justifyContent="space-after" mb={0}>
        {percentToggle && hasDataForPercentToggle && (
          <CustomSwitch
            onChange={() => setIsPercent(!isPercent)}
            checked={!isPercent}
            checkedLabel={cryptoCurrency || "$"}
            uncheckedLabel="%"
          />
        )}
        {headerSuffix}
        <IconButton size="small" onClick={exportToCsv} sx={{ ml: 2 }}>
          <Tooltip title="Export to CSV">
            <CustomIcon icon="export" sx={{ svg: { width: 24, height: 24 } }} />
          </Tooltip>
        </IconButton>
      </Box>
    </Box>
  );
  const leftAxisSeries = series.filter((s) => !s.yAxisIndex);
  const otherSeries = series.filter((s) => s.yAxisIndex);
  const sortedLeftAxisSeries = [...(leftAxisSeries || [])].sort(
    (a, b) => b.data.reduce((acc, v) => acc + v[1], 0) - a.data.reduce((acc, v) => acc + v[1], 0),
  );
  const aggregateOther = showAggregateOther && sortedLeftAxisSeries.length >= 10;
  const topLeftSeries = aggregateOther ? sortedLeftAxisSeries.slice(0, MAX_SERIES_DATA) : sortedLeftAxisSeries;
  const otherLeftSeries = aggregateOther && getAggregatedOtherSeries(sortedLeftAxisSeries);

  if (otherLeftSeries) {
    topLeftSeries.push(otherLeftSeries);
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
      }}
    >
      {(title || percentToggle) && header}
      <Box height={chartHeight}>
        {isLoading ? (
          <ShimmerLoader sx={{ borderRadius: 1 }} />
        ) : (
          <ECharts
            onClick={onClick}
            emptyState={emptyState}
            emptyStateText={emptyStateText}
            option={getCompositionChartOption({
              isPercent,
              series: [...topLeftSeries, ...otherSeries],
              showPercentOfTotal: isPercent,
              hideTotal,
              cryptoCurrency,
              ...restProps,
            })}
          />
        )}
      </Box>
    </Box>
  );
};
