import { Box } from "../../../box";
import { Typography } from "../../../typography";
import ECharts from "../../echarts";
import { ShimmerLoader } from "../../../shimmer-loader";
import { NumberChartProps } from "../../types";
import { getValueOverTimeChart } from "./get-value-over-time-chart-option";
import { downloadCsv } from "../../../utils/csv";
import { transformToCSV } from "../export-utils";
import { IconButton } from "../../../icon-button";
import { CustomIcon } from "../../../custom-icon";
import { Tooltip } from "../../../tooltip";

type Props = {
  title: string;
  description?: React.ReactNode;
  chartHeight?: number;
  emptyState?: boolean;
  emptyStateText?: string;
  suffix?: JSX.Element;
} & NumberChartProps;

export const ValueOverTimeChart = ({
  title,
  description,
  chartHeight = 280,
  emptyState,
  emptyStateText,
  isLoading,
  suffix,
  series,
  zoomable,
  tooltip,
  ...restProps
}: Props) => {
  const exportToCsv = () => {
    downloadCsv(
      ["Timestamp", ...series.map((serie) => serie.label || "")],
      transformToCSV(series),
      title || "chart_data",
    );
  };
  return (
    <Box>
      <Box display="flex" alignItems="flex-start" justifyContent="space-between" mb={2}>
        <Box flex="1">
          <Box display="flex" alignItems="stretch" gap={1}>
            <Typography variant="h4">{title}</Typography>
            {tooltip && (
              <Tooltip title={tooltip} placement="right-end">
                <CustomIcon icon="info" sx={{ svg: { width: 20, height: 20 } }} />
              </Tooltip>
            )}
          </Box>
          {typeof description === "string" ? <Typography>{description}</Typography> : description}
        </Box>
        {suffix}
        <IconButton size="small" onClick={exportToCsv} sx={{ ml: 2 }}>
          <Tooltip title="Export to CSV">
            <CustomIcon icon="export" sx={{ svg: { width: 24, height: 24 } }} />
          </Tooltip>
        </IconButton>
      </Box>
      <Box height={chartHeight}>
        {isLoading ? (
          <ShimmerLoader sx={{ borderRadius: 1 }} />
        ) : (
          <ECharts
            emptyState={emptyState}
            emptyStateText={emptyStateText}
            option={getValueOverTimeChart({ series, zoomable, ...restProps })}
            zoomable={zoomable}
          />
        )}
      </Box>
    </Box>
  );
};
