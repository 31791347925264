import { Box, Paper, Typography } from "@frontend/ui";
import { PieChart } from "@frontend/ui/charts";
import { useEffect, useState } from "react";
import { ProtocolPoolsHistoryFragment } from "src/pages/ccar-lending-page/generated";
import { capitalizeFirstLetter } from "@frontend/ui/utils/formatters";
import { ShimmerLoader } from "@frontend/ui/shimmer-loader";
import TvlBalanceToggle from "./tvl-balance-toggle";

type Props = {
  asset: string;
  poolsHistoryByProtocol?: ProtocolPoolsHistoryFragment[];
};
const TotalPoolsAmountProtocolDistribution = ({ asset, poolsHistoryByProtocol }: Props) => {
  const [showTvl, setShowTvl] = useState(false);
  const [poolsData, setPoolsData] = useState(poolsHistoryByProtocol);

  useEffect(() => {
    if (poolsHistoryByProtocol) setPoolsData(poolsHistoryByProtocol);
  }, [poolsHistoryByProtocol]);

  const chartData = (poolsData || []).map(({ protocol, data }) => {
    const lastPoint = [...data].sort((a, b) => b.timestamp - a.timestamp)[0];

    return {
      x: capitalizeFirstLetter(protocol),
      y: showTvl ? lastPoint.totalTvlUSD : lastPoint.totalTokenBalance,
    };
  });

  return (
    <Paper variant="widget" sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h5">Distribution of {asset} across Protocols</Typography>
        <TvlBalanceToggle asset={asset} showTvl={showTvl} setShowTvl={setShowTvl} />
      </Box>
      {!poolsData ? (
        <ShimmerLoader sx={{ height: 240 }} />
      ) : (
        <PieChart format={showTvl ? "currency" : "number"} showLegendIcon size={240} data={chartData} />
      )}
    </Paper>
  );
};
export default TotalPoolsAmountProtocolDistribution;
