import { Grid } from "@frontend/ui";
import { getDeltaFields } from "@frontend/ui/trend";
import { ValueCard, ValueCardProps } from "@frontend/ui/value-card";
import { StableCoinCard } from "src/pages/ccar-lending-page/types";
import { useClientConfig } from "src/pages/ccar-lending-page/clients-config";
import { useStablecoinDataContext } from "../../provider";

export const StablecoinCards = () => {
  const { stableCoin } = useClientConfig();
  const { history, marketAssets = {}, price, assetStats, marketCap, volume } = useStablecoinDataContext();
  const distribution = Object.values(history ?? {});

  const collateralAssets = Object.values(marketAssets)
    .filter((ma) => !stableCoin?.marketType || ma.marketType === stableCoin?.marketType)
    .filter((ma) => ma.asset !== stableCoin?.asset);

  const stableCoinAssets = Object.values(marketAssets).filter((ma) => ma.asset === stableCoin?.asset);

  const currentStats = assetStats?.current;
  const previousStats = assetStats?.previous;

  const { totalBorrowToken: currTotalBorrowToken, borrowCap } = currentStats || {};

  const { totalBorrowToken: prevTotalBorrowToken } = previousStats || {};

  const cardsConfig: Record<StableCoinCard, ValueCardProps> = {
    totalMinted: {
      value: distribution[distribution.length - 1]?.total,
      title: `${stableCoin?.asset || ""} Minted`,
      icon: "borrow-icon",
      currency: "USD",
    },
    totalBorrow: {
      title: "Total Borrow",
      value: currTotalBorrowToken ?? undefined,
      icon: "deposit-icon",
      ...getDeltaFields(currTotalBorrowToken || undefined, prevTotalBorrowToken || undefined, true),
      progress: borrowCap
        ? {
            maxValue: borrowCap,
            displayMaxValue: true,
          }
        : undefined,
      valueMaximumFractionDigits: borrowCap ? 1 : undefined,
    },
    totalSupply: {
      value: stableCoinAssets?.reduce((curr, next) => curr + next.totalSupply, 0),
      title: `${stableCoin?.asset || ""} Supply`,
      icon: "coins-stack-icon",
      currency: "USD",
    },
    totalCollateral: {
      value: collateralAssets?.reduce((curr, next) => curr + next.totalSupply, 0),
      title: "Mint Collateral",
      icon: "coins-stack-icon",
      currency: "USD",
    },
    price: {
      value: price,
      title: "Price",
      icon: "coin-dollar",
      currency: "USD",
      valueMaximumFractionDigits: 10,
    },
    collateraDebtRatio: {
      value: distribution[distribution.length - 1]?.averageCollateralRatio,
      title: "Collateral Ratio",
      icon: "coins",
      isPercent: true,
    },
    marketCap: {
      value: marketCap,
      title: "Market Cap",
      icon: "coins",
      currency: "USD",
    },
    volume: {
      value: volume,
      title: "Volume 24H",
      icon: "coins",
      currency: "USD",
    },
  };

  return (
    <Grid container spacing={1}>
      {stableCoin?.cards?.map((cardName: StableCoinCard) => (
        <Grid key={cardName} item xs={12} sm={4} lg={3}>
          <ValueCard {...cardsConfig[cardName]} />
        </Grid>
      ))}
    </Grid>
  );
};
