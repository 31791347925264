import { formatAxis, formatDate, formatPercent } from "@frontend/ui/utils/formatters";
import {
  EChartsOption,
  YAXisComponentOption,
  XAXisComponentOption,
  GridComponentOption,
  TooltipComponentFormatterCallback,
} from "echarts";
import { echartsTooltipFormatter } from "@frontend/ui";

export type SeriesData = [number, number][];

export type SeriesProps = {
  data: SeriesData;
  color?: string;
  name?: string;
  animation?: boolean;
  showArea?: boolean;
};
export type ChartArgs = {
  type: "line" | "bar";
  series: SeriesProps | SeriesProps[];
  emptyState?: boolean;
  currency?: string;
  yAxisOptions?: YAXisComponentOption;
  xAxisOptions?: XAXisComponentOption;
  grid?: GridComponentOption;
  isXAxisPercentage?: boolean;
  isYAxisPercentage?: boolean;
  legend?: EChartsOption["legend"];
  dataZoom?: EChartsOption["dataZoom"];
  tooltipFormatter?: TooltipComponentFormatterCallback<any>;
  tooltipValueFormatter?: (value: number | string) => string;
  tooltipHeaderFormatter?: (value: number | string) => string;
};

export const ChartOption = ({
  type,
  series,
  currency,
  yAxisOptions,
  xAxisOptions,
  grid,
  isXAxisPercentage,
  isYAxisPercentage,
  legend,
  dataZoom,
  tooltipFormatter,
  tooltipValueFormatter,
  tooltipHeaderFormatter,
}: ChartArgs): EChartsOption => {
  const seriesArray = Array.isArray(series) ? series : [series];
  const isLineChart = type === "line";
  return {
    legend,
    tooltip: isLineChart
      ? {
          trigger: "axis",
          formatter:
            tooltipFormatter ||
            echartsTooltipFormatter({
              headerFormatter:
                tooltipHeaderFormatter ||
                ((value) =>
                  isXAxisPercentage
                    ? formatPercent(Number(value))
                    : xAxisOptions?.type === "time"
                      ? formatDate(new Date(value))
                      : formatAxis(Number(value))),
              currency,
              valueFormatter: tooltipValueFormatter,
            }),
          padding: 0,
          renderMode: "auto",
          verticalAlign: "middle",
        }
      : {
          trigger: "item",
        },
    grid: {
      left: 5,
      ...grid,
    },
    xAxis: {
      type: "category",
      ...xAxisOptions,
    },
    yAxis: {
      ...yAxisOptions,
      type: "value",
      axisLabel: {
        formatter: (value: number) => (isYAxisPercentage ? formatPercent(value) : formatAxis(value, 2, currency)),
        fontSize: 12,
      },
      splitNumber: 3,
    },
    dataZoom,
    series: seriesArray.map(({ data, color, name, showArea, animation }) =>
      isLineChart
        ? {
            name,
            data,
            color,
            type: "line",
            smooth: false,
            symbol: "none",
            lineStyle: {
              width: 1,
            },
            areaStyle: {
              opacity: showArea ? 0.5 : 0,
            },
            animation,
          }
        : {
            data,
            type: "bar",
            color,
            barWidth: 4,
            itemStyle: {
              borderRadius: [50, 50, 0, 0],
            },
            animation,
          },
    ),
  };
};
