import { Box, CustomReactSelect } from "@frontend/ui";
import { PageHeader } from "@frontend/ui/page-header";
import { useParams, useLocation } from "react-router-dom";
import { RouteParams, RoutePath } from "src/config/routes";
import { useDefiAppApiRequest } from "../../api/use-api-request";
import { DefiAppApiEndpoint } from "../../api/types";

const DegensDropdownHeader = () => {
  const { degenId, clientName } = useParams();
  const location = useLocation();
  const { isLoading, response } = useDefiAppApiRequest(DefiAppApiEndpoint.Degens);

  const dropdownOptions = (response || [])
    .sort((a, b) => a.ranking - b.ranking)
    .map(({ userId, username }) => ({
      value: userId,
      label: username,
      link: location.pathname.replace(degenId!, userId),
    }));

  const selectedFaction = dropdownOptions.find((opt) => opt.value === degenId);

  const backPath = RoutePath.Risk.DegenLeaderboard.replace(RouteParams.ClientName, clientName!);

  const breadcrumbsLinks = [
    {
      title: "Degen Leaderboard",
      href: backPath,
    },
  ];

  return (
    <PageHeader
      backLink={backPath}
      pageTitle={
        <Box sx={{ display: "inline-flex", alignItems: "center", gap: 2 }} data-testid="page-title">
          <CustomReactSelect
            options={dropdownOptions}
            value={selectedFaction}
            isSearchable={false}
            variant="h3"
            isLoading={isLoading}
          />
        </Box>
      }
      breadcrumbTitle={selectedFaction?.label || "degen"}
      breadcrumbsLinks={breadcrumbsLinks}
      containerStyle={{ mt: 0, mb: [2, 5.5] }}
      showBackButton
    />
  );
};

export default DegensDropdownHeader;
