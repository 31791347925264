import { Box, Grid } from "@frontend/ui";
import { ValueCard, ValueCardProps } from "@frontend/ui/value-card";
import { TraderStats } from "src/pages/ccar-perpetuals-page/generated";

type Props = {
  traderStats?: TraderStats;
  isLoading: boolean;
};

const Cards = ({ traderStats, isLoading }: Props) => {
  const cards: ValueCardProps[] = [
    {
      title: "Total PnL",
      value: traderStats?.totalPnlUsd,
      currency: "USD",
      tooltip: "Total Realized PnL",
      icon: "chart-maximum",
    },
    {
      title: "Volume",
      value: traderStats?.volumeUsd,
      currency: "USD",
      tooltip: "Cumulative trading volume",
      icon: "coins",
    },
    {
      title: "Total PnL %",
      value: traderStats?.totalPnlPctAvgGrossExposure,
      isPercent: true,
      tooltip: "Total Realized PnL as percent from the average gross exposure",
      icon: "pie-slice",
    },
    {
      title: "Number of Operations",
      value: traderStats?.numOperations,
      tooltip: "Number of trades",
      icon: "transaction",
    },
    {
      title: "Daily Sharpe",
      value: traderStats?.dailySharp,
      tooltip: "Annualized daily Sharpe ratio, calculated on daily returns",
      icon: "waterfall-up",
    },
    {
      title: "Average Gross Exposure",
      value: traderStats?.avgGrossExposure,
      tooltip: "Mean Absolute Exposure",
      icon: "line-up-smooth",
    },
    {
      title: "Max Gross Exposure",
      value: traderStats?.maxGrossExposure,
      tooltip: "Max Absolute Exposure",
      icon: "line-up",
    },
    {
      title: "Max Drawdown",
      value: isLoading ? undefined : traderStats?.maxDd || 0,
      currency: "USD",
      tooltip: "Worst peak-to-trough (based on daily returns)",
      icon: "line-down-smooth",
    },
  ];
  return (
    <Box>
      <Grid container spacing={1}>
        {cards.map((cardProps) => (
          <Grid key={cardProps.title} item xs={12} sm={3}>
            <ValueCard {...cardProps} value={isLoading ? undefined : cardProps.value || "-"} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Cards;
