import { FC } from "react";
import { Box, BoxProps } from "@mui/material";
import { Typography } from "../typography";
import "./progress-bar.scss";

export type ProgressBarType = "small" | "common";

interface ProgressBarProps extends BoxProps {
  progress: number;
  leftText?: string;
  text?: string;
  bgBlack?: boolean;
  barType?: ProgressBarType;
  barWidth?: string;
  textPosition?: "top" | "right" | "bottom" | "left";
  textSize?: string;
  showText?: boolean;
}

export const ProgressBar: FC<ProgressBarProps> = ({
  progress,
  barType,
  text,
  leftText,
  bgBlack,
  barWidth,
  textPosition = "top",
  textSize = "14px",
  showText = true,
  ...props
}) => {
  const isTextTop = textPosition === "top";
  const isTextRight = textPosition === "right";
  const isTextBottom = textPosition === "bottom";
  const isTextLeft = textPosition === "left";

  const isTextHorizontalAlign = isTextRight || isTextLeft;

  const flexDirection = (() => {
    if (isTextTop) return "column";
    if (isTextRight) return "row-reverse";
    if (isTextBottom) return "column-reverse";
    if (isTextLeft) return "row";

    return "row";
  })();

  return (
    <Box
      className={`progress-bar-container ${barType || ""}`}
      display="flex"
      flexDirection={flexDirection}
      alignItems={isTextHorizontalAlign ? "center" : undefined}
      {...props}
    >
      {leftText && (
        <Box
          className="progress-bar-leftText"
          sx={{
            position: "absolute",
            top: 0,
            left: 24,
            bottom: 0,
            display: "flex",
            alignItems: "center",
            zIndex: 100,
          }}
        >
          <Typography sx={{ fontWeight: "fontWeightBold" }}>{leftText}</Typography>
        </Box>
      )}
      {showText && (
        <Box
          sx={{
            marginBottom: isTextTop ? "8px" : undefined,
            marginLeft: isTextRight ? "8px" : undefined,
            marginTop: isTextBottom ? "8px" : undefined,
            marginRight: isTextLeft ? "8px" : undefined,
          }}
        >
          <Typography
            sx={{
              fontWeight: "fontWeightBold",
              color: "#9B9DA1",
              fontSize: textSize,
            }}
          >
            {`${Math.round(progress * 100)}%`}
            {text}
          </Typography>
        </Box>
      )}
      <Box className="progress-bar" width={barWidth ? `${barWidth} !important` : undefined}>
        <Box className={`progress-bar-track ${bgBlack ? "black" : ""}`} />
        <Box
          className="progress-bar-fill"
          style={{
            width: `${100 * progress}%`,
          }}
        />
      </Box>
    </Box>
  );
};
