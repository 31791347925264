import { FC, ReactNode } from "react";
import { Box } from "@frontend/ui";
import { SiderNavBar } from "./sider-nav-bar";
import { NavigationTabProps } from "./use-tabs-navigations";

type Props = {
  children: ReactNode;
  homePath: string;
  tabs?: NavigationTabProps[];
};

export const SiderPageLayout: FC<Props> = ({ children, homePath, tabs }) => (
  <Box minHeight="100vh" display="flex" flexDirection="row" width="100%">
    <SiderNavBar homePath={homePath} tabs={tabs} />
    <Box
      flex={1}
      px={{
        sm: 1,
        md: 4,
      }}
      maxWidth={1560}
      margin="auto"
      width="100%"
      height="100vh"
      maxHeight="100vh"
      overflow="scroll"
    >
      {children}
    </Box>
  </Box>
);
