import { CompositionOverTimeChart, Paper } from "@frontend/ui";
import { TimeSpan } from "@frontend/ui/echarts/types";
import { palette } from "@frontend/ui/theme/palette";
import { HistoryData } from "src/pages/ccar-perpetuals-page/generated";
import { getOiChartCustomTooltip } from "./custom-tooltip";

type Props = {
  shortInterestUsd?: HistoryData[];
  longInterestUsd?: HistoryData[];
  loading: boolean;
  timeSpan?: TimeSpan;
  title?: string;
  showCustomTooltip?: boolean;
  onClick?: (timestamp: number) => void;
};

export const OpenInterestChartWidget = ({
  shortInterestUsd,
  longInterestUsd,
  loading,
  timeSpan,
  title,
  showCustomTooltip,
  onClick,
}: Props) => (
  <Paper variant="widget">
    <CompositionOverTimeChart
      isLoading={loading}
      percentToggle
      title={title || "Open Interest Distribution"}
      timeSpan={timeSpan}
      series={[
        {
          label: "Short",
          color: palette.red.main,
          type: "bar",
          data: shortInterestUsd?.map(({ time, value }) => [time, value]) || [],
        },
        {
          label: "Long",
          color: palette.green.hover,
          type: "bar",
          data: longInterestUsd?.map(({ time, value }) => [time, value]) || [],
        },
      ]}
      onClick={onClick}
      hideTotal={showCustomTooltip}
      getCustomTooltip={showCustomTooltip ? getOiChartCustomTooltip(shortInterestUsd, longInterestUsd) : undefined}
    />
  </Paper>
);
