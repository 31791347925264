import { CompositionOverTimeChart, Paper } from "@frontend/ui";
import { TimeSpan } from "@frontend/ui/echarts/types";
import { TvlElementFragment } from "../../../../generated";
import { getTvlCompositionSeriesData } from "./utils";

type Props = {
  data?: TvlElementFragment[];
  loading: boolean;
  timeSpan?: TimeSpan;
};

export const TvlCompositionChart = ({ data, loading, timeSpan }: Props) => (
  <Paper variant="widget">
    <CompositionOverTimeChart
      title="Total Value Locked"
      isLoading={loading}
      series={getTvlCompositionSeriesData(data || [])}
      timeSpan={timeSpan}
    />
  </Paper>
);
