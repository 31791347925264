import { Grid, ValueCard, ValueCardProps } from "@frontend/ui";
// import { getDeltaAsRatio, getDeltaAsValue } from '../../../components/utils';
import { LiquidationsOverviewFragment } from "../../../generated";

type LiquidationsCardsProps = {
  overview?: LiquidationsOverviewFragment;
};

export const LiquidationsCards = ({ overview }: LiquidationsCardsProps) => {
  const cards: ValueCardProps[] = [
    {
      title: "Short Liquidations",
      icon: "card-eject",
      value: overview?.shortLiquidationsCount,
      // ...getDeltaAsRatio(18_162, 17_162),
    },
    {
      title: "Short Liquidated Value",
      icon: "coins-stack-icon",
      value: overview?.shortLiquidationValueUSD,
      currency: "USD",
      // ...getDeltaAsRatio(614_123, 612_123),
    },
    {
      title: "Long Liquidations",
      icon: "card-eject",
      value: overview?.longLiquidationsCount,
      // ...getDeltaAsRatio(25_242, 42_242),
    },
    {
      title: "Long Liquidated Value",
      icon: "coins-stack-icon",
      value: overview?.longLiquidationValueUSD,
      currency: "USD",
      // ...getDeltaAsValue(35_234_134, 10_234_134),
    },
  ];

  return (
    <Grid container spacing={[1, 2, 3]}>
      {cards.map((card) => (
        <Grid key={card.title} item xs={12} md={6} lg={3}>
          <ValueCard {...card} />
        </Grid>
      ))}
    </Grid>
  );
};
