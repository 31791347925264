import { Box, CustomIcon, Typography } from "@frontend/ui";
import { useLocation } from "react-router-dom";
import { clientViewProps } from "../client-view-props";

export const TopBanner = () => {
  const { pathname } = useLocation();
  const clientsProps = clientViewProps();
  const getBanner = clientsProps?.headerProps.getBanner;
  const banner = getBanner && getBanner(pathname);

  return (
    <Box>
      {banner && (
        <Box
          display="flex"
          gap={1}
          p={1}
          color="black.main"
          bgcolor="warning.main"
          width="100%"
          justifyContent="center"
        >
          <CustomIcon size="x-small" sx={{ fill: "inherit" }} icon={banner.icon} />
          <Typography fontSize={12}>{banner.text}</Typography>
        </Box>
      )}
    </Box>
  );
};
