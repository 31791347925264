import { EChartsOption, YAXisComponentOption } from "echarts";
import { echartsTooltipFormatter } from "../../tooltipFormatter";
import { NumberChartProps, NumberChartSeries } from "../../types";
import { formatEChartValue, formatXAxisDate, formatTooltipDate } from "../../utils";
import { palette } from "../../../theme/palette";

const calculatePercentAtIndex = ({ value, series }: { value: [number, number]; series: NumberChartSeries[] }) => {
  const matchesForValue = series.map((s) => s.data.find((v) => v[0] === value[0]) || [value[0], 0]);

  const total = matchesForValue.reduce((curr, next) => curr + next[1], 0);
  return total ? value[1] / total : 0;
};

export const getCompositionChartOption = ({
  series,
  isPercent,
  currency,
  yAxisLabel,
  dualAxis,
  yAxisLabelRight,
  timeSpan,
  showLegend = true,
  showPercentOfTotal,
  hideTotal,
  cryptoCurrency,
  sortTooltipValues,
  xAxisSplitNumber,
  fillMissingPoints,
  hideLegend,
  getCustomTooltip,
  dateFormatter,
  tooltipHeaderFormatter,
}: NumberChartProps): EChartsOption => {
  const isBarGraph = series.map((s) => s.type).includes("bar");
  const allDates = series.flatMap((s) => s.data.flatMap((d) => d[0])).sort();
  const uniqueDates = Array.from(new Map(allDates.map((d) => [d, null])).keys());

  const legend = showLegend ? series.map((serie) => serie.label || "") : [];

  return {
    tooltip: {
      formatter: echartsTooltipFormatter({
        showPercentOfTotal,
        getCustomTooltip,
        headerFormatter: (value) => tooltipHeaderFormatter?.(value) || formatTooltipDate(Number(value), timeSpan),
        valueFormatter: (v) =>
          formatEChartValue({
            isPercent: isPercent || showPercentOfTotal,
            currency,
            notation: "standard",
            cryptoCurrency,
          })(Number(v)),
        currency,
        showTotal: !showPercentOfTotal && !hideTotal,
        showXAxisInline: false,
        hideZeroValues: isBarGraph,
        sortValues: sortTooltipValues,
      }),
      padding: 0,
      renderMode: "auto",
      verticalAlign: "middle",
      trigger: "axis",
    },
    legend: hideLegend
      ? undefined
      : {
          data: legend,
          type: "scroll",
          padding: [0, 24, 0, 0],
        },
    grid: {
      bottom: 5,
      top: !hideLegend && legend.length ? 60 : 20,
      left: yAxisLabel ? 45 : 10,
      right: yAxisLabelRight ? 55 : 15,
    },
    toolbox: {
      feature: {
        saveAsImage: {
          show: false,
        },
      },
    },
    xAxis: {
      type: "time",
      axisLine: {
        show: true,
      },
      splitNumber: xAxisSplitNumber || 5,
      axisLabel: {
        hideOverlap: true,
        fontSize: 12,
        formatter: (v: number) => dateFormatter?.(v) || formatXAxisDate(v, timeSpan),
      },
    },
    yAxis: [
      {
        type: "value",
        name: yAxisLabel,
        nameLocation: "middle",
        position: "left",
        nameTextStyle: {
          color: palette.white.main,
        },
        nameGap: 60,
        axisLabel: {
          fontSize: 12,
          formatter: (v: number) =>
            formatEChartValue({ isPercent: isPercent || showPercentOfTotal, currency, cryptoCurrency })(Number(v)),
        },
      },
      ...(dualAxis || yAxisLabelRight
        ? [
            {
              type: "value",
              name: yAxisLabelRight,
              nameGap: cryptoCurrency ? 85 : 70,
              nameLocation: "middle",
              position: "right",
              nameTextStyle: {
                color: palette.white.main,
              },
              axisLabel: {
                fontSize: 12,
                formatter: (v: number) =>
                  formatEChartValue({ isPercent: isPercent || showPercentOfTotal, currency, cryptoCurrency })(
                    Number(v),
                  ),
              },
            } as YAXisComponentOption,
          ]
        : []),
    ],
    series: series.map((serie) => {
      const baseSerie = {
        name: serie.label,
        color: serie.color,
        stack: "Total",
        areaStyle:
          serie.type !== "line"
            ? {}
            : {
                opacity: serie.showArea ? 0.5 : 0,
              },
        ...(serie.z !== undefined && { z: serie.z }),
        smooth: serie.smooth,
        animation: false,
        symbol: "none",
        data: uniqueDates.map((date, i) => {
          const matchingDate = serie.data.find((d) => d[0] === date);
          if (!matchingDate && fillMissingPoints) {
            const prevDate = uniqueDates[i - 1];
            const matchingPrev = serie.data.find((d) => d[0] === prevDate);
            return [date, matchingPrev?.[1] || 0, 1]; // the third value indicates that it's a filled point
          }

          if (!matchingDate) {
            return [date, 0];
          }

          if (showPercentOfTotal) {
            return [date, calculatePercentAtIndex({ value: matchingDate, series })];
          }

          return [date, matchingDate[1]];
        }),
        yAxisIndex: serie.yAxisIndex,
      };

      if (serie.type === "bar") {
        return {
          ...baseSerie,
          type: "bar",
          barWidth: "50%",
          barMaxWidth: 12,
          barGap: 1,
          ...(serie.stack && { stack: serie.stack }),
          itemStyle: serie.highlightTrends
            ? {
                color: (item) => {
                  if (Array.isArray(item.data)) {
                    return Number(item.data[1].valueOf()) < 0 ? palette.red.main : palette.green.main;
                  }

                  return palette.green.main;
                },
              }
            : undefined,
        };
      }

      return {
        ...baseSerie,
        type: "line",
      };
    }),
  };
};
