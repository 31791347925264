import { CompositionOverTimeChart, Paper } from "@frontend/ui";
import { HistoryDataFragment } from "src/pages/ccar-perpetuals-page/generated";
import { NumberChartSeries } from "@frontend/ui/echarts/types";

type Props = {
  isLoading: boolean;
  data?: HistoryDataFragment[];
};

export const UniqueUsersChart = ({ isLoading, data = [] }: Props) => {
  const series: NumberChartSeries[] = [
    {
      label: "Unique Users",
      type: "line",
      data: data.map((s) => [s.time, s.value]),
      highlightTrends: true,
    },
  ];

  return (
    <Paper variant="widget">
      <CompositionOverTimeChart
        title="Number of Unique Users Over Time"
        isLoading={isLoading}
        hideTotal
        series={series}
        showLegend={false}
        currency=""
      />
    </Paper>
  );
};
