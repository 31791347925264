import { Controller, useFormContext, useWatch } from "react-hook-form";
import { Grid, useConfirmationMessage } from "@frontend/ui";
import { ClientConfig, NotificationChannel, NotificationConfig } from "../utils";
import { NotificationChannelInput } from "./notification-channel-input";
import { CcarClient } from "../../alerts/utils";

interface NotificationConfigurationSectionProps {
  submitting: boolean;
}

export const NotificationConfigurationSection = ({ submitting }: NotificationConfigurationSectionProps) => {
  const { control, setValue } = useFormContext();
  const { confirm } = useConfirmationMessage();

  const [clientsConfig] = useWatch({
    control,
    name: ["clientsConfig"],
  }) as [Partial<Record<CcarClient, ClientConfig>>];

  const handlePreActiveChange = async (channel: NotificationChannel | "community", enabled: boolean) => {
    if (enabled) {
      return;
    }

    if (channel === "community") {
      if (Object.values(clientsConfig).some((config) => config?.hideFromUi)) {
        confirm({
          title: "Hide Alerts From UI",
          message: "This setting is enabled for some clients, Do you want to show alerts for all clients?",
          onConfirm: () => {
            setValue(
              "clientsConfig",
              Object.entries(clientsConfig).reduce(
                (acc, [client, config]) => {
                  acc[client as CcarClient] = {
                    ...config,
                    hideFromUi: false,
                  };
                  return acc;
                },
                {} as Partial<Record<CcarClient, ClientConfig>>,
              ),
            );
          },
          confirmText: "Yes",
          cancelText: "No",
        });
      }
    } else if (Object.values(clientsConfig).some((config) => config?.notificationConfig?.[channel]?.enabled)) {
      confirm({
        title: "Disable Notification Channel",
        message: "This channel is enabled for some clients, Do you want to disable this channel for all clients?",
        onConfirm: () => {
          setValue(
            "clientsConfig",
            Object.entries(clientsConfig).reduce(
              (acc, [client, config]) => {
                acc[client as CcarClient] = {
                  ...config,
                  notificationConfig: {
                    ...config?.notificationConfig,
                    [channel]: {
                      ...config?.notificationConfig?.[channel],
                      enabled: false,
                    },
                  },
                };
                return acc;
              },
              {} as Partial<Record<CcarClient, ClientConfig>>,
            ),
          );
        },
        confirmText: "Yes",
        cancelText: "No",
      });
    }
  };

  return (
    <Grid
      container
      sx={{
        border: "1px solid",
        borderBottom: "none",
        borderRight: "none",
        borderColor: "divider",
        "& > div": {
          borderRight: "1px solid",
          borderBottom: "1px solid",
          borderColor: "divider",
        },
      }}
    >
      {Object.keys(NotificationChannel).map((channel) => (
        <Grid item lg={4} md={6} xs={12} key={channel}>
          <Controller
            disabled={submitting}
            render={({ field }) => (
              <NotificationChannelInput
                channel={channel as NotificationChannel}
                value={field.value as NotificationConfig}
                onChange={field.onChange}
                disabled={field.disabled}
                preActiveChange={handlePreActiveChange}
              />
            )}
            name={`globalNotificationConfig.${channel}`}
            control={control}
          />
        </Grid>
      ))}
      <Grid item lg={4} md={6} xs={12}>
        <Controller
          name="hideFromUi"
          control={control}
          disabled={submitting}
          render={({ field }) => (
            <NotificationChannelInput
              channel="community"
              value={{
                enabled: !!field.value,
              }}
              onChange={(value) => field.onChange(value.enabled)}
              disabled={field.disabled}
              preActiveChange={handlePreActiveChange}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};
