import { useParams } from "react-router-dom";
import { RouteParams } from "src/config/routes";
import { PageHeader } from "@frontend/ui/page-header";
import { Methodology } from "./types";

type Props = {
  methodology: Methodology;
};

const MethodologyPageHeader = ({ methodology }: Props) => {
  const { clientName } = useParams<{ clientName: string }>();

  const breadcrumbsLinks = [
    {
      title: methodology.backPageTitle || "Methodology",
      href: methodology.redirectBack.replace(RouteParams.ClientName, clientName!),
    },
  ];

  return (
    <PageHeader
      pageTitle={methodology.methodologyTitle}
      breadcrumbsLinks={breadcrumbsLinks}
      containerStyle={{ mt: 0, mb: 2 }}
    />
  );
};

export default MethodologyPageHeader;
