import { DataItem, HistogramChartRow } from "@frontend/ui/charts";
import { WalletHealthHistogramItem } from "../../../generated";

type Props = {
  title: string;
  description: string;
  walletsHealth: WalletHealthHistogramItem[];
};

const MarketWalletsHealthHistogram = ({ walletsHealth, title, description }: Props) => (
  <HistogramChartRow
    title={title}
    description={description}
    chartHeight={300}
    data={[
      (walletsHealth || []).reduce((acc: DataItem[], { bucket: { start, end }, numberOfWallets }, index: number) => {
        const isLastItem = index === walletsHealth.length - 1;
        acc.push({
          x: isLastItem ? `>= ${start}` : `${start} - ${end}`,
          y: numberOfWallets,
        });
        return acc;
      }, []),
    ]}
    legend={["Number of wallets"]}
  />
);

export default MarketWalletsHealthHistogram;
