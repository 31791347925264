import { Box } from "@frontend/ui";
import { OverviewCards } from "./overview-cards";
import { OverviewCharts } from "./overview-charts";

const CCARPerpetualsOverviewTab = () => (
  <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
    <OverviewCards />
    <OverviewCharts />
  </Box>
);
export default CCARPerpetualsOverviewTab;
