export enum CcarClient {
  AAVE_V3 = "AAVE_V3",
  AAVE_V2 = "AAVE_V2",
  BENQI = "BENQI",
  RADIANT = "RADIANT",
  GMX_ARBITRUM = "GMX_ARBITRUM",
  GMX_AVALANCHE = "GMX_AVALANCHE",
  GMX_V2_ARBITRUM = "GMX_V2_ARBITRUM",
  GMX_V2_AVALANCHE = "GMX_V2_AVALANCHE",
  VENUS = "VENUS",
  CRV_USD = "CRV_USD",
  DYDXV4 = "DYDXV4",
  OSTIUM_TESTNET = "OSTIUM_TESTNET",
  OSTIUM = "OSTIUM",
  SEAMLESS = "SEAMLESS",
  BLUEFIN = "BLUEFIN",
  ZEROLEND = "ZEROLEND",
  NFTPERP = "NFTPERP",
  JUPITER = "JUPITER",
  SYNFUTURES = "SYNFUTURES",
  APTOS = "APTOS",
  GEARBOX = "GEARBOX",
  ETHERFI = "ETHERFI",
}

export const mapProtocolToData: Record<CcarClient, { icon: string; version: string; name: string }> = {
  [CcarClient.AAVE_V3]: {
    icon: "aave",
    version: "V3",
    name: "Aave",
  },
  [CcarClient.AAVE_V2]: {
    icon: "aave",
    version: "V2",
    name: "Aave",
  },
  [CcarClient.BENQI]: {
    icon: "benqi",
    version: "",
    name: "Benqi",
  },
  [CcarClient.RADIANT]: {
    icon: "radiant",
    version: "",
    name: "Radiant",
  },
  [CcarClient.GMX_ARBITRUM]: {
    icon: "gmx",
    version: "V1 Arbitrum",
    name: "GMX",
  },
  [CcarClient.GMX_AVALANCHE]: {
    icon: "gmx",
    version: "V1 Avalanche",
    name: "GMX",
  },
  [CcarClient.GMX_V2_ARBITRUM]: {
    icon: "gmx",
    version: "V2 Arbitrum",
    name: "GMX",
  },
  [CcarClient.GMX_V2_AVALANCHE]: {
    icon: "gmx",
    version: "V2 Avalanche",
    name: "GMX",
  },
  [CcarClient.VENUS]: {
    icon: "venus",
    version: "",
    name: "Venus",
  },
  [CcarClient.CRV_USD]: {
    icon: "crvusd",
    version: "",
    name: "CrvUSD",
  },
  [CcarClient.DYDXV4]: {
    icon: "dydx",
    version: "",
    name: "dYdX",
  },
  [CcarClient.OSTIUM_TESTNET]: {
    icon: "ostium",
    version: "Testnet",
    name: "Ostium",
  },
  [CcarClient.OSTIUM]: {
    icon: "ostium",
    version: "Mainnet",
    name: "Ostium",
  },
  [CcarClient.SEAMLESS]: {
    icon: "seamless",
    version: "",
    name: "Seamless",
  },
  [CcarClient.BLUEFIN]: {
    icon: "bluefin",
    version: "",
    name: "Bluefin",
  },
  [CcarClient.ZEROLEND]: {
    icon: "zerolend",
    version: "",
    name: "ZeroLend",
  },
  [CcarClient.NFTPERP]: {
    icon: "nftperp",
    version: "",
    name: "NFTPerp",
  },
  [CcarClient.JUPITER]: {
    icon: "jupiter",
    version: "",
    name: "Jupiter",
  },
  [CcarClient.SYNFUTURES]: {
    icon: "synfutures",
    version: "",
    name: "SynFutures",
  },
  [CcarClient.APTOS]: {
    icon: "aptos",
    version: "",
    name: "Aptos",
  },
  [CcarClient.GEARBOX]: {
    icon: "gearbox",
    version: "",
    name: "Gearbox",
  },
  [CcarClient.ETHERFI]: {
    icon: "etherfi",
    version: "",
    name: "EtherFi",
  },
};
