import { Box, CustomReactSelect } from "@frontend/ui";
import { PageHeader } from "@frontend/ui/page-header";
import { wrappedSymbolToIconSymbol } from "@frontend/ui/utils/icon-helper";
import { useEffect } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { RouteParams, RoutePath } from "src/config/routes";
import { useClientConfig } from "../clients-config";
import { Chain } from "../generated";

const ChainDropdownHeader = () => {
  const { clientName, chain } = useParams<{ clientName: string; chain: Chain }>();
  const location = useLocation();
  const navigate = useNavigate();
  const { chains } = useClientConfig();

  useEffect(() => {
    if (!chains.includes(chain!)) {
      navigate(RoutePath.Risk.Chains.replace(RouteParams.ClientName, clientName!));
    }
  }, [chain, chains, navigate, clientName]);

  const dropdownOptions = chains.map((c) => ({
    value: c,
    label: c,
    cryptoIcon: wrappedSymbolToIconSymbol(c),
    link: location.pathname.replace(chain!, c),
  }));

  const pageTitle = (
    <Box sx={{ display: "inline-block" }} data-testid="page-title">
      <CustomReactSelect
        options={dropdownOptions}
        value={dropdownOptions.find((option) => option.value === chain!)}
        isSearchable={false}
        variant="h3"
      />
    </Box>
  );

  return (
    <PageHeader
      backLink={RoutePath.Risk.Chains.replace(RouteParams.ClientName, clientName!)}
      pageTitle={pageTitle}
      containerStyle={{ mt: 0, mb: [2, 5.5] }}
      showBackButton
    />
  );
};

export default ChainDropdownHeader;
