import { Grid, ValueCard, ValueCardProps } from "@frontend/ui";
import { useTradingOverviewQuery } from "../../generated";
import { getDeltaAsRatio, getDeltaAsValue } from "../../components/utils";
import { useClientConfig } from "../../clients-config";
import { OverviewCard } from "../../types";

const defaultCardsIds: OverviewCard[] = ["trading-volume"];

export const OverviewCards = () => {
  const { overview } = useClientConfig();
  const { data } = useTradingOverviewQuery({
    variables: {
      daysAgo: 1,
      shouldFetchTVL: false,
      useVolumeFromMarkets: overview?.useVolumeCardFromMarkets,
      hourlySupport: overview?.hourlySupport,
    },
  });

  const current = data?.tradingOverviewStats.current;
  const previous = data?.tradingOverviewStats.previous;

  const cardById: Record<OverviewCard, ValueCardProps> = {
    "glp-pool": {
      title: "GLP Pool",
      icon: "wallet-icon",
      value: current?.totalLiquidityUsd,
      currency: "USD",
      ...getDeltaAsRatio(current?.totalLiquidityUsd, previous?.totalLiquidityUsd),
    },
    tvl: {
      title: "Total Value Locked",
      icon: "lock-icon",
      value: current?.tvl || data?.aggSubAccounts?.tvl || undefined,
      currency: "USD",
      ...getDeltaAsValue(current?.tvl || 0, previous?.tvl || undefined),
    },
    fees: {
      title: "Fees 1D",
      icon: "deposit-icon",
      value: current?.dailyFeesUsd,
      currency: "USD",
      ...getDeltaAsRatio(current?.dailyFeesUsd, previous?.dailyFeesUsd),
    },
    "trading-volume": {
      title: "Trading Volume 1D",
      icon: "exponential-graph",
      value: current?.dailyVolumeUsd,
      currency: "USD",
      ...getDeltaAsRatio(current?.dailyVolumeUsd, previous?.dailyVolumeUsd),
    },
    "daily-trades": {
      title: "Daily Trades",
      icon: "coins",
      value: current?.dailyTrades !== null ? current?.dailyTrades : undefined,
      ...getDeltaAsValue(
        current?.dailyTrades !== null ? current?.dailyTrades : undefined,
        previous?.dailyTrades !== null ? previous?.dailyTrades : undefined,
      ),
    },
    "open-positions": {
      title: "Open Positions",
      icon: "wallet-icon",
      value: current?.openPositions,
      ...getDeltaAsValue(current?.openPositions, previous?.openPositions),
    },
    "open-interest": {
      title: "Total Open Interest",
      icon: "coins-stack-icon",
      value: current?.totalOpenInterestUsd,
      currency: "USD",
      ...getDeltaAsValue(current?.totalOpenInterestUsd, previous?.totalOpenInterestUsd),
    },
    "oi-imbalance": {
      title: "Open Interest Imbalance",
      icon: "line-down-smooth",
      value: current?.openInterestImbalance,
      isPercent: true,
      ...getDeltaAsValue(current?.openInterestImbalance, previous?.openInterestImbalance),
    },
  };

  return (
    <Grid container spacing={[1, 2, 3]}>
      {(overview?.cards || defaultCardsIds).map((id) => (
        <Grid key={id} item xs={12} md={6} lg={3}>
          <ValueCard {...cardById[id]} id={id} />
        </Grid>
      ))}
    </Grid>
  );
};
