import { Box, CustomReactSelect } from "@frontend/ui";
import { PageHeader } from "@frontend/ui/page-header";
import { useParams, useLocation } from "react-router-dom";
import { RouteParams, RoutePath } from "src/config/routes";
import { useGlvsQuery } from "src/pages/ccar-perpetuals-page/generated";

const GlvDropdownHeader = () => {
  const { clientName, address } = useParams();
  const location = useLocation();

  const { data: glvsData, loading } = useGlvsQuery();
  const glvs = glvsData?.glvs || [];

  const dropdownOptions = glvs.map(({ glvName, glvAddress }) => ({
    value: glvAddress,
    label: glvName,
    cryptoIcon: "glv",
    link: location.pathname.replace(address!, glvAddress),
  }));

  return (
    <PageHeader
      backLink={RoutePath.Risk.Markets.replace(RouteParams.ClientName, clientName!)}
      pageTitle={
        <Box display="flex" gap={[1, 2]} alignItems="center">
          <CustomReactSelect
            options={dropdownOptions}
            value={dropdownOptions.find((option) => option.value === address!)}
            isSearchable={false}
            variant="h3"
            isLoading={loading}
          />
        </Box>
      }
      containerStyle={{ mt: 0, mb: [2, 5.5] }}
    />
  );
};

export default GlvDropdownHeader;
