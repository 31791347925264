import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { Button } from "@frontend/ui";
import { createContext, FC, ReactNode, useCallback, useContext, useMemo, useState } from "react";

interface ConfirmationDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: ReactNode;
  message: ReactNode;
  confirmText: string;
  cancelText: string;
}

export const ConfirmationDialog: FC<ConfirmationDialogProps> = ({
  open,
  onClose,
  onConfirm,
  title,
  message,
  confirmText,
  cancelText,
}) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>{message}</DialogContent>
    <DialogActions>
      <Button onClick={onClose}>{cancelText}</Button>
      <Button onClick={onConfirm} color="primary" autoFocus>
        {confirmText}
      </Button>
    </DialogActions>
  </Dialog>
);

interface ConfirmFunctionArgs {
  title: ReactNode;
  message: ReactNode;
  onConfirm: () => void;
  onCancel?: () => void;
  confirmText?: string;
  cancelText?: string;
}

interface ConfirmationMessageContextProps {
  confirm: (args: ConfirmFunctionArgs) => void;
}

const ConfirmationMessageContext = createContext<ConfirmationMessageContextProps>({
  confirm: () => {
    throw new Error("ConfirmationMessageContext not provided");
  },
});

export const useConfirmationMessage = () => useContext(ConfirmationMessageContext);

export const ConfirmationMessageProvider: FC = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [confirmFunctionArgs, setConfirmFunctionArgs] = useState<ConfirmFunctionArgs | null>(null);

  const confirm = useMemo(
    () => ({
      confirm: (args: ConfirmFunctionArgs) => {
        setConfirmFunctionArgs(args);
        setOpen(true);
      },
    }),
    [],
  );

  const handleConfirm = useCallback(() => {
    confirmFunctionArgs?.onConfirm();
    setOpen(false);
  }, [confirmFunctionArgs]);

  const handleCancel = useCallback(() => {
    confirmFunctionArgs?.onCancel?.();
    setOpen(false);
  }, [confirmFunctionArgs]);

  return (
    <ConfirmationMessageContext.Provider value={confirm}>
      {children}
      <ConfirmationDialog
        open={open}
        onClose={handleCancel}
        onConfirm={handleConfirm}
        title={confirmFunctionArgs?.title}
        message={confirmFunctionArgs?.message}
        confirmText={confirmFunctionArgs?.confirmText ?? "Confirm"}
        cancelText={confirmFunctionArgs?.cancelText ?? "Cancel"}
      />
    </ConfirmationMessageContext.Provider>
  );
};
