import { Header, RenderData } from "@frontend/types";
import { ChaosTable } from "@frontend/ui";
import { formatAmount } from "@frontend/ui/utils/formatters";
import { useFacilitatorsQuery } from "./use-facilitators-query";
import { useStablecoinDataContext } from "../../provider";

export const FacilitatorsTable = () => {
  const { isLoading, data = [] } = useFacilitatorsQuery();
  const { tokenSymbol } = useStablecoinDataContext();

  const headers: Header[] = [
    { text: "Name", renderType: "TEXT" },
    { text: `Minted ${tokenSymbol}`, renderType: "ICONS" },
    { text: `${tokenSymbol} Limit`, renderType: "ICONS" },
    {
      text: "% Minted",
      renderType: "TEXT",
      tooltip: `Sum of actively borrowed ${tokenSymbol} through this facilitator`,
    },
  ];

  const tableData: RenderData[][] = data.map(
    ({ name, ghoMinted, ghoMintLimit }) =>
      [
        {
          renderType: "TEXT",
          text: name,
          token1: name,
        },
        {
          renderType: "TEXT",
          text: formatAmount(ghoMinted, { notation: "standard" }),
          token1: tokenSymbol,
          value: ghoMinted,
        },
        {
          renderType: "TEXT",
          text: formatAmount(ghoMintLimit, { notation: "standard" }),
          token1: tokenSymbol,
          value: ghoMintLimit,
        },
        {
          renderType: "PROGRESS",
          progressValue: ghoMinted / ghoMintLimit,
          width: 150,
        },
      ] as RenderData[],
  );

  return (
    <ChaosTable
      title="Facilitators"
      data={tableData}
      headers={headers}
      isLoading={isLoading}
      isFilterHidden
      isSettingsHidden
    />
  );
};
