import { useState } from "react";
import { CustomSwitch, Paper, ValueOverTimeChart } from "@frontend/ui";
import { useGhoTotalSupplyQuery } from "../../../generated";
import { useStablecoinDataContext } from "../provider";

const GhoTotalSupplyChart = () => {
  const { timeSpan } = useStablecoinDataContext();
  const { data, loading } = useGhoTotalSupplyQuery({
    variables: {
      timeSpan,
    },
  });
  const [showRate, setShowRate] = useState(false);

  return (
    <Paper variant="card">
      <Paper variant="widget">
        <ValueOverTimeChart
          key="gho-supply-chart"
          title={showRate ? "% of GHO in stkGHO" : "Total Supply of GHO & stkGHO"}
          series={
            showRate
              ? [
                  {
                    type: "line",
                    label: "Rate",
                    data: (data?.ghoTotalSupply || []).map(({ timestamp, ghoTotalSupply, stkGhoTotalSupply }) => [
                      timestamp,
                      stkGhoTotalSupply / ghoTotalSupply,
                    ]),
                  },
                ]
              : [
                  {
                    type: "line",
                    label: "GHO",
                    data: (data?.ghoTotalSupply || []).map(({ timestamp, ghoTotalSupply }) => [
                      timestamp,
                      ghoTotalSupply,
                    ]),
                  },
                  {
                    type: "line",
                    label: "stkGHO",
                    data: (data?.ghoTotalSupply || []).map(({ timestamp, stkGhoTotalSupply }) => [
                      timestamp,
                      stkGhoTotalSupply,
                    ]),
                  },
                ]
          }
          isLoading={loading}
          timeSpan={timeSpan}
          currency=""
          yAxisMax
          yAxisMin
          hideLegend={showRate}
          isPercent={showRate}
          suffix={
            <CustomSwitch
              onChange={() => setShowRate(!showRate)}
              checked={showRate}
              checkedLabel="%"
              uncheckedLabel="Token"
            />
          }
        />
      </Paper>
    </Paper>
  );
};

export default GhoTotalSupplyChart;
