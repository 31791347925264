import { Paper, CompositionOverTimeChart, CustomSwitch } from "@frontend/ui";
import { NumberChartSeries } from "@frontend/ui/echarts/types";
import { useState } from "react";
import { HistoryDataFragment } from "src/pages/ccar-perpetuals-page/generated";

type Props = {
  isLoading: boolean;
  data?: HistoryDataFragment[];
  title?: string;
  subtitle?: string;
};

const getChartProps = (
  data: HistoryDataFragment[] = [],
  showAggregatedPnL = false,
): { series: NumberChartSeries[] } => ({
  series: showAggregatedPnL
    ? [
        {
          label: "Aggregated",
          type: "line",
          data: [...data]
            .sort((a, b) => a.time - b.time)
            .reduce<[number, number][]>((acc, s, i) => [...acc, [s.time, (acc[i - 1]?.[1] || 0) + s.value]], []),
          highlightTrends: true,
        },
      ]
    : [
        {
          label: "Realized PnL",
          type: "bar",
          data: data.map((s) => [s.time, s.value]),
          highlightTrends: true,
        },
      ],
});

export const RealizedPnLChart = ({ isLoading, data = [], title, subtitle }: Props) => {
  const [showAggregatedPnL, setShowAggregatedPnL] = useState(true);
  const { series } = getChartProps(data, showAggregatedPnL);

  return (
    <Paper variant="widget">
      <CompositionOverTimeChart
        title={title || "Realized PnL"}
        description={`${showAggregatedPnL ? "Aggregated" : "Daily"} ${subtitle || "realized profits or losses by traders over time"}`}
        isLoading={isLoading}
        hideTotal
        series={series}
        showLegend={false}
        headerSuffix={
          <CustomSwitch
            onChange={() => setShowAggregatedPnL(!showAggregatedPnL)}
            checked={!showAggregatedPnL}
            checkedLabel="Daily"
            uncheckedLabel="Aggregated"
          />
        }
      />
    </Paper>
  );
};
