import { Box, Paper } from "@frontend/ui";
import { useParams } from "react-router-dom";
import { TopWallets } from "src/pages/ccar-lending-page/components/top-wallets";
import { Loader } from "@frontend/ui/loader";
import { useClientConfig } from "src/pages/ccar-lending-page/clients-config";
import EventsTable from "src/pages/ccar-lending-page/components/events-table/events-table";
import { Cards } from "./components";
import { useMarketsContext } from "../../context/markets-context";
import { MarketHistoryCharts } from "../../components/market-history-charts";

const MarketOverview = () => {
  const { marketId } = useParams();
  const { markets } = useMarketsContext();
  const { markets: marketsConfig } = useClientConfig();
  const { overview } = marketsConfig || {};

  const market = markets.find((m) => m.id === marketId);

  if (!market) return <Loader />;

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      <Cards />
      <Paper variant="card">
        <Box display="grid" gap={3}>
          <MarketHistoryCharts
            key={marketId}
            marketId={marketId}
            showCollateralChart={overview?.showCollateralChart}
            showTimeSpanPicker
          />
        </Box>
      </Paper>
      <Paper variant="card">
        <Box display="grid" gap={3}>
          <TopWallets
            key={marketId}
            marketId={marketId}
            hideTopBorrowers={overview?.topBorrowers?.hideTopBorrowers}
            hideTopSuppliers={overview?.topBorrowers?.hideTopSuppliers}
          />
        </Box>
      </Paper>
      {overview?.showEventsTable && <EventsTable eventsQuery={{ marketId }} />}
    </Box>
  );
};

export default MarketOverview;
