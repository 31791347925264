import { Box, CustomIcon, FilterPicker, TableAppliedFilters, TableFilter, Button } from "@frontend/ui";
import { wrappedSymbolToIconSymbol } from "@frontend/ui/utils/icon-helper";
import { useMemo, useRef, useState } from "react";
import { useClientConfig } from "src/pages/ccar-lending-page/clients-config";
import { TimeSpanPicker } from "src/pages/ccar-lending-page/components/time-span-picker";
import { MarketData, useMarketAssetsBreakdownQuery } from "../../../generated";
import { DeltaTimeType, LiquidationsTimeSpans, timeSpans } from "../types";
import { LiquidationsNotification } from "./liquidations-notification";
import { useMarketsContext } from "../../markets-new/context/markets-context";

type DataCut = {
  daysAgo: DeltaTimeType;
  marketIds: string[] | null;
  assets: string[] | null;
};

type Props = {
  dataCut: DataCut;
  setDataCut: (newCut: DataCut) => void;
  selectedTimeSpan: LiquidationsTimeSpans;
  setSelectedTimeSpan: (timeSpan: LiquidationsTimeSpans) => void;
};

const getInitialFilters = (isMultimarket: boolean, markets: MarketData[], assets: string[]) => {
  const filters: FilterPicker[] = [];

  if (isMultimarket) {
    filters.push({
      type: "options",
      options: markets.map((o) => ({
        name: o.name,
        value: o.id,
        cryptoIcon: o.symbol ? o.symbol.toLowerCase() : undefined,
        isHidden: false,
      })),
      fieldName: "Markets",
      fieldIndex: 5,
      isApplied: false,
    });
  }

  filters.push({
    type: "options",
    options: assets.map((o) => ({
      name: o,
      cryptoIcon: wrappedSymbolToIconSymbol(o),
      isHidden: true,
      isFixed: false,
    })),
    fieldName: "Assets",
    fieldIndex: 6,
    isApplied: false,
    isAutocomplete: true,
  });

  return filters;
};

const LiquidationsDataCutHeader = ({
  dataCut: { daysAgo, marketIds, assets },
  setDataCut,
  selectedTimeSpan,
  setSelectedTimeSpan,
}: Props) => {
  const filterButtonRef = useRef<null | HTMLButtonElement>(null);
  const { isMultiMarket } = useClientConfig();
  const { data: assetsResponse } = useMarketAssetsBreakdownQuery({
    variables: {
      marketId: null,
      chain: null,
      tokenSymbol: null,
    },
  });
  const { markets: clientMarkets, isLoading: isLoadingMarkets } = useMarketsContext();
  const clientAssetsData = assetsResponse?.assetsBreakdown;
  const clientAssets = useMemo(
    () => (clientAssetsData ? Array.from(new Set(clientAssetsData.map((a) => a.asset))) : []),
    [clientAssetsData],
  );

  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [marketIdsFilter, setMarketIdsFilter] = useState(marketIds);
  const [assetFilter, setAssetFilter] = useState(assets);
  const filters: FilterPicker[] | undefined = useMemo(() => {
    if (!clientAssets.length) {
      return undefined;
    }
    if (isMultiMarket && !clientMarkets.length) {
      return undefined;
    }
    return getInitialFilters(isMultiMarket, clientMarkets, clientAssets);
  }, [isMultiMarket, clientMarkets, clientAssets]);

  const appliedFilters = filters?.map((f) => {
    const options = f.options.map((o) => ({
      ...o,
      isHidden:
        (f.fieldName === "Markets" && o.value && !marketIdsFilter?.includes(o.value)) ||
        (f.fieldName === "Assets" && !assetFilter?.includes(o.name)),
    }));

    return {
      ...f,
      isApplied: !!options.find(
        (o) =>
          (f.fieldName === "Markets" && marketIdsFilter?.length && o.isHidden) ||
          (f.fieldName === "Assets" && assetFilter?.length && o.isHidden),
      ),
      options,
    };
  });

  return (
    <Box
      width="100%"
      display="flex"
      alignItems="center"
      flexWrap="wrap"
      flexDirection={["column", "row"]}
      justifyContent="space-between"
      gap={[2, 3]}
    >
      <Box display="flex" alignItems="center" flexWrap="wrap" gap={3}>
        <Button
          color="secondary"
          ref={filterButtonRef}
          onClick={() => setIsFilterOpen(!isFilterOpen)}
          aria-label="Add Filter"
          data-testid="toggle-filter"
          startIcon={<CustomIcon icon="filter" />}
          disabled={isLoadingMarkets}
        >
          Filter
        </Button>
        {!!appliedFilters?.filter((f) => f.isApplied).length && (
          <TableAppliedFilters
            filters={appliedFilters}
            removeFilter={(i) => {
              if (filters?.[i].fieldName === "Markets") {
                setMarketIdsFilter(clientMarkets.map((m) => m.id));
                setDataCut({ daysAgo, marketIds: null, assets });
              } else if (filters?.[i].fieldName === "Assets") {
                setAssetFilter(clientAssets);
                setDataCut({ daysAgo, marketIds, assets: null });
              }
            }}
            headers={appliedFilters.map((f) => ({ renderType: "TEXT", text: f.fieldName }))}
          />
        )}
        <Box sx={{ order: [-1, 0] }}>
          <LiquidationsNotification />
        </Box>
      </Box>
      {filters && (
        <TableFilter
          isOpen={isFilterOpen}
          anchorEl={filterButtonRef.current}
          filters={filters}
          onChange={(filterValues) => {
            let newMarketIdsFilter = marketIdsFilter;
            let newAssetFilters = assetFilter;
            Object.values(filterValues).forEach((f) => {
              if (f.fieldName === "Markets") {
                newMarketIdsFilter = (f as FilterPicker).options
                  .filter((o) => !o.isHidden)
                  .map((o) => o.value || "")
                  .filter((v) => v.length);
                setMarketIdsFilter(newMarketIdsFilter);
              } else if (f.fieldName === "Assets") {
                const selectedAssets = (f as FilterPicker).options.filter((o) => !o.isHidden).map((o) => o.name);
                newAssetFilters = selectedAssets.length > 0 ? selectedAssets : null;
                setAssetFilter(newAssetFilters);
              }
            });
            setDataCut({ daysAgo, marketIds: newMarketIdsFilter, assets: newAssetFilters });
          }}
          close={() => setIsFilterOpen(false)}
        />
      )}
      <Box width={["100%", "auto"]}>
        <TimeSpanPicker<LiquidationsTimeSpans>
          selectedTimeSpan={selectedTimeSpan}
          onSelectTimeSpan={setSelectedTimeSpan}
          timeSpanOptions={timeSpans}
        />
      </Box>
    </Box>
  );
};

export default LiquidationsDataCutHeader;
