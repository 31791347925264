import React, { useMemo, useRef, useState, useEffect, useCallback, memo, ReactChild } from "react";
import { Header, RenderData, RowData } from "@frontend/types";
import { BoxProps, Theme, useMediaQuery } from "@mui/material";
import { usePagination, SortChangeHandler } from "../hooks/use-pagination";
import { downloadCsv } from "../utils/csv";
import { debounce } from "../utils/debounce";
import { Box } from "../box";
import { Tooltip } from "../tooltip";
import { Typography } from "../typography";
import { Button } from "../button";
import { TableSortLabel } from "../table-sort-label";
import { CustomIcon } from "../custom-icon";
import { KeyValueModal, ModalWrapper } from "../modals";
import { TableFilterButton } from "./table-filter-button";
import TableRow, { ICONS_CELL_MIN_WIDTH } from "./table-row";
import { Pagination } from "../pagination";
import { FilterBar } from "../filter-bar";
import { Loader } from "../loader";
import { ShimmerLoader } from "../shimmer-loader";
import { Paper } from "../paper";
import { EmptyState, EmptyStateProps } from "../empty-state";
import { CryptoIcon } from "../crypto-icon";

interface TableProps {
  customFilter?: ReactChild;
  data: Array<RenderData[]>;
  metadata?: string;
  description?: string | React.ReactNode;
  emptyState?: EmptyStateProps;
  getRowImageUrl?: (rowId: number) => string | Promise<string>;
  headers: Array<Header>;
  headerSuffixComponent?: React.ReactNode;
  initialSortBy?: number;
  isAppliedFiltersHidden?: boolean;
  isFilterHidden?: boolean;
  isTableControlsHidden?: boolean;
  isFullHeight?: boolean;
  isInitialSortDesc?: boolean;
  isInitialSortEnable?: boolean;
  isLoading?: boolean;
  isSettingsHidden?: boolean;
  isWrapped?: boolean;
  minWidth?: number;
  onPageChange?: (pageNumber: number) => void;
  onSearch?: (text: string) => void;
  onSortChange?: SortChangeHandler;
  pageSize?: number;
  resetPagination?: string;
  resetSortBy?: string;
  rowHeight?: number;
  rowHref?: (rowId: number) => string;
  rowHrefTarget?: "_blank" | "_self";
  searchBy?: number[];
  serchbarPlaceholder?: string;
  showRowChevron?: boolean;
  showSearch?: boolean;
  tableHeight?: number;
  title?: string;
  titleIcon?: string;
  titleSuffixComponent?: React.ReactNode;
  expandContent?: (rowId: number) => React.ReactNode;
  footerContent?: React.ReactNode;
  defaultSearch?: string;
  onExport?: () => void;
  tooltip?: string;
  onRowClick?: (rowId: number) => void;
}

const TableHeaderWithIcon = (title: string, titleIcon: string) => (
  <Box display="flex" alignItems="center" flexDirection="row">
    <Typography variant="h2">{title}</Typography>
    <Box ml={1} mt={0.5}>
      <CryptoIcon sx={{ height: 30, width: 30 }} icon={titleIcon} />
    </Box>
  </Box>
);

const EMPTY_STATE_HEIGHT = 180;

const Table = ({
  customFilter,
  data,
  metadata,
  description,
  emptyState = { icon: "warning", title: data ? "No data to show" : "Failed to load data" },
  getRowImageUrl,
  headers,
  headerSuffixComponent,
  initialSortBy = -1,
  isAppliedFiltersHidden = false,
  isFilterHidden,
  isTableControlsHidden = false,
  isFullHeight,
  isInitialSortDesc = false,
  isInitialSortEnable = true,
  isLoading,
  isSettingsHidden,
  isWrapped = true,
  minWidth,
  onPageChange,
  onSearch,
  onSortChange,
  pageSize = 50,
  resetPagination,
  resetSortBy,
  rowHeight = 72,
  rowHref,
  rowHrefTarget,
  searchBy,
  serchbarPlaceholder,
  showRowChevron = false,
  showSearch = false,
  tableHeight = 600,
  title,
  titleIcon,
  titleSuffixComponent,
  expandContent,
  footerContent,
  defaultSearch,
  onExport,
  tooltip,
  onRowClick,
}: TableProps) => {
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const rowsData: RowData[] = useMemo(
    () => data.map((renderData: RenderData[], idx: number) => ({ renderData, id: idx })),
    [data],
  );
  const [expandedIdx, setExpandedIdx] = useState<number | null>(null);

  const [searchInputValue, setSearchInputValue] = useState<string | undefined>(defaultSearch);
  const debouncedSearch = useMemo(
    () => debounce((val?: string) => val !== undefined && onSearch?.(val), 200),
    [onSearch],
  );
  const minimumWidth = minWidth !== undefined ? minWidth : headers.length * 125;
  const [extraData, setExtraData] = useState<{ data?: Record<string, string>[]; title?: string }>();
  const [sortBy, setSortBy] = useState<number>(initialSortBy);
  const [isDesc, setIsDesc] = useState(isInitialSortDesc);
  const [isSortEnable, setIsSortEnable] = useState(isInitialSortEnable);
  const [filteredData, setFilteredData] = useState(rowsData);

  const node = useRef<HTMLDivElement>();
  useEffect(() => setFilteredData(rowsData), [rowsData]);
  const sortedData = useMemo(() => {
    if (isSortEnable === false || sortBy < 0) {
      return filteredData;
    }

    return filteredData
      .filter(({ renderData: row }) =>
        row
          .reduce((acc: string[], { searchTerm, text }, idx) => {
            if (searchBy && !searchBy.includes(idx)) return acc;
            return [...acc, searchTerm || text];
          }, [])
          .join("")
          .toLowerCase()
          .includes(onSearch ? "" : searchInputValue?.toLowerCase() || ""),
      )
      .slice()
      .sort(({ renderData: a }, { renderData: b }) => {
        if (!onSortChange) {
          let aValue;
          let bValue;

          if (a[sortBy].extraData?.data) {
            aValue = a[sortBy].extraData?.data?.length || 0;
            bValue = b[sortBy].extraData?.data?.length || 0;
          } else {
            aValue = a[sortBy].value !== undefined ? a[sortBy].value! : a[sortBy].text.toString();
            bValue = b[sortBy].value !== undefined ? b[sortBy].value! : b[sortBy].text.toString();
          }

          if (aValue > bValue) {
            return isDesc ? -1 : 1;
          }

          return isDesc ? 1 : -1;
        }

        return 1;
      });
  }, [isSortEnable, sortBy, filteredData, onSearch, searchInputValue, searchBy, onSortChange, isDesc]);

  const { currentPage, dataForPage, pageCount, setCurrentPage } = usePagination<RowData>(
    sortedData,
    sortedData.length,
    pageSize,
    resetPagination,
  );

  const setPage = useCallback(
    (page: number) => {
      setCurrentPage(page);
      if (onPageChange) {
        onPageChange(page);
      }
    },
    [setCurrentPage, onPageChange],
  );

  useEffect(() => {
    if (resetSortBy) {
      setSortBy(initialSortBy);
    }
  }, [initialSortBy, resetSortBy]);

  /**
   * There's a slight delay between the time this
   * component receives new `data` and when it sets the
   * initial `dataForPage`.
   *
   * If the table is loading data from the BE, there's a weird
   * UI flash when this delay occurs.
   *
   * As a safeguard i'm setting a short timeout to allow the initial
   * dataForPage to be set before showing or not the <EmptyState />
   */
  const [didInit, setDidInit] = useState(false);
  useEffect(() => {
    if (didInit) {
      return;
    }

    if (isLoading === undefined) {
      setDidInit(true);
    }
    if (isLoading === false) {
      setTimeout(() => setDidInit(true), 100);
    }
  }, [isLoading, didInit]);

  /* eslint-disable no-nested-ternary */
  const hasIcon = titleIcon && titleIcon.length > 0;
  const exportToCsv = () => {
    if (onExport) {
      onExport();
    } else {
      downloadCsv(
        headers.map((header) => header.text.toString()),
        sortedData.map(({ renderData: row }) =>
          row.map((cell) =>
            (cell.exportText !== undefined && cell.exportText !== null
              ? cell.exportText
              : cell.value !== undefined && cell.value !== null
                ? cell.value
                : cell.icons !== undefined && cell.icons !== null
                  ? `"${String(cell.icons)}"`
                  : cell.text
            ).toString(),
          ),
        ),
        metadata ? `${title || ""} ${metadata || ""}` : title,
      );
    }
  };

  const defaultSortIcon = useCallback(() => <CustomIcon icon="sort-none" />, []);

  const activeSortIcon = useCallback(
    () => <CustomIcon icon="sort-down" sx={{ transform: !isDesc ? "rotateX(180deg)" : "  " }} />,
    [isDesc],
  );

  const getSortedIcon = (index: number) => {
    if (sortBy !== index) {
      return defaultSortIcon;
    }

    return activeSortIcon;
  };

  useEffect(() => {
    setCurrentPage(1);
    debouncedSearch(searchInputValue);
  }, [searchInputValue, setCurrentPage, debouncedSearch]);

  const headersForRender = headers.filter((header) => header.renderType !== "FILTER");
  const showTableHeader = !!(
    title ||
    description ||
    customFilter ||
    !isFilterHidden ||
    !isSettingsHidden ||
    headerSuffixComponent ||
    showSearch
  );

  return (
    <Paper
      variant="card"
      sx={{
        p: isWrapped ? undefined : 0,
        ...(isFullHeight
          ? {
              height: "100%",
              display: "flex",
              flexDirection: "column",
              overflow: "hidden",
            }
          : {}),
      }}
    >
      {showTableHeader && (
        <Box
          className="table-header"
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 2,
            mb: 2,
            flexWrap: "wrap",
          }}
        >
          <Box className="table-title">
            {title && (
              <Box display="flex" alignItems="center" data-testid="table-title">
                <Box display="flex" flex="1" gap={3} alignItems="center">
                  {hasIcon ? TableHeaderWithIcon(title, titleIcon) : <Typography variant="h2">{title}</Typography>}
                  {titleSuffixComponent}
                  {tooltip && (
                    <Tooltip title={tooltip} placement="right-end">
                      <CustomIcon icon="info" sx={{ svg: { width: 20, height: 20 } }} />
                    </Tooltip>
                  )}
                </Box>
              </Box>
            )}
            {typeof description === "string" ? <Typography>{description}</Typography> : description}
          </Box>
          {!isTableControlsHidden && (
            <Box
              className="table-controls"
              sx={{
                display: "flex",
                alignItems: "center",
                flexGrow: { xs: 1, lg: 0 },
                gap: 2,
                ml: { xs: 0, lg: "auto" },
                flexWrap: "wrap",
              }}
            >
              {headerSuffixComponent}
              {customFilter && (
                <Box display="flex" alignItems="center">
                  {customFilter}
                </Box>
              )}
              {!isFilterHidden && (
                <TableFilterButton
                  key={rowsData.length}
                  isLoading={isLoading}
                  headers={headers}
                  data={rowsData}
                  onChange={setFilteredData}
                  setTablePage={setCurrentPage}
                  showAppliedFilters={!isAppliedFiltersHidden}
                />
              )}
              {!isSettingsHidden && (
                <Button color="secondary" onClick={exportToCsv} disabled={!sortedData.length}>
                  Export to CSV
                </Button>
              )}
              {showSearch && (
                <FilterBar
                  sx={{
                    flexBasis: 440,
                    flexSrink: 1,
                  }}
                  fullWidth={isMobile}
                  value={searchInputValue || ""}
                  onChange={setSearchInputValue}
                  placeholder={serchbarPlaceholder || "Search"}
                />
              )}
            </Box>
          )}
        </Box>
      )}
      {dataForPage ? (
        <Box
          borderRadius={2}
          sx={
            isFullHeight
              ? {
                  flex: 1,
                  height: 0,
                  display: "flex",
                  flexDirection: "column",
                  overflowX: "auto",
                }
              : { overflow: "auto" }
          }
          data-testid="table"
        >
          <Box
            display="flex"
            flexDirection="row"
            minWidth={minimumWidth}
            borderBottom="1px solid"
            borderColor="darkGrey.main"
            bgcolor="darkGrey.main"
            py={1}
          >
            {getRowImageUrl && <Box width={0} flexBasis={0} flexGrow={1} flexShrink={1} />}
            {expandContent && <Box width="58px" />}
            {headersForRender.map((item) => {
              const i = headers.indexOf(item);

              const defaultMinWidth = item.renderType !== "ICONS" ? 0 : ICONS_CELL_MIN_WIDTH;
              const boxMinWidth = item.minWidth ? (`${item.minWidth}px` as BoxProps["minWidth"]) : defaultMinWidth;

              return (
                <Box
                  key={i}
                  overflow="hidden"
                  minWidth={boxMinWidth}
                  flexBasis={(item.width as string) || `${100 / headersForRender.length}%`}
                  flexGrow={1}
                  flexShrink={1}
                  className="table-header"
                  data-testid={`table-header-${i}`}
                  marginLeft={3}
                >
                  {item.renderType !== "EMPTY" && (
                    <TableSortLabel
                      IconComponent={getSortedIcon(i)}
                      onClick={() => {
                        if (!item.nonSortable) {
                          setIsSortEnable(() => true);
                          const nextIsDesc = sortBy === i ? !isDesc : true;
                          const nextSortBy = sortBy === i ? sortBy : headers.indexOf(item);
                          setIsDesc(nextIsDesc);
                          setSortBy(nextSortBy);
                          const sortField = headers[nextSortBy]?.field;
                          if (onSortChange && sortField) {
                            setCurrentPage(1);
                            const sortOpposite = headers[nextSortBy]?.sortOpposite;
                            const sortOrder = nextIsDesc ? -1 : 1;
                            const oppositeSortOrder = nextIsDesc ? 1 : -1;
                            onSortChange(sortField, sortOpposite ? oppositeSortOrder : sortOrder);
                          }
                        }
                      }}
                      sx={{
                        svg: {
                          margin: 0,
                          flex: "0 0 24px",
                          display: item.nonSortable ? "none" : undefined,
                          width: "24px",
                        },
                        width: "100%",
                        cursor: item.nonSortable ? "default" : "pointer",
                      }}
                    >
                      {item.icon && <CustomIcon icon={item.icon} sx={{ mr: 1 }} />}
                      {item.tooltip ? (
                        <Tooltip title={item.tooltip} arrow>
                          <Typography color="light.main" fontSize={14} style={{ whiteSpace: "pre-line" }}>
                            {item?.text}
                          </Typography>
                        </Tooltip>
                      ) : (
                        <Typography color="light.main" fontSize={14} style={{ whiteSpace: "pre-line" }}>
                          {item?.text}
                        </Typography>
                      )}
                    </TableSortLabel>
                  )}
                </Box>
              );
            })}
            {expandContent && <Box width="16px" />}
            {showRowChevron && <Box width="50px" />}
          </Box>
          <Box
            className="table-wrapper"
            ref={node}
            sx={{
              minWidth: minimumWidth,
              overflow: "hidden",
            }}
            borderRadius="8px 8px 0 0"
            bgcolor="black.main"
          >
            <Box
              flex={isFullHeight ? 1 : undefined}
              height={
                isFullHeight
                  ? "100%"
                  : Math.min(dataForPage.length * rowHeight, tableHeight) +
                    /* dataForPage.length - 1 is the total rows margin bottom */
                    (dataForPage.length - 1)
              }
              overflow="auto"
              position="relative"
            >
              {isLoading && (
                <Box width="100%" display="flex" position="absolute" flexDirection="column">
                  {dataForPage.length ? (
                    dataForPage.map((_, i) => (
                      <ShimmerLoader key={i} sx={{ height: rowHeight + 1 }} testId={`table-row-shimmer-${i}`} />
                    ))
                  ) : (
                    <ShimmerLoader sx={{ height: EMPTY_STATE_HEIGHT }} />
                  )}
                </Box>
              )}
              {dataForPage.length ? (
                dataForPage.map(({ renderData: row, id }) => (
                  <TableRow
                    key={id}
                    data-test-id="table-row"
                    rowIndex={id}
                    data={row}
                    headers={headersForRender}
                    setExtraData={setExtraData}
                    fixedRowHeight={rowHeight}
                    getRowImageUrl={getRowImageUrl}
                    showRowChevron={showRowChevron}
                    isLast={id === dataForPage.length - 1}
                    href={rowHref ? rowHref(id) : undefined}
                    hrefTarget={rowHrefTarget}
                    expandRow={expandContent ? { content: expandContent(id), expandedIdx, setExpandedIdx } : undefined}
                    onRowClick={onRowClick}
                  />
                ))
              ) : (
                <EmptyState {...(isLoading || !didInit ? { height: rowHeight } : emptyState)} />
              )}
            </Box>
          </Box>
          {pageCount > 1 && (
            <Box
              sx={{
                paddingY: 1.5,
                paddingX: 2,
                borderTop: "1px solid",
                borderColor: "darkGrey.main",
                bgcolor: "black.main",
                position: "sticky",
                bottom: 0,
                left: 0,
                zIndex: 1,
              }}
            >
              <Pagination count={pageCount} page={currentPage} onChange={(_, page) => setPage(page)} />
            </Box>
          )}
          {footerContent && <Box mt={2}>{footerContent}</Box>}
        </Box>
      ) : (
        <Loader />
      )}
      <ModalWrapper open={!!extraData} onClose={() => setExtraData(undefined)} maxWidth={800}>
        <KeyValueModal
          title={extraData?.title || "Data"}
          onClose={() => setExtraData(undefined)}
          json={extraData?.data || []}
        />
      </ModalWrapper>
    </Paper>
  );
};

export const ChaosTable = memo(Table);
