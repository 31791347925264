import { Box, Grid, Paper } from "@frontend/ui";
import { OrderBookDepth } from "@frontend/ui/order-book-depth";
import { useParams } from "react-router-dom";
import { HistoricalPriceChart } from "src/pages/ccar-lending-page/tabs/markets/components";
import {
  useFeesDistributionHistoryQuery,
  useMarketHistoryQuery,
  useMarketsQuery,
  useMarketStatsQuery,
  useOrderBookQuery,
  usePriceImpactDistributionQuery,
} from "../../generated";
import { useClientConfig } from "../../clients-config";
import { MarketStatsCards } from "../../components";
import { InterestImbalanceChart } from "../overview/components";
import { OpenInterestDistributionChart } from "./components/open-interest-distribution-chart";
import { OrderBookLiquidity } from "./components/order-book-liquidity";
import { PositionsTable } from "./components/positions-table";
import { RealizedPnLChart } from "./components/realized-pnl-chart";
import { FeesDistributionChart } from "./components/fees-distribution-chart";
import { AverageLeverageChart } from "./components/average-leverage-chart";
import { AveragePositionSizeChart } from "./average-position-size-chart";
import { PriceImpactDistributionChart } from "./components/price-impact-distribution-chart";
import { useFeatureFlag } from "../../../../utils/feature-flags";

const useMarketDetails = (marketId: string) => {
  const { data, loading } = useMarketsQuery({
    fetchPolicy: "cache-first",
  });

  const market = data?.markets.find((m) => m.id.toLowerCase() === marketId.toLowerCase());

  return {
    market,
    loading,
  };
};

export const MarketDetails = () => {
  const { network, markets, combineOpenInterest } = useClientConfig();
  const params = useParams<"market" | "marketId">();
  const marketId = params.marketId || params.market || "";

  const { market } = useMarketDetails(marketId);
  const { data: stats, loading: loadingStats } = useMarketStatsQuery({
    fetchPolicy: "network-only",
    variables: {
      marketId,
      fetchPriceFromCoingecko: markets?.priceCardFromCoingecko || null,
    },
  });
  const { data: history, loading: loadingHistory } = useMarketHistoryQuery({
    fetchPolicy: "network-only",
    variables: {
      chain: network.name,
      marketId,
    },
  });
  const { data: orderBook, loading: loadingOrderBook } = useOrderBookQuery({
    variables: { tokenSymbol: marketId },
    skip: !markets?.charts.includes("order-book"),
  });
  const { data: feesDistribution, loading: loadingFeesDistribution } = useFeesDistributionHistoryQuery({
    variables: { marketId },
    skip: !markets?.hasFeesDistribution,
  });

  const { data: priceImpactDistribution, loading: loadingPriceImpactDistribution } = usePriceImpactDistributionQuery({
    variables: { marketId },
    skip: !markets?.priceImpactDistribution,
  });

  const currentStats = stats?.marketStats.current;
  const previousStats = stats?.marketStats.previous;
  const cryptoCurrency =
    orderBook?.orderBook[0].quote_asset !== "USD" ? orderBook?.orderBook[0].quote_asset : undefined;

  return (
    <Box display="flex" flexWrap="nowrap" flexDirection="column" height="100%" width="100%" gap={[1, 2, 3]}>
      <MarketStatsCards
        isLoading={loadingStats}
        marketStats={{
          current: currentStats,
          previous: previousStats,
        }}
      />
      {markets?.charts.includes("assetPrice") && (
        <HistoricalPriceChart isLoading={loadingHistory} assetHistoricalPrices={history?.assetDataOverTime.prices[0]} />
      )}
      <Grid container spacing={[1, 2, 3]}>
        <Grid item xs={12}>
          <Paper variant="card">
            <OpenInterestDistributionChart
              isLoading={loadingHistory}
              short={history?.assetDataOverTime.openInterestDistribution.short}
              long={history?.assetDataOverTime.openInterestDistribution.long}
              combineOpenInterest={combineOpenInterest}
            />
          </Paper>
        </Grid>
        {!orderBook && (
          <Grid item xs={12}>
            <Paper variant="card">
              <InterestImbalanceChart
                data={history?.assetDataOverTime.openInterestDistribution.long.map(({ time, value }, i) => {
                  const interestDiff = Math.abs(
                    value - history.assetDataOverTime.openInterestDistribution.short[i].value,
                  );
                  const totalInterest = value + history.assetDataOverTime.openInterestDistribution.short[i].value;
                  return {
                    time,
                    value: totalInterest === 0 ? 0 : interestDiff / totalInterest,
                  };
                })}
                loading={loadingHistory}
              />
            </Paper>
          </Grid>
        )}
        {markets?.hasFeesDistribution && (
          <Grid item xs={12}>
            <Paper variant="card">
              <FeesDistributionChart
                loading={loadingFeesDistribution}
                data={feesDistribution?.feesDistributionHistory}
              />
            </Paper>
          </Grid>
        )}
        {markets?.charts.includes("averageLeverage") && (
          <Grid item xs={12} md={6}>
            <Paper variant="card">
              <AverageLeverageChart
                loading={loadingHistory}
                longLeverage={history?.assetDataOverTime.averageLongLeverage}
                shortLeverage={history?.assetDataOverTime.averageShortLeverage}
              />
            </Paper>
          </Grid>
        )}
        {markets?.charts.includes("averagePositionSize") && (
          <Grid item xs={12} md={markets?.charts.includes("averageLeverage") ? 6 : 12}>
            <Paper variant="card">
              <AveragePositionSizeChart
                loading={loadingHistory}
                longSize={history?.assetDataOverTime.averageLongPositionSize}
                shortSize={history?.assetDataOverTime.averageShortPositionSize}
              />
            </Paper>
          </Grid>
        )}
        {markets?.charts.includes("netAndGrossRealizedPnl") ? (
          <Grid item xs={12}>
            <Paper variant="card">
              <RealizedPnLChart
                isLoading={loadingHistory}
                data={history?.assetDataOverTime.netRealizedPnL || []}
                title="Net Realized PnL"
                subtitle="net realized profits or losses over time from traders’ executed positions, including fees"
              />
            </Paper>
            <Paper variant="card">
              <RealizedPnLChart
                isLoading={loadingHistory}
                data={history?.assetDataOverTime.grossRealizedPnL || []}
                title="Gross Realized PnL"
                subtitle="gross realized profits or losses over time from traders’ executed positions, excluding fees"
              />
            </Paper>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Paper variant="card">
              <RealizedPnLChart isLoading={loadingHistory} data={history?.assetDataOverTime.realizedPnL} />
            </Paper>
          </Grid>
        )}
      </Grid>
      {markets?.priceImpactDistribution && (
        <PriceImpactDistributionChart
          loading={loadingPriceImpactDistribution}
          marketPriceImpactDistribution={priceImpactDistribution?.priceImpactDistribution}
        />
      )}
      {orderBook && (
        <>
          <Paper variant="card">
            <OrderBookDepth
              tokenSymbol={market?.name || marketId}
              orderBook={orderBook.orderBook}
              loading={loadingOrderBook}
              cryptoCurrency={cryptoCurrency}
            />
          </Paper>
          <OrderBookLiquidity
            data={orderBook.orderBookSpreads}
            isLoading={loadingOrderBook}
            cryptoCurrency={cryptoCurrency}
          />
        </>
      )}
      <PositionsTable market={market} />
    </Box>
  );
};
