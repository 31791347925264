import type { EChartsOption } from "echarts";
import merge from "lodash/merge";
import { ReactNode } from "react";
import { Box } from "../../../box";
import { Typography } from "../../../typography";
import ECharts from "../../echarts";
import { ShimmerLoader } from "../../../shimmer-loader";
import { StringChartProps } from "../../types";
import { getStackedBarOptions } from "./get-stacked-bar-chart-option";
import { IconButton } from "../../../icon-button";
import { CustomIcon } from "../../../custom-icon";
import { Tooltip } from "../../../tooltip";
import { downloadCsv } from "../../../utils/csv";
import { transformToCSV } from "../export-utils";

type Props = {
  title: string;
  description?: string | ReactNode;
  headerSuffix?: ReactNode;
  chartHeight?: number;
  emptyState?: boolean;
  onClick?: (xValue: number) => void;
  option?: EChartsOption;
  hideHeader?: boolean;
} & StringChartProps;

export const StackedBarChart = ({
  title,
  description,
  headerSuffix,
  chartHeight = 420,
  emptyState,
  isLoading,
  onClick,
  option = {},
  hideHeader,
  ...restProps
}: Props) => {
  const exportToCsv = () => {
    downloadCsv(
      [restProps.xAxisLabel || title, ...restProps.series.map((serie) => serie.label || "")],
      transformToCSV(restProps.series),
      title || "chart_data",
    );
  };
  return (
    <Box>
      {!hideHeader && (
        <Box display="flex" alignItems="flex-start" justifyContent="space-between" mb={2}>
          <Box flex="1">
            {title && <Typography variant="h4">{title}</Typography>}
            {typeof description === "string" ? <Typography>{description}</Typography> : description}
          </Box>
          {headerSuffix}
          <IconButton size="small" onClick={exportToCsv} sx={{ ml: 2 }}>
            <Tooltip title="Export to CSV">
              <CustomIcon icon="export" sx={{ svg: { width: 24, height: 24 } }} />
            </Tooltip>
          </IconButton>
        </Box>
      )}
      <Box height={chartHeight}>
        {isLoading ? (
          <ShimmerLoader sx={{ borderRadius: 1 }} />
        ) : (
          <ECharts emptyState={emptyState} option={merge(getStackedBarOptions(restProps), option)} onClick={onClick} />
        )}
      </Box>
    </Box>
  );
};
