import { Box, CustomReactSelect } from "@frontend/ui";
import { wrappedSymbolToIconSymbol } from "@frontend/ui/utils/icon-helper";
import { useState } from "react";
import { PoolsCards, PoolsTable } from "./components";
import { useClientConfig } from "../../clients-config";
import { Chain, useChainPoolsDataQuery } from "../../generated";

const Pools = () => {
  const { chains } = useClientConfig();
  const [selectedChain, setSelectedChain] = useState<Chain>(chains[0]);

  const { data } = useChainPoolsDataQuery({
    variables: {
      chain: selectedChain,
    },
  });

  const dropdownOptions = chains.map((c) => ({
    value: c,
    label: c,
    cryptoIcon: wrappedSymbolToIconSymbol(c),
  }));

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      <Box maxWidth={280}>
        <CustomReactSelect
          options={dropdownOptions}
          value={{
            value: selectedChain,
            label: selectedChain,
            cryptoIcon: wrappedSymbolToIconSymbol(selectedChain),
          }}
          onChange={(option) => {
            if (!option) return;
            setSelectedChain(option.value as Chain);
          }}
          isSearchable={false}
        />
      </Box>
      <PoolsCards data={data?.chainPoolsData} />
      <PoolsTable chain={selectedChain} />
    </Box>
  );
};

export default Pools;
