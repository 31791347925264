import { PageHeader } from "@frontend/ui/page-header";
import { useParams } from "react-router-dom";
import { RouteParams } from "../../../../../config/route-params";
import { RoutePath } from "../../../../../config/routes";

interface CreditManagerDetailsHeaderProps {
  poolName: string;
  creditManagerName: string;
}

const CreditManagerDetailsHeader = ({ poolName, creditManagerName }: CreditManagerDetailsHeaderProps) => {
  const { clientName, poolId, creditManagerId } = useParams();

  const backLink = RoutePath.Risk.Pool.replace(RouteParams.ClientName, clientName!).replace(
    RouteParams.PoolId,
    poolId!,
  );

  return (
    <PageHeader
      backLink={backLink}
      pageTitle={creditManagerName}
      containerStyle={{ mt: 0, mb: 0 }}
      showBackButton
      extraData={{
        text: creditManagerId!,
        allowCopy: true,
      }}
      breadcrumbsLinks={[
        {
          title: "Pools",
          href: RoutePath.Risk.Pools.replace(RouteParams.ClientName, clientName!),
        },
        {
          title: poolName,
          href: backLink,
        },
      ]}
    />
  );
};

export default CreditManagerDetailsHeader;
