import { Chain, TimeSpan } from "../../generated";
import { CCARClientConfig } from "../types";

export const aaveV3Config: CCARClientConfig = {
  chains: [
    Chain.Arbitrum,
    Chain.Avalanche,
    Chain.Ethereum,
    // Chain.Fantom,
    // Chain.Harmony,
    Chain.Metis,
    Chain.Optimism,
    Chain.Polygon,
    Chain.Base,
    Chain.Gnosis,
    Chain.Bnb,
    Chain.Scroll,
    Chain.ZkSync,
  ],
  timeSpanOptions: [TimeSpan.Day, TimeSpan.Month, TimeSpan.Quarter, TimeSpan.Year],
  riskThreshold: 1.2,
  eligibleThreshold: 1,
  isMultichain: true,
  isMultiMarket: true,
  hasEMode: true,
  marketType: "GeneralMarket",
  endpointsPrefix: "aavev3",
  overview: {
    cards: [
      "totalSupplyUsd",
      "totalBorrowUsd",
      "totalValueLockedUsd",
      "totalValueAvailableForLiquidationUsd",
      "totalValueAtRiskUsd",
      "numberOfWalletsAtRisk",
      "numberOfWalletsAvailableForLiquidation",
      "totalPotentialBadDebtUsd",
    ],
    charts: ["historyStatsTvl", "chainHistoryStats"],
    chainTableConfig: {
      columns: [
        "chain",
        "totalSupplyUsd",
        "totalBorrowUsd",
        "totalValueLockedUsd",
        "totalValueAtRiskUsd",
        "activeUsers",
      ],
    },
  },
  wallets: {},
  walletDetails: {
    hideTimeMachine: true,
    tables: ["totalSupplyAndBorrow", "recentEvents"],
  },
  marketDetails: {
    tables: ["wallets", "assetEvents"],
    charts: [
      // 'priceImpact',
      "historicalPrices",
      "collateralAtRisk",
      "supplyWalletsHealth",
      "borrowWalletsHealth",
      "marketsDistribution",
      "topWallets",
    ],
    liquidationThresholdLabel: "Loan to Value",
  },
  assetDetails: {
    tables: [],
    charts: [],
  },
  liquidations: {
    showLiquidationPie: false,
    showLiquidationEvents: true,
  },
  riskExplorer: {
    liquidationThresholdLabel: "Liquidation Threshold",
    showAdvancedSettings: true,
  },
  alerts: {
    enabled: true,
    notificationsChannels: [
      {
        channel: "telegram",
        link: "https://t.me/+lQsW50s5IgJkZmQx",
      },
    ],
  },
  markets: {
    overview: {
      showEventsTable: true,
    },
  },
  stableCoin: {
    asset: "GHO",
    chain: Chain.Ethereum,
    marketId: "Ethereum-main",
    hasPools: true,
    hasCrossChain: true,
    riskTab: { enabled: true, showApyHistory: true },
    beta: false,
    cards: ["price", "marketCap", "volume", "totalBorrow"],
    charts: [
      "facilitators",
      "collateralComposition",
      "assetPriceLiquidation",
      "marketCap",
      "ghoTotalSupply",
      "topTokenHolders",
      "mintBurn",
    ],
    collateral: { charts: ["assetBreakdown", "assetClassBreakdown", "collateralRatio"] },
  },
};
