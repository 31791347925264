import { RouteParams, RoutePath } from "src/config/routes";
import { useParams } from "react-router-dom";
import { useOverviewStatsChainsQuery } from "../../generated";
import ChainOverviewStatsRowView from "../overview/overview-table";

const Chains = () => {
  const { clientName } = useParams();
  const { loading, data } = useOverviewStatsChainsQuery();

  return (
    <ChainOverviewStatsRowView
      data={data?.overviewStatsChains}
      isLoading={loading}
      onClickChainHref={(chain) =>
        RoutePath.Risk.ChainDetails.replace(RouteParams.ClientName, clientName!).replace(RouteParams.Chain, chain)
      }
    />
  );
};

export default Chains;
