import { Header, RenderData } from "@frontend/types";
import {
  Box,
  ChaosTable,
  CustomMultiReactSelect,
  iconsCell,
  labelsCell,
  MultiOptionType,
  textCell,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@frontend/ui";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { usePageTitle } from "../../../../hooks";
import { CcarClient, mapProtocolToData } from "../alerts/utils";
import { alertSeverityLabels, alertSeverityStatus, alertTypeLabels } from "../../../../utils/consts";
import { AlertLevel } from "./utils";
import { useAlertSettings } from "./use-settings-table-data";

const alertsHeaders: Header[] = [
  {
    renderType: "TEXT",
    text: "Name",
    field: "name",
  },
  {
    renderType: "TEXT",
    text: "Severity",
    field: "severity",
    width: "80px",
  },
  {
    renderType: "TEXT",
    text: "Type",
    field: "type",
  },
  {
    renderType: "TEXT",
    text: "Frequency",
    field: "frequencyInMinutes",
    suffix: "Minutes",
  },
  {
    renderType: "ICONS",
    text: "Protocols",
    nonSortable: true,
    hideFilter: true,
  },
  {
    renderType: "TEXT",
    text: "Status",
    field: "enabled",
  },
];

export const SettingsTab = () => {
  usePageTitle("Settings");
  const {
    alertSettings,
    loading,
    onAlertLevelFilterChange,
    onProtocolsFilterChange,
    onSearch,
    selectedAlertLevel,
    selectedProtocols,
    resetFilters,
  } = useAlertSettings();

  const { state } = useLocation() as { state?: { reset: boolean } };

  useEffect(() => {
    if (state?.reset) {
      resetFilters();
    }
  }, [state, resetFilters]);

  const rows = alertSettings?.map((alertSetting) => {
    const frequencyFactor =
      alertSetting.frequencyInMinutes % 1440 === 0 ? 1440 : alertSetting.frequencyInMinutes % 60 === 0 ? 60 : 1;
    const frequencyUnits = frequencyFactor === 1440 ? "Days" : frequencyFactor === 60 ? "Hours" : "Minutes";

    return [
      textCell(alertSetting.name),
      labelsCell(alertSeverityStatus[alertSetting.severity], alertSeverityLabels[alertSetting.severity]),
      textCell(alertTypeLabels[alertSetting.alertType]),
      textCell(
        `${alertSetting.frequencyInMinutes / frequencyFactor} ${frequencyUnits}`,
        alertSetting.frequencyInMinutes,
      ),
      alertSetting.level === "GLOBAL"
        ? textCell("Global")
        : iconsCell(
            Object.entries(alertSetting.clientsConfig)
              .filter(([, config]) => config.enabled)
              .map(([ccarClient]) => ccarClient),
            "",
            Object.entries(alertSetting.clientsConfig)
              .filter(([, config]) => config.enabled)
              .map(
                ([ccarClient]) =>
                  `${mapProtocolToData[ccarClient as CcarClient]?.name} ${
                    mapProtocolToData[ccarClient as CcarClient]?.version
                  }`,
              )
              .join(" ,"),
            "",
            Object.entries(alertSetting.clientsConfig)
              .filter(([, config]) => config.enabled)
              .reduce(
                (acc, [ccarClient]) => ({
                  ...acc,
                  [ccarClient]: mapProtocolToData[ccarClient as CcarClient]?.icon,
                }),
                {},
              ),
          ),
      labelsCell(alertSetting.enabled ? "OK" : "ERROR", alertSetting.enabled ? "Active" : "Inactive"),
    ] as RenderData[];
  });

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" my={5}>
        <Typography variant="h1">Alert Settings</Typography>
      </Box>
      <ChaosTable
        pageSize={10}
        isFullHeight
        isLoading={loading}
        isSettingsHidden
        title=" "
        titleSuffixComponent={
          <ToggleButtonGroup
            fullWidth
            value={selectedAlertLevel}
            exclusive
            onChange={(_, v) => {
              onAlertLevelFilterChange(v as AlertLevel);
            }}
            size="medium"
          >
            <ToggleButton key="all" value="ALL">
              All Alerts
            </ToggleButton>
            <ToggleButton key="global" value="GLOBAL">
              Global Alerts
            </ToggleButton>
            <ToggleButton key="protocol" value="PROTOCOL">
              Protocol Alerts
            </ToggleButton>
          </ToggleButtonGroup>
        }
        headers={alertsHeaders}
        data={rows}
        emptyState={{
          icon: "warning",
          title: "No Alert Settings Found",
        }}
        rowHref={(rowId) => alertSettings?.[rowId]?.alertId ?? ""}
        showRowChevron
        showSearch
        onSearch={onSearch}
        customFilter={
          selectedAlertLevel !== "GLOBAL" ? (
            <CustomMultiReactSelect
              controlShouldRenderValue
              placeholder="Select Protocol"
              tooltip="Filter by protocol"
              options={Object.entries(mapProtocolToData).map(([value, { name, version, icon }]) => ({
                label: `${name} ${version}`,
                value,
                cryptoIcon: icon,
              }))}
              selectAllLabel="All"
              value={selectedProtocols}
              onChange={(e) => {
                onProtocolsFilterChange(e as MultiOptionType[]);
              }}
              allowSelectAll
              multiValueProps={{
                maxItems: 2,
              }}
            />
          ) : undefined
        }
      />
    </Box>
  );
};
