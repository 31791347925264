import { PieChart } from "@frontend/ui/charts";
import { useState } from "react";
import { Box, CustomIcon, CustomSwitch, IconButton, Paper, Tooltip, Typography } from "@frontend/ui";
import { downloadCsv } from "@frontend/ui/utils/csv";
import { OverviewChartsStats } from "../types";

type Props = {
  data?: OverviewChartsStats;
};

const aggregateDistribution = (
  distribution: {
    name: string;
    value: number;
  }[],
) => {
  const aggregate = [] as { name: string; value: number }[];
  distribution.forEach((asset) => {
    const existing = aggregate.find(({ name }) => name === asset.name);
    if (existing) {
      existing.value += asset.value;
    } else {
      aggregate.push(asset);
    }
  });
  return aggregate;
};
export const CrossChainSupplyBorrowDistribution = ({ data }: Props) => {
  const supplyDistribution = data?.supplyDistribution || [];
  const borrowDistribution = data?.borrowDistribution || [];

  const [type, setType] = useState<"supply" | "borrow">("supply");
  const checked = type === "borrow";
  const nextDistribution = checked ? borrowDistribution : supplyDistribution;
  const aggregated = aggregateDistribution(nextDistribution)
    ?.sort((a, b) => (a.value > b.value ? -1 : 1))
    .map((d) => ({
      x: d.name,
      y: d.value,
    }));

  const toggleDistribution = () => {
    setType(type === "supply" ? "borrow" : "supply");
  };

  const title = `Cross-Chain Exposure by ${type === "supply" ? "Collateral" : "Borrow"} Asset`;

  const exportToCsv = () => {
    downloadCsv(
      ["Asset", "Cross-Chain Exposure (USD)"],
      aggregated.map((item) => [item.x?.toString() ?? "", item.y?.toString() ?? ""]),
      title || "chart_data",
    );
  };

  return (
    <Paper variant="widget">
      <Box display="flex" justifyContent="space-between" flexWrap="wrap" mb={3} gap={3}>
        <Box>
          <Typography variant="h3">{title}</Typography>
          <Typography>Showing aggregated exposure to collateral assets across all markets</Typography>
        </Box>
        <Box flexWrap="wrap">
          <CustomSwitch
            uncheckedLabel="Collateral Assets"
            checkedLabel="Borrow Assets"
            onChange={toggleDistribution}
            checked={checked}
          />
          <IconButton size="small" onClick={exportToCsv} sx={{ ml: 2 }}>
            <Tooltip title="Export to CSV">
              <CustomIcon icon="export" sx={{ svg: { width: 24, height: 24 } }} />
            </Tooltip>
          </IconButton>
        </Box>
      </Box>
      <Box display="flex" gap={6}>
        <PieChart format="currency" showLegendIcon legendPrefix="Exposure" size={240} data={aggregated} />
      </Box>
    </Paper>
  );
};
