import { Paper, Box, Typography, CustomSwitch } from "@frontend/ui";
import { useState } from "react";
import { PieChart } from "@frontend/ui/charts";
import { formatAmount } from "@frontend/ui/utils/formatters";
import { useNavigate, useParams } from "react-router-dom";
import { RouteParams, RoutePath } from "src/config/routes";
import { AssetDistributionResult } from "../../../generated";

type Props = {
  asset?: string;
  assetsDistribution: AssetDistributionResult;
};

enum DistributionType {
  Borrow = "BORROW",
  Collateral = "COLLATERAL",
}

const MarketAssetDistribution = ({ asset, assetsDistribution }: Props) => {
  const hasSupplyDistribution = assetsDistribution.supplied.some((d) => d.value);
  const hasBorrowDistribution = assetsDistribution.borrowed.some((d) => d.value);
  const [field, setField] = useState<DistributionType>(
    hasSupplyDistribution ? DistributionType.Collateral : DistributionType.Borrow,
  );

  const { clientName, marketId } = useParams();

  const toggleDistribution = () => {
    setField(field === DistributionType.Collateral ? DistributionType.Borrow : DistributionType.Collateral);
  };
  const checked = field === DistributionType.Collateral;
  const showToggle = hasSupplyDistribution && hasBorrowDistribution;
  const data = (checked ? assetsDistribution.supplied : assetsDistribution.borrowed).sort(
    (itemA, itemB) => itemB.value - itemA.value,
  );
  const totalValue = data.reduce((partialSum, item) => partialSum + item.value, 0);
  const totalEModeValue = data.reduce((partialSum, item) => partialSum + (item.eModeValue || 0), 0);
  const navigate = useNavigate();
  const onClickChartItem = (assetSymbol: string) => {
    const assetPath = RoutePath.Risk.MarketAssetOverview.replace(RouteParams.ClientName, clientName!)
      .replace(RouteParams.MarketId, marketId!)
      .replace(RouteParams.Asset, assetSymbol);
    return navigate(assetPath);
  };
  return (
    <Paper variant="card">
      <Paper variant="widget">
        <Box display="flex" justifyContent="space-between" mb={3}>
          <Box>
            <Typography variant="h3">
              {`${field === DistributionType.Collateral ? "Collateral" : "Borrow"} Distribution`}
            </Typography>
            <Typography>
              {`Distribution of ${field === DistributionType.Collateral ? "collateral" : "borrowed"} 
              assets against ${asset || ""}, in USD`}
            </Typography>
          </Box>
          {showToggle && (
            <CustomSwitch
              uncheckedLabel="Borrowed Against"
              checkedLabel="Collateral Against"
              onChange={toggleDistribution}
              checked={checked}
            />
          )}
        </Box>
        <Box display="flex" gap={6}>
          <PieChart
            format="currency"
            showLegendIcon
            size={240}
            data={data.map((item) => ({
              x: `${item.id}${item.eModeValue ? `\nE-Mode ${formatAmount(item.eModeValue, { currency: "usd" })}` : ""}`,
              y: item.value,
              onClick: () => onClickChartItem(item.id),
            }))}
            pieLabelRenderValue={(xValue, formattedValue) => `${xValue as string}\n${formattedValue}`}
            pieLabelFormatNotation="compact"
          />
          <Box />
          <Paper variant="card" sx={{ alignSelf: "flex-start", paddingTop: 1, paddingBottom: 1 }}>
            <Typography variant="h6">Total:</Typography>
            <Typography variant="h5">{formatAmount(totalValue, { currency: "usd" })}</Typography>
            {!!totalEModeValue && (
              <Box mt={1}>
                <Typography variant="h6">Total E-Mode:</Typography>
                <Typography variant="h5">{formatAmount(totalEModeValue, { currency: "usd" })}</Typography>
              </Box>
            )}
          </Paper>
        </Box>
      </Paper>
    </Paper>
  );
};

export default MarketAssetDistribution;
