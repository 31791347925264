import { CompositionOverTimeChart, Drawer, Grid } from "@frontend/ui";
import { getDeltaFields } from "src/components/trend";
import { ValueCard } from "@frontend/ui/value-card";
import { useState } from "react";
import { SeriesType } from "@frontend/ui/echarts/types";
import { useFeatureFlag } from "src/utils/feature-flags";
import { useClientConfig } from "../../clients-config";
import { OverviewCard } from "../../types";
import { MultiChartData, OverviewChartsStats, OverviewStatsData } from "./types";

type Props = {
  data?: OverviewStatsData;
  chartsStats?: OverviewChartsStats;
};

type CardsConfig = Record<
  OverviewCard,
  {
    title: string;
    icon?: string;
    type?: SeriesType;
    formatCurrency?: boolean;
    isAlignedWithTrend?: boolean;
    tooltip?: string;
    drawerChartData?: MultiChartData;
    maxDecimals?: number;
    isPercent?: boolean;
  }
>;

const OverviewCards = ({ data, chartsStats }: Props) => {
  const collateralAtRiskHistoryEnabled = useFeatureFlag("collateral-at-risk-history");
  const walletsAmountAtRiskEnabled = useFeatureFlag("wallets-amount-at-risk-history");
  const walletsRiskHistoryEnabled = useFeatureFlag("wallets-at-risk-history");

  const cardsConfig: CardsConfig = {
    totalSupplyUsd: {
      title: "Total Supply",
      icon: "deposit-icon",
      formatCurrency: true,
      isAlignedWithTrend: true,
      drawerChartData: chartsStats?.totalLiquidity,
    },
    totalBorrowUsd: {
      title: "Total Borrow",
      icon: "borrow-icon",
      formatCurrency: true,
      isAlignedWithTrend: true,
      drawerChartData: chartsStats?.totalBorrow,
    },
    totalValueLockedUsd: {
      title: "Total Value Locked",
      icon: "lock-icon",
      formatCurrency: true,
      isAlignedWithTrend: true,
      drawerChartData: chartsStats?.tvl,
    },
    totalValueAvailableForLiquidationUsd: {
      title: "Value Eligible for Liquidations",
      icon: "coins-stack-icon",
      formatCurrency: true,
      tooltip: "Total value at risk of liquidation in positions with health score below 1",
      drawerChartData: walletsRiskHistoryEnabled ? chartsStats?.valueAvailableForLiquidationUsd : undefined,
    },
    totalCollateralUsd: {
      title: "Total Collateral",
      icon: "coins-stack-icon",
      formatCurrency: true,
      isAlignedWithTrend: true,
    },
    totalValueAtRiskUsd: {
      title: "Total Collateral at Risk",
      icon: "coins-stack-icon",
      formatCurrency: true,
      tooltip: "Total value at risk of liquidation in positions with health score approaching liquidation",
      drawerChartData: collateralAtRiskHistoryEnabled ? chartsStats?.collateralAtRisk : undefined,
    },
    activeUsers: {
      title: "Active Wallets",
      icon: "users",
      tooltip: "Wallets with non-zero balance",
      isAlignedWithTrend: true,
    },
    numberOfWalletsAvailableForLiquidation: {
      title: "Wallets Eligible for Liquidations",
      icon: "wallet-icon",
      tooltip: "Wallets holding positions with health score below 1",
      drawerChartData: walletsAmountAtRiskEnabled ? chartsStats?.walletsEligibleForLiquidation : undefined,
    },
    numberOfWalletsAtRisk: {
      title: "Wallets at Risk",
      icon: "wallet-icon",
      tooltip: "Wallets holding positions with health score approaching liquidation",
      drawerChartData: walletsAmountAtRiskEnabled ? chartsStats?.walletsAtRisk : undefined,
    },
    protocolHealth: {
      title: "Protocol Health",
      icon: "heart-icon",
      type: "line",
      tooltip: "Protocol supply to borrow ratio",
    },
    totalPotentialBadDebtUsd: {
      title: "Bad Debt",
      icon: "coin-insert",
      formatCurrency: true,
      drawerChartData: walletsRiskHistoryEnabled ? chartsStats?.totalBadDebtUsd : undefined,
      tooltip: "Protocol bad debt, calculated as the sum of (borrow - collateral) over all wallets where health <1.",
    },
    dailyGrowth: {
      title: "Daily Growth",
      icon: "exponential-graph",
      type: "line",
      formatCurrency: true,
      isAlignedWithTrend: true,
    },
    stablecoinPrice: {
      title: "Price",
      icon: "coin-dollar",
      type: "line",
      formatCurrency: false,
      maxDecimals: 5,
      isAlignedWithTrend: true,
    },
    collateraDebtRatio: {
      title: "Collateral Ratio",
      icon: "coins",
      type: "line",
      isPercent: true,
      isAlignedWithTrend: true,
    },
  };

  const [drawerCard, setDrawerCard] = useState<OverviewCard>();
  const currentStats = data?.current;
  const previousStats = data?.previous;
  const { overview } = useClientConfig();

  const cardsData = overview?.cards.map((card) => {
    let value: number | null = 0;
    let previousValue: number | null = null;
    switch (card) {
      case "collateraDebtRatio": {
        const totalCollaral = currentStats?.totalCollateralUsd || 0;
        const totalBorrow = currentStats?.totalBorrowUsd || 1;
        value = totalCollaral / totalBorrow;
        break;
      }
      default: {
        value = currentStats ? currentStats[card] : 0;
        previousValue = previousStats ? previousStats[card] : null;
      }
    }

    return {
      key: card,
      config: cardsConfig[card],
      value,
      previousValue,
      drawerChartData: cardsConfig[card].drawerChartData,
    };
  });

  return (
    <>
      <Grid container spacing={1}>
        {cardsData?.map(
          ({ key, value, previousValue, config, drawerChartData }) =>
            value !== null && (
              <Grid key={key} item xs={12} sm={4} lg={cardsData.length % 2 === 0 ? 3 : 4}>
                <ValueCard
                  title={config.title}
                  value={value}
                  icon={config.icon}
                  currency={config.formatCurrency ? "USD" : undefined}
                  {...getDeltaFields(value, previousValue, config.isAlignedWithTrend)}
                  valueMaximumFractionDigits={config.maxDecimals}
                  isPercent={config.isPercent}
                  tooltip={config.tooltip}
                  onClick={drawerChartData ? () => setDrawerCard(key) : undefined}
                />
              </Grid>
            ),
        )}
      </Grid>
      <Drawer
        title={drawerCard ? `${cardsConfig[drawerCard].title} Over Time` : ""}
        open={!!drawerCard}
        onClose={() => setDrawerCard(undefined)}
      >
        <CompositionOverTimeChart
          series={Object.values(drawerCard ? cardsConfig[drawerCard].drawerChartData || [] : []).map((d) => ({
            type: drawerCard ? cardsConfig[drawerCard]?.type ?? "area" : "area",
            label: d.title,
            data: d.data.map((c) => [c.x.getTime(), c.y]),
          }))}
          percentToggle
          currency={drawerCard && cardsConfig[drawerCard].formatCurrency ? "USD" : ""}
        />
      </Drawer>
    </>
  );
};

export default OverviewCards;
