import { useState } from "react";
import { NumberChartSeries, TimeSpan } from "@frontend/ui/echarts/types";
import { Grid, Paper, ValueOverTimeChart } from "@frontend/ui";
import { daysAgoByTimeSpan } from "@frontend/ui/echarts/utils";
import { TimeSpanPicker } from "../../../ccar-lending-page/components/time-span-picker";
import { JlpPoolHistoryData, useJlpPoolHistoryDataQuery } from "../../generated";

export const JlpPoolCharts = () => {
  const [daysAgo, setDaysAgo] = useState(TimeSpan.Week);
  const { data, loading } = useJlpPoolHistoryDataQuery({
    variables: {
      daysAgo: daysAgoByTimeSpan[daysAgo],
    },
  });

  const multiChartDataToValueOverTimeSeries = (field: keyof JlpPoolHistoryData): NumberChartSeries[] =>
    data?.jlpPoolHistoryData.map((jlpHistoryData) => ({
      type: "line",
      label: jlpHistoryData.token,
      data: jlpHistoryData.data.map((d) => [d.time, d[field] as number]),
    })) || [];

  return (
    <Paper variant="card" key={daysAgo}>
      <Grid container spacing={[1, 2, 3]}>
        <Grid item xs={12} display="flex" justifyContent="end">
          <TimeSpanPicker
            timeSpanOptions={[TimeSpan.Day, TimeSpan.Week]}
            selectedTimeSpan={daysAgo}
            onSelectTimeSpan={setDaysAgo}
          />
        </Grid>

        <Grid item xs={12}>
          <Paper variant="widget">
            <ValueOverTimeChart
              isLoading={loading}
              series={multiChartDataToValueOverTimeSeries("sizeUsd")}
              title="Token TVL Over Time in USD"
              showLegend
              connectNulls
              timeSpan={daysAgo}
              hideTotal={false}
            />
          </Paper>
        </Grid>

        <Grid item xs={12} lg={6}>
          <Paper variant="widget">
            <ValueOverTimeChart
              isLoading={loading}
              series={multiChartDataToValueOverTimeSeries("targetWeightage")}
              title="Target Weightage Over Time"
              showLegend
              connectNulls
              timeSpan={daysAgo}
              currency=""
              isPercent
            />
          </Paper>
        </Grid>

        <Grid item xs={12} lg={6}>
          <Paper variant="widget">
            <ValueOverTimeChart
              isLoading={loading}
              series={multiChartDataToValueOverTimeSeries("currentWeightage")}
              title="Current Weightage Over Time"
              showLegend
              connectNulls
              timeSpan={daysAgo}
              currency=""
              isPercent
            />
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper variant="widget">
            <ValueOverTimeChart
              isLoading={loading}
              series={multiChartDataToValueOverTimeSeries("utilization")}
              title="Utilization Rate Over Time"
              showLegend
              connectNulls
              timeSpan={daysAgo}
              currency=""
              isPercent
            />
          </Paper>
        </Grid>
      </Grid>
    </Paper>
  );
};
