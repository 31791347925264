import { Link } from "react-router-dom";
import { Box, Button, CustomIcon } from "@frontend/ui";
import { Theme, useMediaQuery } from "@frontend/ui/theme";

import imgChaosCommunityDashboardLogoHeader from "src/assets/img/chaos-community-analytics-logo.svg";
import { ReactNode } from "react";
import { ClientLogo } from "../client-logo";
import { useProtocolSwitcherContext } from "../protocol-switcher";
import { UserInfoCard } from "../user-info-card";

export type CommunityDashboardHeaderProps = {
  homePath: string;
  shareText?: string;
  shareHashtags?: string[];
  buttonsPrefix?: ReactNode;
  hideLoginButton?: boolean;
};

export const CommunityDashboardHeader = ({
  homePath,
  shareText,
  shareHashtags,
  buttonsPrefix,
  hideLoginButton,
}: CommunityDashboardHeaderProps) => {
  const { shouldShowProtocolSwitcher } = useProtocolSwitcherContext();
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      flexDirection="row"
      alignItems="center"
      position="relative"
      zIndex={20}
      py={2}
      px={{
        xs: 2,
        sm: 5,
        md: 10,
        lg: 15,
      }}
      bgcolor="#0C0D10"
      width="100%"
    >
      <Box
        display="flex"
        flex="1"
        flexWrap="wrap"
        alignItems="center"
        maxWidth={1320}
        margin="auto"
        justifyContent="space-between"
      >
        <Box
          flex={1}
          sx={{
            display: "flex",
            alignContent: "center",
          }}
        >
          <Link to={homePath} style={{ paddingRight: isMobile ? 16 : 24 }}>
            <img
              style={{ display: "block" }}
              src={imgChaosCommunityDashboardLogoHeader}
              alt="header"
              height={isMobile ? 35 : 40}
            />
          </Link>
        </Box>
        <Box
          sx={{
            flex: 1,
            flexGrow: shouldShowProtocolSwitcher ? 1 : 0,
            display: "flex",
            justifyContent: isMobile ? "flex-end" : "center",
          }}
        >
          <ClientLogo {...{ homePath, isMobile, shouldShowProtocolSwitcher }} />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: 2,
            ...(!isMobile && { flex: 1 }),
            ...(isMobile && { marginLeft: "4px" }),
          }}
        >
          {!isMobile && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                gap: 2,
              }}
            >
              {buttonsPrefix}
              <Box>
                <Button
                  color="secondary"
                  onClick={() => {
                    const url = `https://twitter.com/share?ref_src=twsrc%5Etfw&url=${encodeURIComponent(
                      window.location.href,
                    )}&text=${encodeURIComponent(shareText || document.title)}${
                      shareHashtags ? `&hashtags=${encodeURIComponent(shareHashtags.join(","))}` : ""
                    }`;
                    window.open(url, "", "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600");
                  }}
                  startIcon={<CustomIcon icon="twitter" sx={{ svg: { width: 16, height: 16 } }} />}
                >
                  Share
                </Button>
              </Box>
              <Box>
                <Button color="primary" href="https://chaoslabs.xyz/contact-us">
                  Contact Us
                </Button>
              </Box>
            </Box>
          )}
          {!hideLoginButton && <UserInfoCard />}
        </Box>
      </Box>
    </Box>
  );
};
