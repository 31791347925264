import { Grid } from "@frontend/ui";
import { ValueCard, ValueCardProps } from "@frontend/ui/value-card";
import { AggAmmExchangeEvents } from "../../../../../generated";
import { getCardsDataByAggPoints } from "./utils";

type Props = {
  aggAmmExchangeEventsPoints?: AggAmmExchangeEvents[];
};

const SoftLiquidationsCards = ({ aggAmmExchangeEventsPoints }: Props) => {
  const cardsData = aggAmmExchangeEventsPoints ? getCardsDataByAggPoints(aggAmmExchangeEventsPoints) : undefined;

  const cards: ValueCardProps[] = [
    {
      title: "Liquidations",
      value: cardsData?.totalLiquidations,
      icon: "card-eject",
    },
    {
      title: "Token Sold",
      value: cardsData?.totalSoldUSD,
      icon: "deposit-icon",
      currency: "USD",
    },
    {
      title: "Token Bought",
      value: cardsData?.totalBoughtUSD,
      icon: "borrow-icon",
      currency: "USD",
    },
    {
      title: "Diff",
      tooltip: "Difference between tokens sold and bought",
      value: cardsData ? (cardsData.totalBoughtUSD - cardsData.totalSoldUSD) / cardsData.totalSoldUSD : undefined,
      icon: "star",
      isPercent: true,
    },
  ];

  return (
    <Grid container spacing={3}>
      {cards.map((cardProps) => (
        <Grid key={cardProps.title} item xs={12} sm={3} lg={3}>
          <ValueCard {...cardProps} />
        </Grid>
      ))}
    </Grid>
  );
};

export default SoftLiquidationsCards;
