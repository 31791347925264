import { Box } from "@frontend/ui";
import { JlpPoolCards, LiquidityAllocationTable, PoolAssets } from "./components";
import { useJlpPoolQuery } from "../../generated";
import { JlpPoolCharts } from "./jlp-pool-charts";

export const JlpPoolTab = () => {
  const { loading, data } = useJlpPoolQuery();
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
      <JlpPoolCards data={data?.jlpPool} />
      <PoolAssets isLoading={loading} data={data?.jlpPool} />
      <LiquidityAllocationTable isLoading={loading} data={data?.jlpPool} />
      <JlpPoolCharts />
    </Box>
  );
};
