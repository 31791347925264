import { Box, CustomReactSelect, FormHelperText, InputLabel, OptionType, TextField } from "@frontend/ui";
import { Stack } from "@mui/material";
import { ChangeEvent, useState } from "react";

interface DurationSelectProps {
  value: number;
  onChange: (value: number) => void;
  disabled?: boolean;
  label?: string;
  helperText?: string;
  error?: boolean;
  errorMsg?: string;
}

const MINUTES_IN_DAY = 1440;
const MINUTES_IN_HOUR = 60;

export const DurationSelect = ({
  value,
  onChange,
  disabled,
  label,
  helperText,
  error,
  errorMsg,
}: DurationSelectProps) => {
  const initialFactor =
    value % MINUTES_IN_DAY === 0 ? MINUTES_IN_DAY : value % MINUTES_IN_HOUR === 0 ? MINUTES_IN_HOUR : 1;
  const [factor, setFactor] = useState<number>(initialFactor);
  const [inputValue, setInputValue] = useState<number>(value / initialFactor);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(e.target.value || "0", 10);
    setInputValue(newValue);
    onChange(newValue * factor);
  };

  return (
    <Stack spacing={1}>
      <InputLabel>{label}</InputLabel>
      <Box display="flex" alignItems="center" justifyItems="center" justifyContent="space-between">
        <TextField
          type="number"
          onChange={handleChange}
          value={inputValue}
          disabled={disabled}
          sx={{ flex: 1, marginRight: 1 }}
          error={error}
          variant="outlined"
        />
        <CustomReactSelect
          controlShouldRenderValue
          options={[
            {
              label: "Minutes",
              value: "1",
            },
            {
              label: "Hours",
              value: "60",
            },
            {
              label: "Days",
              value: "1440",
            },
          ]}
          value={{
            label: factor === 1 ? "Minutes" : factor === 60 ? "Hours" : "Days",
            value: factor.toString(),
          }}
          onChange={(v: OptionType | null) => {
            if (!v) return;

            setFactor(parseInt(v.value, 10));
            onChange(inputValue * parseInt(v.value, 10));
          }}
          disabled={disabled}
        />
      </Box>
      {error && <FormHelperText error>{errorMsg}</FormHelperText>}
      <FormHelperText>{helperText}</FormHelperText>
    </Stack>
  );
};
