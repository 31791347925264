import { Box, Button, CustomIcon, IconButton, Typography, useConfirmationMessage } from "@frontend/ui";
import { Loader } from "@frontend/ui/loader";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Controller, FormProvider, useForm, useWatch } from "react-hook-form";
import { CircularProgress, FormLabel, Stack, Switch } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import ErrorIcon from "@mui/icons-material/Error";
import { AlertSetting, convertAlertLevelToDisplayName } from "./utils";
import { AlertSettingsForm } from "./form-inputs/types";
import { alertSeverityLabels, alertTypeLabels } from "../../../../utils/consts";
import { GeneralInformationSection } from "./form-inputs/general-information-section";
import { FormSection } from "./form-inputs/form-section";
import { TimingConfigurationSection } from "./form-inputs/timing-configuration-section";
import { TriggersConfigurationSection } from "./form-inputs/triggers-configuration-section";
import { NotificationConfigurationSection } from "./form-inputs/notification-configuration-section";
import { ClientsConfigurationSection } from "./form-inputs/clients-configuration-section";
import { useAlertSettings } from "./use-settings-table-data";
import { AlertsPortalRoutes } from "../../../../config/alerts-portal-routes";

function convertAlertSettingsToFormValues(alertSetting: AlertSetting | null): AlertSettingsForm | null {
  if (!alertSetting) {
    return null;
  }
  return {
    ...alertSetting,
    level: {
      value: alertSetting.level ?? "PROTOCOL",
      label: convertAlertLevelToDisplayName(alertSetting.level),
    },
    severity: {
      value: alertSetting.severity,
      label: alertSeverityLabels[alertSetting.severity],
    },
    alertType: {
      value: alertSetting.alertType,
      label: alertTypeLabels[alertSetting.alertType],
    },
  };
}

export const SettingsDetails = () => {
  const navigate = useNavigate();
  const { alertId = "" } = useParams<"alertId">();
  const { alertSettings, loading, onUpdate } = useAlertSettings();
  const { confirm } = useConfirmationMessage();

  const [alertSetting, setAlertSetting] = useState<AlertSetting | null>(null);

  const methods = useForm<AlertSettingsForm>({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: convertAlertSettingsToFormValues(alertSetting) ?? {},
  });

  const { handleSubmit, reset, getFieldState, control } = methods;

  useEffect(() => {
    const obj = alertSettings.find((setting) => setting.alertId === alertId) ?? null;
    setAlertSetting(obj);
    reset(convertAlertSettingsToFormValues(obj) ?? {});
  }, [alertSettings, alertId, reset]);

  // The dirtyFields of the react-form-hook does not work as expected
  // Then this state will save each field that was touched or changed
  const [dirtyFields, setDirtyFields] = useState<Record<string, boolean>>({});

  // need this to track changes in the form
  const changes = useWatch({
    control,
    name: Object.keys(alertSetting ?? {}) as (keyof AlertSettingsForm)[],
  });

  useEffect(() => {
    Object.keys(alertSetting ?? {}).forEach((field) => {
      if (getFieldState(field as keyof AlertSettingsForm)?.isDirty) {
        setDirtyFields((prev) => ({ ...prev, [field]: true }));
      }
    });
  }, [alertSetting, changes, getFieldState]);

  const [submitting, setSubmitting] = useState(false);
  const [submittingStatus, setSubmittingStatus] = useState<"idle" | "success" | "failure">("idle");

  const getUpdatedFields = (formValues: AlertSettingsForm) =>
    Object.keys(formValues)
      .filter((field) => dirtyFields[field as keyof AlertSettingsForm])
      .reduce((acc, field) => {
        if (field === "level" || field === "severity" || field === "alertType") {
          const currentValue = formValues[field as keyof AlertSettingsForm] as { label: string; value: string };
          return {
            ...acc,
            [field]: currentValue.value,
          };
        }

        return {
          ...acc,
          [field]: formValues[field as keyof AlertSettingsForm],
        };
      }, {});

  const onSubmit = (values: AlertSettingsForm) => {
    setSubmitting(true);
    const updatedValues = getUpdatedFields(values);

    confirm({
      title: "Confirm Changes to Alert Settings",
      message:
        "Are you sure you want to save the changes? This will update the alert configuration and may affect how alerts are triggered and processed.",
      onConfirm: () => {
        void onUpdate(alertId, updatedValues).then((updatedAlertSettings) => {
          setSubmitting(false);
          if (updatedAlertSettings) {
            setSubmittingStatus("success");
            setAlertSetting(updatedAlertSettings);
            reset(convertAlertSettingsToFormValues(updatedAlertSettings) ?? {});
            setDirtyFields({});
          } else {
            setSubmittingStatus("failure");
          }

          setTimeout(() => {
            setSubmittingStatus("idle");
          }, 1000);
        });
      },
      onCancel: () => {
        setSubmitting(false);
      },
    });
  };

  if (loading) return <Loader />;

  if (!alertSetting) {
    navigate(AlertsPortalRoutes.Settings, { replace: true, state: { reset: true } });

    return null;
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box height="100%" display="flex" flexDirection="column" position="relative">
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            mt={2}
            pt={3}
            pb={5}
            gap={2}
            position="sticky"
            top={0}
            zIndex={100}
            bgcolor="background.paper"
            borderBottom="1px solid"
            borderColor="divider"
          >
            <IconButton
              size="small"
              onClick={() => {
                navigate(AlertsPortalRoutes.Settings, { replace: true, state: { reset: true } });
              }}
            >
              <CustomIcon icon="arrow-left" />
            </IconButton>
            <Typography variant="h1">{alertSetting.name}</Typography>
            <Box flex={1} />
            <Box display="flex" flexDirection="row" alignItems="center">
              <Controller
                name="enabled"
                control={control}
                disabled={submitting}
                render={({ field }) => (
                  <Switch size="medium" {...field} checked={field.value} onClick={(e) => e.stopPropagation()} />
                )}
              />
              <FormLabel>Active</FormLabel>
            </Box>
            <Button
              color={submittingStatus === "success" ? "success" : submittingStatus === "failure" ? "error" : "primary"}
              disabled={submitting || !Object.keys(dirtyFields).length}
              type="submit"
            >
              {submitting && <CircularProgress color="inherit" size="24px" sx={{ marginRight: 2 }} />}
              {submittingStatus === "success" && <DoneIcon />}
              {submittingStatus === "failure" && <ErrorIcon />}
              {submitting
                ? "Saving"
                : submittingStatus === "success"
                  ? "Saved"
                  : submittingStatus === "failure"
                    ? "Failed"
                    : "Save Changes"}
            </Button>
          </Box>
          <Stack spacing={2}>
            <FormSection title="General Configuration">
              <GeneralInformationSection submitting={submitting} />
            </FormSection>
            <FormSection title="Timing Configuration">
              <TimingConfigurationSection submitting={submitting} />
            </FormSection>
            <FormSection title="Trigger Configuration">
              <TriggersConfigurationSection submitting={submitting} />
            </FormSection>
            <FormSection title="Notifications">
              <NotificationConfigurationSection submitting={submitting} />
            </FormSection>
            <FormSection title="Clients">
              <ClientsConfigurationSection submitting={submitting} />
            </FormSection>
          </Stack>
        </Box>
      </form>
    </FormProvider>
  );
};
