import { Grid, ValueCard } from "@frontend/ui";
import { MultiValuesCard } from "src/components/multi-values-card";
import { AccountAggregations } from "../../../../../../generated";

type Props = { accountAggregations?: AccountAggregations | null; hasSubAccounts: boolean };

export const AggregationCards = ({ accountAggregations, hasSubAccounts }: Props) => (
  <Grid container mb={3} spacing={3}>
    <Grid item xs={12} md={4}>
      <ValueCard
        title="Total Collateral"
        icon="coins-stack-icon"
        value={
          accountAggregations?.subAccountCollateral !== null ? accountAggregations?.subAccountCollateral : undefined
        }
        progress={
          hasSubAccounts
            ? {
                maxValue: accountAggregations?.totalCollateral || 0,
                displayMaxValue: true,
              }
            : undefined
        }
        currency="USD"
      />
    </Grid>
    <Grid item xs={12} md={4}>
      <MultiValuesCard
        sx={{ h3: { mt: 3 } }}
        values={[
          {
            title: "Long OI",
            icon: "line-up",
            value: accountAggregations?.longOpenInterest,
          },
          {
            title: "Short OI",
            icon: "line-down",
            value: accountAggregations?.shortOpenInterest,
          },
        ]}
      />
    </Grid>
    <Grid item xs={12} md={4}>
      <ValueCard
        title="Unrealized PnL"
        icon="chart-maximum"
        value={accountAggregations?.pnl}
        showSign
        currency="USD"
        tooltip="Updated on last position change"
      />
    </Grid>
  </Grid>
);
