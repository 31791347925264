import { useDataFetchersQuery } from "src/hooks/useDataFetchersQuery";
import { useClientConfig } from "../../../../clients-config";

type FacilitatorsResponse = {
  name: string;
  ghoMinted: number;
  ghoMintLimit: number;
}[];

export const useFacilitatorsQuery = () => {
  const { endpointsPrefix } = useClientConfig();
  const { isLoading, response } = useDataFetchersQuery<{ data: FacilitatorsResponse }>(
    `${endpointsPrefix}.gho_facilitators`,
    "onchain",
    undefined,
  );

  return { isLoading, data: response?.data };
};
