import { Paper } from "@frontend/ui";
import { LineChart, LineChartData } from "@frontend/ui/charts";
import { formatAmount } from "@frontend/ui/utils/formatters";
import { dataFetchersURL } from "src/utils/data-fetchers-url";
import { ShimmerLoader } from "@frontend/ui/shimmer-loader";
import { AssetTotalValueAtRisk } from "../../../generated";

type Props = {
  totalValueAtRisk?: AssetTotalValueAtRisk;
  asset: string;
};
const MarketTotalValueAtRiskChart = ({ totalValueAtRisk, asset }: Props) => (
  <Paper variant="widget" sx={{ height: { md: 416 } }}>
    {totalValueAtRisk ? (
      <LineChart
        baseUrl={dataFetchersURL()}
        title="Collateral at Risk"
        description="Value at risk of liquidation in positions with health score approaching 1"
        xAxis={{
          label: `${asset} Price`,
          currency: "USD",
          highlight: {
            value: totalValueAtRisk.currentAssetPrice,
            text: `Current ${asset} Price`,
          },
        }}
        xAxisAmountNotation="standard"
        yAxis={{
          label: "Value Liquidated (USD)",
        }}
        yAxisAmountNotation="compact"
        data={totalValueAtRisk.liquidationUsdValueByPrice.reduce((acc: LineChartData[], c) => {
          acc.push({
            x: c.price,
            y: c.liquidationAmount,
            label: `${asset} Price: ${formatAmount(c.price, {
              currency: "USD",
              notation: "standard",
            })}\n \nValue Liquidated (USD): ${formatAmount(c.liquidationAmount, {
              currency: "USD",
              notation: "standard",
            })}`,
          });
          return acc;
        }, [])}
      />
    ) : (
      <ShimmerLoader sx={{ width: "100%", height: "100%", borderRadius: 2 }} />
    )}
  </Paper>
);

export default MarketTotalValueAtRiskChart;
