import { Grid } from "@frontend/ui";
import { ValueCard, ValueCardProps } from "@frontend/ui/value-card";
import { DegenResponse } from "../../../api/types";

type Props = {
  degen?: DegenResponse;
  totalSwapPoints?: number;
  totalReferralPoints?: number;
};

const Cards = ({ degen, totalSwapPoints, totalReferralPoints }: Props) => {
  const cards: (ValueCardProps & { md?: number })[] = [
    {
      title: "Rank",
      value: degen?.ranking,
      icon: "view-list",
    },
    {
      title: "Points",
      value: degen?.totalPoints ? Number(degen.totalPoints) : undefined,
      icon: "bubble-race",
    },
    {
      title: "Faction Name",
      value: degen?.faction.factionName,
      imgSrc: degen?.faction.factionImage,
      icon: "shopping-list",
    },
    {
      title: "Swap Points",
      value: totalSwapPoints,
      icon: "bubble-race",
      md: 6,
    },
    {
      title: "Referral Points",
      value: totalReferralPoints,
      icon: "bubble-race",
      md: 6,
    },
  ];

  return (
    <Grid container spacing={2}>
      {cards.map((card, i) => (
        <Grid key={`${card.title}${i.toString()}`} item xs={12} md={card.md ?? 4}>
          <ValueCard {...card} />
        </Grid>
      ))}
    </Grid>
  );
};

export default Cards;
