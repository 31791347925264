import { Tabs, Tab } from "@mui/material";
import { TabsProps } from "./tabs";

export const SubNavTabs = ({ tabs, value, onChange }: TabsProps) => (
  <Tabs
    value={value}
    data-testid="sub-nav-tabs-container"
    onChange={onChange}
    variant="scrollable"
    scrollButtons="auto"
    sx={{
      padding: 0,
      gap: 0,
      bgcolor: "grey.main",
      borderRadius: 2,
      display: "inline-flex",
      maxWidth: "100%",
      "& .MuiTabs-indicator": {
        display: "none",
      },
    }}
  >
    {tabs.map((tab) => (
      <Tab
        key={tab.label?.toString()}
        data-testid={tab.testId}
        label={tab.label}
        sx={{
          background: "transparent",
          textTransform: "none",
          fontWeight: 600,
          padding: "12px 16px",
          fontSize: 14,
          minWidth: 0,
          borderRadius: "0 !important",
          lineHeight: "24px",
          color: "white.main",
          transition: "all 0.1s ease",
          "&:hover, &.Mui-selected:hover": {
            backgroundColor: "grey.hover",
          },
          "&.Mui-selected": {
            background: "transparent",
          },
          "&.Mui-selected.Mui-selected": {
            color: "aqua.main",
          },
        }}
      />
    ))}
  </Tabs>
);
