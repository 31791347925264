import { Grid, ValueCard, ValueCardProps } from "@frontend/ui";
import { JlpPoolData } from "../../../generated";

type Props = {
  data?: JlpPoolData;
};

const JlpPoolCards = ({ data }: Props) => {
  const cards: ValueCardProps[] = [
    {
      title: "Total Value Locked",
      value: data?.tvl,
      currency: "USD",
      icon: "pie-slice",
    },
    {
      title: "APY",
      value: data?.apy,
      isPercent: true,
      icon: "pie-half",
    },
    {
      title: "APR",
      value: data?.apr,
      isPercent: true,
      icon: "coins-stack-icon",
    },
    {
      title: "$JLP Total Supply",
      value: data?.jlpTotalSupply,
      icon: "bubble-race",
    },
    {
      title: "$JLP Price",
      value: data?.jlpVirtualPrice,
      valueMaximumFractionDigits: 2,
      icon: "coin-dollar",
      currency: "USD",
    },
  ];

  return (
    <Grid container spacing={2}>
      {cards.map((cardProps) => (
        <Grid key={cardProps.title} item xs={12} sm={6} lg={12 / cards.length}>
          <ValueCard {...cardProps} />
        </Grid>
      ))}
    </Grid>
  );
};

export default JlpPoolCards;
