import { useMemo } from "react";
import { useDataFetchersQuery } from "src/hooks/useDataFetchersQuery";
import { DataWrap } from "@frontend/types";
import {
  AssetWalletsHealthHistogramTypsEnum,
  Chain,
  useAssetDistributionQuery,
  useAssetsHistoricalPricesQuery,
  useAssetStatsQuery,
  useAssetTotalValueAtRiskQuery,
  useAssetWalletsHealthHistogramQuery,
} from "../../generated";
import { clientsConfig } from "../../clients-config";
import { Client } from "../../types";
import { MarketWalletsDistribution } from "./types";

export const useMarketDetails = (
  client: Client,
  chain: Chain,
  tokenSymbol: string,
  market?: string,
  marketId?: string,
) => {
  const { endpointsPrefix, marketDetails, markets: marketsConfig } = clientsConfig[client];
  const marketsQuery = useMemo(() => ({ chain, tokenSymbol }), [chain, tokenSymbol]);
  const supplierMarketsQuery = useMemo(
    () => ({
      chain,
      tokenSymbol,
      type: AssetWalletsHealthHistogramTypsEnum.Liquidity,
      marketId: marketId || null,
      market: market || null,
    }),
    [chain, tokenSymbol, marketId, market],
  );
  const borrowerMarketsQuery = useMemo(
    () => ({
      chain,
      tokenSymbol: marketsConfig?.borrowAsset ?? tokenSymbol,
      type: AssetWalletsHealthHistogramTypsEnum.Borrow,
      marketId: marketId || null,
      market: market || null,
    }),
    [marketId, chain, tokenSymbol, market, marketsConfig],
  );
  const walletsQuery = useMemo(() => ({ chain, tokenSymbol }), [chain, tokenSymbol]);

  const { data: marketWalletsBorrowersHealth, loading: isLoadingMarketWalletsBorrowersHealth } =
    useAssetWalletsHealthHistogramQuery({
      variables: borrowerMarketsQuery,
      skip: !marketDetails?.charts.includes("borrowWalletsHealth"),
    });

  const { data: marketWalletsSuppliersHealth, loading: isLoadingMarketWalletsSuppliersHealth } =
    useAssetWalletsHealthHistogramQuery({
      variables: supplierMarketsQuery,
      skip: !marketDetails?.charts.includes("supplyWalletsHealth"),
    });

  const { data: marketAssetsDistribution, loading: isLoadingMarketAssetsDistribution } = useAssetDistributionQuery({
    variables: marketsQuery,
    skip: !marketDetails?.charts.includes("marketsDistribution"),
    fetchPolicy: "no-cache",
  });

  const { data: marketStats, loading: isLoadingMarketStats } = useAssetStatsQuery({
    variables: {
      ...marketsQuery,
      marketId: marketId || null,
      market: market || null,
      marketType: null,
    },
  });

  const { data: marketTotalValueAtRisk, loading: isLoadingMarketTotalValueAtRisk } = useAssetTotalValueAtRiskQuery({
    variables: { ...marketsQuery, marketId: marketId || null },
  });

  const { response: marketWalletsDistribution, isLoading: isLoadingMarketWalletsDistribution } = useDataFetchersQuery<
    DataWrap<MarketWalletsDistribution>
  >(
    `${endpointsPrefix}.market_wallets_distribution`,
    "offchain",
    marketDetails?.charts.includes("walletsDistribution") ? walletsQuery : undefined,
    true,
  );

  const { data: assetHistoricalPrices, loading: isLoadingAssetHistoricalPrices } = useAssetsHistoricalPricesQuery({
    variables: { assets: [marketsQuery] },
  });

  const isLoading =
    isLoadingMarketWalletsBorrowersHealth ||
    isLoadingMarketWalletsSuppliersHealth ||
    isLoadingMarketStats ||
    isLoadingMarketAssetsDistribution ||
    isLoadingMarketTotalValueAtRisk ||
    isLoadingMarketWalletsDistribution ||
    isLoadingAssetHistoricalPrices;

  return {
    isLoading,
    marketWalletsBorrowersHealth: marketWalletsBorrowersHealth?.assetWalletsHealthHistogram,
    marketWalletsSuppliersHealth: marketWalletsSuppliersHealth?.assetWalletsHealthHistogram,
    marketStats: marketStats?.assetStats,
    marketAssetsDistribution: marketAssetsDistribution?.assetDistribution,
    marketTotalValueAtRisk: marketTotalValueAtRisk?.assetTotalValueAtRisk,
    marketWalletsDistribution,
    assetHistoricalPrices: assetHistoricalPrices?.assetsHistoricalPrices,
  };
};
