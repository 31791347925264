import { Box } from "@frontend/ui";
import { ReactNode } from "react";
import { useParams } from "react-router-dom";
import TabsNavigation, { NavigationTabProps } from "src/components/layouts/tabs-navigation";
import { RouteParams, RoutePath } from "src/config/routes";
import { useClientConfig } from "src/pages/ccar-lending-page/clients-config";
import { RiskTabsLayout } from "src/pages/ccar-lending-page/components/risk-tabs-layout";

export const LiquidationsTabsLayout = ({ children }: { children: ReactNode }) => {
  const { clientName } = useParams();
  const { liquidations } = useClientConfig();

  const replaceParams = (path: string) => path.replace(RouteParams.ClientName, clientName!);

  const liquidationsPageTabs: NavigationTabProps[] = [
    { label: "Liquidations", path: replaceParams(RoutePath.Risk.Liquidations) },
    ...(liquidations?.hasSoftLiquidations
      ? [{ label: "Soft Liquidations", path: replaceParams(RoutePath.Risk.SoftLiquidations) }]
      : []),
  ];

  return (
    <RiskTabsLayout>
      <Box display="flex" flexDirection="column" gap={3}>
        {liquidationsPageTabs.length > 1 && (
          <Box display="flex" justifyContent="flex-end">
            <TabsNavigation variant="sub-nav" tabs={liquidationsPageTabs} />
          </Box>
        )}
        {children}
      </Box>
    </RiskTabsLayout>
  );
};
