import { Box, Paper, Typography } from "@frontend/ui";
import { FC } from "react";
import {
  EModeInformation,
  MarketData,
  MarketHealth,
  MarketLiquidationBand,
} from "src/pages/ccar-lending-page/generated";
import { MarketHealthScore } from "../market-health-score";

type AssetsHealthWidgetProps = {
  markets?: MarketData[];
  marketHealth: MarketHealth[];
  eModeCategories?: EModeInformation[];
  marketLiquidationBands?: MarketLiquidationBand[];
};

export type MarketHealthWithTotalSupplyAndBorrow = MarketHealth & {
  totalCollateral?: number;
  totalSupply?: number;
  totalBorrow?: number;
};

const AssetsHealthWidget: FC<AssetsHealthWidgetProps> = ({
  markets,
  marketHealth,
  eModeCategories,
  marketLiquidationBands,
}) => (
  <Paper
    variant="card"
    sx={{
      display: "flex",
      gap: "24px",
      flexDirection: "column",
      minHeight: "130px",
      height: "100%",
    }}
  >
    <Typography variant="h2" component="h2">
      Markets
    </Typography>
    <Box display="flex" alignItems="center" flexDirection="row" flexWrap="wrap" gap={[3, 5]}>
      {marketHealth?.map((market: MarketHealthWithTotalSupplyAndBorrow) => {
        const eModeCategory = eModeCategories?.find((c) => c.chain === market.market.id);
        const liquidationBands = marketLiquidationBands?.find((b) => b.marketId === market.market.id);
        const marketData = markets?.find((m) => m.id === market.market.id);

        return (
          <MarketHealthScore
            key={Object.values(market.market).join("-")}
            {...market}
            marketData={marketData}
            eModeCategory={
              eModeCategory && { id: eModeCategory.categoryId.toString(), label: eModeCategory.categoryLabel }
            }
            liquidationBands={liquidationBands}
          />
        );
      })}
    </Box>
  </Paper>
);

export default AssetsHealthWidget;
