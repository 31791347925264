import { Box } from "@frontend/ui";
import { Header, RenderData } from "@frontend/types";
import { ChaosTable, currencyCell, numberCell, percentCell, textCell } from "@frontend/ui/chaos-table";
import { RouteParams, RoutePath } from "src/config/routes";
import { useParams } from "react-router-dom";
import { useMarketsQuery } from "../../generated";
import { useClientConfig } from "../../clients-config";
import GlvsTable from "./components/glvs-table";

const MarketsTab = () => {
  const { clientName } = useParams<{ clientName: string }>();
  const { data, loading } = useMarketsQuery();
  const { isSynthetic, combineOpenInterest, markets: marketsConfig, marketBaseAssetOverride } = useClientConfig();
  const markets = data?.markets || [];
  const marketsHeaders: Header[] = [
    {
      renderType: "TEXT",
      text: "Market",
      prefix: "$",
      width: combineOpenInterest ? "5%" : "10%",
    },
    !combineOpenInterest
      ? {
          renderType: "TEXT",
          text: "Short OI",
          tooltip: "Open Short Interest",
          width: "5%",
        }
      : {
          renderType: "TEXT",
          text: "Open Interest",
          width: "5%",
        },
    {
      renderType: "TEXT",
      text: "Short Positions",
      width: "5%",
    },
    {
      renderType: "TEXT",
      text: "Short Leverage",
      suffix: "%",
      width: "5%",
    },
    ...(!combineOpenInterest
      ? [
          {
            renderType: "TEXT",
            text: "Long OI",
            tooltip: "Open Long Interest",
            width: "5%",
          } as Header,
        ]
      : []),
    {
      renderType: "TEXT",
      text: "Long Positions",
      width: "5%",
    },
    {
      renderType: "TEXT",
      text: "Long Leverage",
      suffix: "%",
      width: "5%",
    },
    ...(marketsConfig?.hasDailyVolume
      ? [
          {
            renderType: "TEXT",
            text: "Volume 24H",
            width: "5%",
          } as Header,
        ]
      : []),
    ...(marketsConfig?.hasNextFundingRate
      ? [
          {
            renderType: "TEXT",
            text: "1H Funding",
            width: "5%",
          } as Header,
        ]
      : []),
    ...(marketsConfig?.hasDailyTrades
      ? [
          {
            renderType: "TEXT",
            text: "Trades 24H",
            width: "5%",
          } as Header,
        ]
      : []),
    ...(isSynthetic
      ? ([
          {
            renderType: "ICONS",
            text: "Short Assets",
            width: "3%",
            nonSortable: true,
          },
        ] as Header[])
      : []),
    ...(isSynthetic
      ? ([
          {
            renderType: "ICONS",
            text: "Long Assets",
            width: "3%",
            nonSortable: true,
          },
        ] as Header[])
      : []),
  ];
  const marketsRows: RenderData[][] = markets.map((market) => {
    const marketBaseAsset = marketBaseAssetOverride?.[market.id] || market.symbol;
    return [
      {
        renderType: "ICONS",
        token1: marketBaseAsset,
        text: market.name,
        extraData: {
          title: market.name,
          symbol: marketBaseAsset,
        },
      },
      combineOpenInterest
        ? currencyCell(market.shortInterest + market.longInterest)
        : currencyCell(market.shortInterest),
      numberCell(market.shortPositions),
      textCell(`${market.averageShortLeverage.toFixed(2)}x`, market.averageShortLeverage),
      ...(!combineOpenInterest ? [currencyCell(market.longInterest)] : []),
      numberCell(market.longPositions),
      textCell(`${market.averageLongLeverage.toFixed(2)}x`, market.averageLongLeverage),
      ...(marketsConfig?.hasDailyVolume ? [currencyCell(market.dailyVolumeUsd)] : []),
      ...(marketsConfig?.hasNextFundingRate ? [percentCell(market.nextFundingRate)] : []),
      ...(marketsConfig?.hasDailyTrades ? [numberCell(market.dailyTrades)] : []),
      ...(isSynthetic
        ? ([
            {
              renderType: "ICONS",
              text: "",
              icons: market.shortAssets,
            },
          ] as RenderData[])
        : []),
      ...(isSynthetic
        ? ([
            {
              renderType: "ICONS",
              text: "",
              icons: market.longAssets,
            },
          ] as RenderData[])
        : []),
    ];
  });

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      {marketsConfig?.supportGlv && <GlvsTable />}
      <ChaosTable
        title="Markets"
        headers={marketsHeaders}
        isLoading={loading}
        data={marketsRows}
        initialSortBy={1}
        pageSize={15}
        isFullHeight
        showSearch
        isInitialSortEnable
        isInitialSortDesc
        serchbarPlaceholder="Search"
        rowHref={(rowId) =>
          RoutePath.CCARPerpetuals.MarketDetails.replace(RouteParams.ClientName, clientName!).replace(
            RouteParams.MarketId,
            markets[rowId].id,
          )
        }
        showRowChevron
      />
    </Box>
  );
};

export default MarketsTab;
