import { Box, OptionsSelect } from "@frontend/ui";
import { RouteParams, RoutePath } from "src/config/routes";
import { useNavigate, useParams } from "react-router-dom";
import { useFeatureFlag } from "src/utils/feature-flags";
import { useTraderStatsQuery } from "src/pages/ccar-perpetuals-page/generated";
import { useClientConfig } from "../../../../../clients-config";
import { CumulativePnlChart } from "../components";
import { PositionsTable, AggregationCards, VolumeDistribution } from "./components";
import { useAccountData } from "../../hooks/use-account-data";

export const Overview = () => {
  const { address: accountAddress = "" } = useParams();
  const { clientName, hasSharedCollateral, network, liquidations, accounts } = useClientConfig();
  const navigate = useNavigate();
  const { accountId, subAccount, subAccounts, accountAggregations, loadingAggregations } = useAccountData();
  const accountAnalysisEnabled = useFeatureFlag("perpetuals-positions-analysis") && accounts?.accountAnalysis;

  const { loading: isLoadingTraderStats, data: traderStatsData } = useTraderStatsQuery({
    variables: {
      account: accountId,
    },
    skip: !accountAnalysisEnabled,
  });

  return (
    <Box display="flex" flexWrap="nowrap" flexDirection="column" gap={[1, 2, 3]} height="100%" width="100%">
      {accountAnalysisEnabled && !!traderStatsData?.traderStats.length && (
        <>
          <Box>
            <CumulativePnlChart account={accountId} />
          </Box>
          <Box>
            <VolumeDistribution isLoading={isLoadingTraderStats} traderStats={traderStatsData?.traderStats} />
          </Box>
        </>
      )}
      {!!subAccounts?.length && (
        <Box ml="auto">
          <OptionsSelect<number>
            title="Sub-account"
            options={subAccounts.map((s) => ({ label: s.toString(), value: s }))}
            selectedOption={Number(subAccount)}
            onSelectOption={(value) =>
              navigate(
                RoutePath.Risk.SubAccountPositions.replace(RouteParams.ClientName, clientName)
                  .replace(RouteParams.Address, accountId)
                  .replace(RouteParams.SubAccount, value.toString()),
              )
            }
          />
        </Box>
      )}
      {!!subAccounts?.length && (loadingAggregations || accountAggregations) && (
        <AggregationCards accountAggregations={accountAggregations} hasSubAccounts={subAccounts.length > 1} />
      )}
      <Box>
        <PositionsTable
          account={accountAddress}
          subAccount={subAccount !== undefined ? Number(subAccount) : undefined}
          title="Open Positions"
          chain={network.name}
          metadata={accountId}
          open
          hasSharedCollateral={hasSharedCollateral}
        />
      </Box>
      <Box>
        <PositionsTable
          account={accountAddress}
          subAccount={subAccount !== undefined ? Number(subAccount) : undefined}
          title="Historic Positions"
          chain={network.name}
          metadata={accountId}
          hasSharedCollateral={hasSharedCollateral}
          showLiquidationBlock={liquidations?.showBlockNumber}
        />
      </Box>
    </Box>
  );
};
