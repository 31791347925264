import { Grid, ValueCard, ValueCardProps } from "@frontend/ui";
import { MarketAmm } from "src/pages/ccar-lending-page/generated";

type Props = {
  marketAmm: MarketAmm;
};
const InfoCards = ({ marketAmm }: Props) => {
  const { basePrice, amplificationFactor, fee, totalSwapVolume } = marketAmm;

  const cards: ValueCardProps[] = [
    {
      title: "Price",
      value: basePrice,
      currency: "USD",
      icon: "coin-dollar",
    },
    {
      title: "Amplification Factor(A)",
      value: amplificationFactor,
      icon: "line-up",
    },
    {
      title: "Fee",
      value: fee,
      isPercent: true,
      icon: "coin-dollar",
    },
    {
      title: "Total Swap Volume",
      value: totalSwapVolume,
      currency: "USD",
      icon: "transaction",
    },
  ];

  return (
    <Grid container spacing={3}>
      {cards.map((cardProps) => (
        <Grid key={cardProps.title} item xs={12} sm={6} lg={3}>
          <ValueCard {...cardProps} />
        </Grid>
      ))}
    </Grid>
  );
};

export default InfoCards;
